<template>
    <div class="home">
        <section
        v-if="$api.shop.index_slides.length > 0"
        class="pt-0">
            <slider-vetrina :items="$api.shop.index_slides" />
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section-deps />

        <!-- ------------------------------------------------------------------------------------------------------------------------ -->
        <section class="white-lighten-4">
            <bxs-layout>
                <div class="text-center">
                    <h3>Come funziona</h3>
                </div>

                <bxs-row align="center">
                    <bxs-col cols="12" ds="6">
                        <bxs-figure ratio="4-3" />
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <ul>
                            <li class="flex nowrap mb-ui">
                                <div class="pr-ui">
                                    <h1>1</h1>
                                </div>
                                <div>
                                    <h5>Scegli il colore</h5>
                                    <p>Seleziona il colore del tappeto tra le tantissime proposte in catalogo.</p>
                                </div>
                            </li>

                            <li class="flex nowrap mb-ui">
                                <div class="pr-ui">
                                    <h1>2</h1>
                                </div>
                                <div>
                                    <h5>Scegli la forma</h5>
                                    <p>Seleziona la forma che preferisci tra le nostre proposte o inviaci il tuo disegno.</p>
                                </div>
                            </li>

                            <li class="flex nowrap mb-ui">
                                <div class="pr-ui">
                                    <h1>3</h1>
                                </div>
                                <div>
                                    <h5>Scegli le dimensioni</h5>
                                    <p>Inserisci le dimensioni del tuo tappeto, paghi solo i centimetri che ti servono.</p>
                                </div>
                            </li>

                            <li class="flex nowrap">
                                <div class="pr-ui">
                                    <h1>4</h1>
                                </div>
                                <div>
                                    <h5>Scegli il bordo</h5>
                                    <p>Rifinisci il tuo tappeto scegliendo il tipo di bordo e il colore.</p>
                                </div>
                            </li>
                        </ul>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="text-center">
                    <h3>Idee e ispirazione</h3>
                </div>

                <bxs-row class="bxs-row-gutter-small">
                    <bxs-col cols="4" ds="7">
                        <bxs-figure height="100%" ratio="4-3" />
                    </bxs-col>

                    <bxs-col cols="8" ds="5">
                        <bxs-figure height="100%" ratio="1-1" />
                    </bxs-col>

                    <bxs-col cols="12" ds="4">
                        <bxs-figure height="100%" ratio="1-1" />
                    </bxs-col>

                    <bxs-col cols="12" ds="4">
                        <div
                        class="flex column nowrao justify-center pa-ui white-lighten-4 text-center"
                        style="height: 100%;">
                            <h4>Tappeti perfetti al centimetro!</h4>
                            <p class="h5">Un tappeto non dovrebbe essere solo bello, ma anche funzionale e adatto a ogni esigenza. Per questo ti offriamo un servizio su misura, così puoi adattare il tuo tappeto a qualsiasi spazio e pagare solo i centimetri che ti servono</p>
                        </div>
                    </bxs-col>

                    <bxs-col cols="12" ds="4">
                        <bxs-figure height="100%" ratio="1-1" />
                    </bxs-col>

                    <bxs-col cols="8" ds="5">
                        <bxs-figure height="100%" ratio="1-1" />
                    </bxs-col>

                    <bxs-col cols="4" ds="7">
                        <bxs-figure height="100%" ratio="4-3" />
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import SliderVetrina from '@/components/project/SliderVetrina.vue'

export default {
    name: 'home',
    components: {
        'slider-vetrina': SliderVetrina
    }
}
</script>