    <template>
    <div class="cart-view">
        <section>
            <bxs-layout>
                <div
                v-if="!$api.me.checkout.cart.length"
                class="text-center">
                    <h6 class="mb-4">Nessun prodotto nel carrello</h6>
                    <bxs-btn
                    @click="$router.go(-1)">Continua gli acquisti</bxs-btn>
                </div>

                <bxs-row v-else>
                    <bxs-col cols="8">
                        <bxs-card class="mb-ui">
                            <div>
                                <div>
                                    <h6 class="mb-ui">Il tuo carrello</h6>
                                </div>

                                <cart-items />
                            </div>
                        </bxs-card>

                        <!-- <bxs-card class="mb-ui">
                            <h4 class="mb-8">I tuoi kit campioni</h4>

                            <div class="list">
                                <div
                                v-for="el in 4"
                                :key="el"
                                class="element-list my-4">
                                    <bxs-row>
                                        <bxs-col cols="auto" class="pt-0 pb-4">
                                            <bxs-avatar />
                                        </bxs-col>

                                        <bxs-col class="pt-0 pb-4">
                                            <h4>Acqualine</h4>
                                            <p>Rosso</p>
                                        </bxs-col>

                                        <bxs-col cols="auto" class="pt-0 pb-4">
                                            <div class="text-center">
                                                <a><bxs-icon name="trash" height="24px" wudth="24px" /><br><small>Elimina</small></a>
                                            </div>
                                        </bxs-col>
                                    </bxs-row>
                                </div>

                                <div class="element-list">
                                    <bxs-row>
                                        <bxs-col cols="auto" class="pt-0 pb-4">
                                            <bxs-avatar />
                                        </bxs-col>

                                        <bxs-col class="pt-0 pb-4">
                                            <h4>Acqualine</h4>
                                            <p>Rosso</p>
                                        </bxs-col>

                                        <bxs-col cols="auto" class="pt-0 pb-4">
                                            <div class="text-center">
                                                <a><bxs-icon name="trash" height="24px" wudth="24px" /><br><small>Elimina</small></a>
                                            </div>
                                        </bxs-col>
                                    </bxs-row>
                                </div>
                            </div>

                            <div class="text-right">
                                <p>9,90 €</p>
                            </div>
                        </bxs-card> -->

                        <bxs-card class="mb-ui">
                            <h5>Tempi medi di consegna</h5>
                            <p>Il tuo ordine sarà pronto per la spedizione tra il <strong>09/05/2022</strong> e il <strong>13/05/2022</strong>.<br>I tempi di consegna sono indicativi e dipendono dal tipo di lavorazione</p>
                        </bxs-card>

                        <bxs-card>
                            <h5>Modalità di pagamento disponibili</h5>

                            <bxs-row>
                                <bxs-col
                                class="pb-0">
                                    [icona]
                                </bxs-col>
                                <bxs-col
                                class="pb-0">
                                    [icona]
                                </bxs-col>
                                <bxs-col
                                class="pb-0">
                                    [icona]
                                </bxs-col>
                                <bxs-col
                                class="pb-0">
                                    [icona]
                                </bxs-col>
                                <bxs-col
                                class="pb-0">
                                    [icona]
                                </bxs-col>
                            </bxs-row>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col cols="4">
                        <bxs-card class="mb-ui">
                            <h3 class="mb-ui">TOTALE</h3>

                            <div class="flex nowrap justify-between mb-1">
                                <p>Subtotale</p>
                                <p>{{ $filters.toHumanPrice($api.me.checkout.cashier.gross) }}</p>
                            </div>

                            <div class="flex nowrap justify-between mb-1">
                                <p>Spedizione</p>
                                <p>{{ $filters.toHumanPrice($api.me.checkout.cashier.shipping_gross) }}</p>
                            </div>

                            <bxs-divider class="my-2" />

                            <div class="flex nowrap justify-between">
                                <p><b>Totale (iva inclusa)</b></p>
                                <p><b>{{ $filters.toHumanPrice($api.me.checkout.cashier.sell) }}</b></p>
                            </div>

                            <bxs-btn
                            color="primary"
                            block
                            to="/checkout/indirizzi"
                            class="mt-ui">Procedi</bxs-btn>
                        </bxs-card>

                        <bxs-card>
                            <bxs-collapse-item
                            label="Inserisci un codice sconto"
                            hide-border>
                                <bxs-form>
                                    <bxs-text-field label="Codice sconto" name="codice sconto" />
                                </bxs-form>
                            </bxs-collapse-item>
                        </bxs-card>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'cart'
}
</script>