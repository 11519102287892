<template>
    <bxs-card :to="to">
        <bxs-figure
        v-if="img"
        :src="img"
        max-width="100%" />
        <bxs-figure
        v-else />

        <div class="pa-ui">
            <h5>{{ title }}</h5>
            <!-- <slot /> -->
        </div>
    </bxs-card>
</template>

<script>
export default {
    name: 'page_card',
    props: {
        to: {
            type: String,
            reuqired: false,
            default: '/'
        },
        img: {
            type: String,
            reuqired: false,
            default: null
        },
        title: {
            type: String,
            reuqired: false,
            default: null
        }
    }
}
</script>