<template>
    <div class="tsm-top-banner">
        <bxs-carousel
        :items="items"
        autoplay>
            <template #item="{ item }">
                <router-link
                v-if="item.link.on"
                :to="item.link.to"
                v-html="item.title"
                :class="['tsm-top-banner--slide-item', item.classes]"
                :style="[{
                    backgroundImage: item.img ? `url(${item.img.url})` : '',
                }, item.styles]"></router-link>

                <div
                v-else
                v-html="item.title"
                :class="['tsm-top-banner--slide-item', item.classes]"
                :style="[{
                    backgroundImage: item.img ? `url(${item.img.url})` : '',
                }, item.styles]"></div>
            </template>
        </bxs-carousel>
    </div>
</template>

<script>
export default {
    name: 'top_banner',
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        }
    }
}
</script>

<style lang="scss" scoped>
.tsm-top-banner {
    position: relative;
    background-color: var(--top-banner-background-color);
    font-size: var(--top-banner-font-size);
    font-weight: var(--top-banner-font-weight);
    color: var(--top-banner-color);
    height: var(--top-banner-height);
    overflow: hidden;

    .bxs-carousel {
        height: 100%;
    }

    &--slide-item {
        width: 100%;
        height: 100%;
        position: relative;

        // display: flex;
        // flex-flow: row nowrap;
        // align-items: center;
        // justify-content: center;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        text-align: center;
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
        white-space: nowrap;
        vertical-align: middle;
        padding-top: 7px;

        overflow: hidden;

        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
}
</style>