<template>
    <div class="newsletter-box">
        <div class="mb-ui">
            <h4>Iscriviti alla newsletter</h4>
            <p class="h5">Per te tante offerte esclusive, news sui nostri tappeti, consigli per la casa e in più uno sconto del 5%*</p>
        </div>

        <div class="mb-ui">
            <bxs-form class="mb-ui">
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <bxs-text-field
                        v-model="email"
                        name="email"
                        label="Il tuo indirizzo email"
                        class="flex-1 mr-4" />
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <bxs-btn
                        block
                        class="ml-4">Iscriviti</bxs-btn>
                    </bxs-col>
                </bxs-row>

                <div class="flex nowrap align-center">
                </div>
            </bxs-form>

            <div>
                <small>Consulta la nostra politica sulla privacy per sapere di più sul trattamento dei tuoi dati. Puoi disiscriverti quando vuoi, senza nessun costo.</small>
                <br>
                <small>* Buono valido una sola volta</small>
            </div>
        </div>

        <div>
            <p>Con la newsletter di Tappeto Su Misura vogliamo aggiungere ancora più valore al servizio che ti proponiamo: le ultime novità dal nostro laboratorio, news sui servizi di Tappeto Su Misura, suggerimenti per arredare e decorare casa con stile e praticità, sconti e promozioni esclusive sui nostri tappeti. </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsletter_box',
    data () {
        return {
            email: null
        }
    }
}
</script>