<template>
    <div class="checkout">
        <section>
            <bxs-layout>
                <swiper
                :modules="swiper_modules"
                :slides-per-view="$store.state.is_mobile ? 3 : 6">
                    <swiper-slide class="text-center">
                        <div class="circle">
                            <span>Accedi</span>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="text-center">
                        <div
                        class="circle"
                        :class="{ 'active': $route.name === 'checkout_addresses' }"
                        @click="$router.push('/checkout/indirizzi')">
                            <span>Indirizzo</span>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="text-center">
                        <div
                        class="circle"
                        :class="{ 'active': $route.name === 'checkout_shipping' }"
                        @click="$router.push('/checkout/spedizione')">
                            <span>Spedizione</span>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="text-center">
                        <div
                        class="circle"
                        :class="{ 'active': $route.name === 'checkout_payment' }"
                        @click="$router.push('/checkout/pagamento')">
                            <span>Pagamento</span>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="text-center">
                        <div
                        class="circle"
                        :class="{ 'active': $route.name === 'checkout_recap' }"
                        @click="$router.push('/checkout/riepilogo')">
                            <span>Riepilogo</span>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="text-center">
                        <div
                        class="circle"
                        :class="{ 'active': $route.name === 'checkout_completed' }">
                            <span>Fatto!</span>
                        </div>
                    </swiper-slide>
                </swiper>

                <div class="my-4"></div>

                <router-view />
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'

export default {
    name: 'checkout',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiper_modules: [Navigation]
        }
    },
    mounted () {
        console.log(this.$route)
    }
}
</script>

<style lang="scss" scoped>
// .swiper {
//     overflow: visible;
// }

// a {
//     position: relative;

//     span {
//         opacity: 0;
//         position: absolute;
//         top: 100%;
//         transform: translateX(-50%);
//         transition: 0.2s ease-out;
//     }

//     &:hover {
//         span {
//             opacity: 1;
//         }
//     }
// }

.circle {
    position: relative;
    border: 1px solid var(--color-white-lighten-3);
    color: var(--color-white-lighten-3);
    margin: auto;
    height: 120px;
    width: 120px;
    border-radius: 60px;

    &.active {
        border: 1px solid var(--color-black);
        color: var(--color-black);
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.router-link-active span {
    opacity: 1;
}
</style>