<template>
    <div class="checkout-shipping">
        <bxs-data-lazy
        ref="dataLazy"
        :promises="() => $api.me.checkout.getShippingsMethods()">
            <template #default="{ data }">
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="8">
                        <bxs-card>
                            <div class="mb-ui">
                                <h3 class="mb-4">Scegli il tipo di spedizione</h3>
                                <bxs-divider />
                            </div>

                            <div>
                                <ul>
                                    <li
                                    v-for="item in data"
                                    :key="item.method">
                                        <bxs-radio-field
                                        v-for="item2 in item.docs"
                                        :key="item2.id"
                                        v-model="model"
                                        :id="item2.id"
                                        name="shipping_method"
                                        :value="item2.id">
                                            <span>{{ item2.name }}</span> <br>
                                            <span><i>{{ item2.description }}</i></span>
                                        </bxs-radio-field>

                                        <!-- <bxs-radio-field
                                        v-model="$api.me.checkout.shipping.method"
                                        id="pickup"
                                        name="shipping_method"
                                        value="pickup">Ritiro in negozio</bxs-radio-field> -->
                                    </li>
                                </ul>
                            </div>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="4">
                        <bxs-card>
                            <bxs-btn
                            :disabled="!model"
                            to="/checkout/pagamento"
                            block
                            color="primary"
                            @click="next">Procedi</bxs-btn>
                        </bxs-card>
                    </bxs-col>
                </bxs-row>
            </template>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'checkout_shipping',
    data () {
        return {
            model: null
        }
    },
    created () {
        this.model = this.$api.me.checkout.shipping.method && this.$api.me.checkout.shipping.method.id ? this.$api.me.checkout.shipping.method.id : this.$api.me.checkout.shipping.method
    },
    methods: {
        async next () {
            try {
                await this.$api.me.checkout.addShippingMethod(this.model)

                this.$router.push('/checkout/pagamento')
            } catch (err) {
                alert(err.message || err.name || err)
            }
        }
    }
}
</script>