<template>
    <div class="err-404">
        <section>
            <bxs-layout>
                <div class="text-center">
                    <h1 class="mb-2">Ops, error 404</h1>
                    <p class="mb-ui">Pagina non trovata o in costruzione</p>

                    <div>
                        <bxs-btn to="/">Riparti</bxs-btn>
                    </div>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'err404'
}
</script>
