<template>
    <header class="bxs-navbar">
        <bxs-layout>
            <nav>
                <bxs-row
                no-gutters
                align="center">
                    <bxs-col
                    cols="3"
                    order-ds="1">
                        <logo />
                    </bxs-col>

                    <bxs-col
                    cols="9"
                    ds="3"
                    order-ds="3"
                    class="text-right">
                        <bxs-btn
                        to="/me"
                        icon>
                            <bxs-icon name="person"></bxs-icon>
                            <span
                            v-if="$api.auth.me"
                            class="text-primary text-600 ml-1">-</span>
                        </bxs-btn>

                        <bxs-btn
                        icon
                        @click="menu.kit = true">
                            <bxs-icon name="book"></bxs-icon>
                        </bxs-btn>

                        <bxs-btn
                        to="/me/desideri"
                        icon>
                            <bxs-icon name="heart"></bxs-icon>
                        </bxs-btn>

                        <checkout-drawer inline>
                            <template #activator="{ on }">
                                <bxs-btn
                                v-on="on"
                                icon>
                                    <bxs-icon name="cart"></bxs-icon>
                                    <span
                                    v-if="$api.me.checkout.cart_count"
                                    class="text-primary text-600 ml-1">{{ $api.me.checkout.cart_count }}</span>
                                </bxs-btn>
                            </template>
                        </checkout-drawer>

                        <bxs-btn
                        icon
                        @click="menu.mobile = true">
                            <bxs-icon name="hamburger"></bxs-icon>
                        </bxs-btn>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6"
                    order-ds="2">
                        <search-box />
                    </bxs-col>
                </bxs-row>
            </nav>

            <!--  -->
            <nav
            v-if="!$store.state.is_mobile"
            class="mt-ui">
                <ul class="flex nowrap">
                    <li
                    v-for="dep in $api.shop.deps"
                    :key="dep.id"
                    class="small text-uppercase mr-ui mr-last-0">
                        <router-link :to="`/catalogo/${dep.slug}`">{{ dep.name }}</router-link>
                    </li>

                    <li
                    v-for="page in $api.shop.pages"
                    :key="page.id"
                    class="small text-uppercase mr-ui mr-last-0">
                        <router-link :to="`/${page.slug}`">{{ page.name }}</router-link>
                    </li>
                </ul>
            </nav>
        </bxs-layout>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            mobile menu
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-drawer
        v-model="menu.mobile"
        max-width="520">
            <template #header-logo>
                <logo max-width="70px" />
            </template>

            <div class="px-layout py-ui">
                <ul class="mb-12 h5">
                    <li
                    v-for="dep in $api.shop.deps"
                    :key="dep.id"
                    class="mb-4">
                        <router-link :to="`/catalogo/${dep.slug}`">{{ dep.name }}</router-link>
                    </li>

                    <li
                    v-for="page in $api.shop.pages"
                    :key="page.id"
                    class="mb-4">
                        <router-link :to="`/${page.slug}`">{{ page.name }}</router-link>
                    </li>
                </ul>

                <ul class="mb-ui h6">
                    <li class="mb-2">
                        <h6>Tappeto Su Misura</h6>
                    </li>
                    <li class="text-white-darken-2 mb-2">
                        <router-link to="/chi-siamo">La nostra azienda</router-link>
                    </li>
                    <li class="text-white-darken-2">
                        <router-link to="/about">Perchè comprare da noi</router-link>
                    </li>
                </ul>

                <bxs-divider />

                <ul class="mt-ui h6">
                    <li class="mb-2">
                        <h6>Hai bisogno di aiuto?</h6>
                    </li>
                    <li class="text-white-darken-2 mb-2">
                        <router-link to="/assistenza-clienti">Assistenza clienti</router-link>
                    </li>
                    <li class="text-white-darken-2 mb-2">
                        <router-link to="/domande-frequenti">Domande Frequenti - FAQ</router-link>
                    </li>
                    <li class="text-white-darken-2 mb-2">
                        <router-link to="/spedizioni">Spese di spedizioni</router-link>
                    </li>
                    <li class="text-white-darken-2">
                        <router-link to="/pagamenti">Metodi di pagamento</router-link>
                    </li>
                </ul>
            </div>
        </bxs-drawer>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            kit menu
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-menu
        v-model="menu.kit"
        title="Richiedi kit capioni"
        solo>
            <div class="pa-layout">
                <div class="list">
                    <div
                    v-for="el in 4"
                    :key="el"
                    class="my-4 element-list">
                        <bxs-row>
                            <bxs-col cols="auto" class="pt-0 pb-4">
                                <bxs-avatar />
                            </bxs-col>

                            <bxs-col class="pt-0 pb-4">
                                <h4>Acqualine</h4>
                                <p>Rosso</p>
                            </bxs-col>

                            <bxs-col cols="auto" class="pt-0 pb-4">
                                <div class="text-center">
                                    <a><bxs-icon name="trash" height="24px" wudth="24px" /><br><small>Elimina</small></a>
                                </div>
                            </bxs-col>
                        </bxs-row>
                    </div>
                </div>

                <div class="mt-8">
                    <bxs-row no-gutters>
                        <bxs-col>
                            <h6>Totale:</h6>
                        </bxs-col>

                        <bxs-col cols="auto">
                            <h6>9,90 €</h6>
                        </bxs-col>
                    </bxs-row>

                    <bxs-btn class="mt-4" block>Aggiungi al carrello</bxs-btn>
                </div>
            </div>

            <div class="pa-layout">
                <h2 class="mb-8">Nessun kit campione disponibile</h2>
            </div>
        </bxs-menu>
    </header>
</template>

<script>
import SearchBox from '@/components/project/SearchBox.vue'
import CheckoutDrawer from '@/components/project/CheckoutDrawer.vue'

export default {
    name: 'bxs-navbar',
    components: {
        'search-box': SearchBox,
        'checkout-drawer': CheckoutDrawer
    },
    data () {
        return {
            menu: {
                cart: false,
                kit: false,
                mobile: false
            }
        }
    }
    // watch: {
    //     '$route' (newVal) {
    //         console.log('> beforeRouteUpdate Navbar')
    //         for (const el in this.menu) {
    //             this.menu[el] = false
    //         }
    //     }
    // }
}
</script>

<style lang="scss" scoped>
.bxs-navbar {
    position: relative;
    width: 100%;
    min-height: var(--navbar-min-height);

    background-color: var(--navbar-background-color);

    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);

    z-index: var(--layer-navbar);

    transition: transform 0.5s ease-out;

    > .bxs-layout {
        height: 100%;
        padding-top: var(--size-layout);
        padding-bottom: var(--size-layout);
        // border-bottom: 1px solid var(--divider-color);

        > .bxs-row {
            height: 100%;
            .bxs-col {
                height: 100%;
            }
        }
    }
}

.drawer-list {
    margin-top: calc(var(--size-ui) * 2);
    margin-bottom: calc(var(--size-ui) * 2);

    li {
        margin-top: calc(var(--size-ui) * 1.4);
        margin-bottom: calc(var(--size-ui) * 1.4);
    }
}

.drawer-links {
    margin-top: var(--size-ui);
    margin-bottom: var(--size-ui);

    li {
        margin-top: calc(var(--size-ui) * 0.1);
        margin-bottom: calc(var(--size-ui) * 0.1);
    }
}
</style>