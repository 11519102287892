<template>
    <div class="signin-view">
        <section>
            <bxs-layout max-width="768">
                <div class="mb-ui">
                    <div class="text-center mb-ui">
                        <h1>Sei già registrato?</h1>
                        <bxs-divider></bxs-divider>
                    </div>

                    <bxs-form
                    ref="form"
                    @submit="signin">
                        <div class="mb-ui">
                            <h6 class="mb-1">Email</h6>
                            <bxs-text-field
                            v-model="model.email"
                            required
                            name="email"
                            label="Inserisci l’indirizzo email" />
                        </div>

                        <div class="mb-ui">
                            <h6 class="mb-1">Password</h6>
                            <bxs-text-field
                            v-model="model.password"
                            required
                            name="password"
                            label="Inserisci la password"
                            type="password" />
                        </div>

                        <bxs-btn
                        block
                        @click="$refs.form.submit()">Accedi</bxs-btn>
                    </bxs-form>

                    <div>
                        <router-link to="/password/recovery" class="text-underline">Hai dimenticato la password?</router-link>
                    </div>
                </div>

                <div class="mt-ui">
                    <h3 class="mb-ui">Sei un nuovo cliente?</h3>

                    <bxs-divider></bxs-divider>

                    <div>
                        <bxs-btn to="/signup" outlined block>Registrati</bxs-btn>
                    </div>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'signin',
    data () {
        return {
            model: {
                email: null,
                password: null
            }
        }
    },
    methods: {
        async signin () {
            try {
                await this.$api.auth.signInWithEmailAndPassword(
                    this.model.email,
                    this.model.password
                )
                this.$api.me.checkout.getDoc()
                alert('Login effettuato con successo!')
                this.$router.push(this.$store.state.page_leave || '/me').catch(() => {})
            } catch (err) {
                alert(err.message || err.name)
            }
        }
    }
}
</script>