<template>
    <div class="product-view">
        <section
        v-if="!is_preloaded"
        class="text-center">
            <bxs-loader />
        </section>

        <div v-else>
            <section>
                <bxs-layout>
                    <div class="flex-inline">
                        <a
                        role="button"
                        @click="$router.go(-1)">
                            <bxs-icon
                            width="1em"
                            height="1em"
                            name="arrow-left"
                            class="mr-2" />

                            <span>Indietro</span>
                        </a>
                    </div>

                    <!-- -->
                    <bxs-row>
                        <!-- ------------------------------------------------------------------------------------------------
                            col l
                        ------------------------------------------------------------------------------------------------ -->
                        <bxs-col cols="12" ds="8">
                            <bxs-carousel
                            v-if="item.imgs.length > 0"
                            :items="item.imgs"
                            navigation
                            scrollbar>
                                <template #item="{ item }">
                                    <bxs-figure
                                    ratio="4-3"
                                    :src="item.url" />
                                </template>
                            </bxs-carousel>

                            <bxs-figure
                            v-else
                            ratio="4-3" />
                        </bxs-col>

                        <!-- ------------------------------------------------------------------------------------------------
                            col r
                        ------------------------------------------------------------------------------------------------ -->
                        <bxs-col cols="12" ds="4">
                            <div
                            v-sticky="!$store.state.is_mobile"
                            sticky-offset="{ top: 40 }">
                                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                <div class="mb-ui">
                                    <ul
                                    v-if="item.labels.length > 0"
                                    class="flex wrap mb-ui">
                                        <li
                                        v-for="label in item.labels"
                                        :key="label"
                                        class="mr-1">
                                            <bxs-chip
                                            v-bind="label.styles"
                                            tile
                                            small>{{ label.name }}</bxs-chip>
                                        </li>
                                    </ul>

                                    <h1 class="h3 text-700 text-uppercase mb-2">{{ item.name }}</h1>
                                    <p class="small mb-0">{{ item.description }}</p>
                                </div>

                                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                <div class="mb-ui">
                                    <h6
                                    v-if="item.prices.discount.value > 0"
                                    class="mb-1 text-primary">-{{ item.prices.discount.value }}{{ item.prices.discount.type === 'percentage' ? '%' : '€' }} in meno</h6>

                                    <h5 class="mb-0">
                                        <small
                                        v-if="item.type === 'configurator' || item.type === 'configurable'"
                                        class="mr-2">A partire da </small>

                                        <span
                                        v-if="item.prices.discount.value > 0"
                                        class="text-primary text-line-through mr-3">{{ $filters.toHumanPrice(item.prices.gross) }}</span>

                                        <span>{{ $filters.toHumanPrice(item.prices.sell) }}</span>

                                        <small class="ml-2 text-disabled">(IVA INCLUSA)</small>
                                    </h5>
                                </div>

                                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                <div
                                v-if="item.type === 'configurator' || item.type === 'configurator_proposal'"
                                class="mb-ui">
                                    <div class="mb-ui">
                                        <h6 class="text-600 mb-1">Crea il tuo tappeto su misura</h6>
                                        <p class="mb-0">Scegli il colore, la forma, le dimensioni, il tipo di bordo e crea il tuo tappeto personalizzato</p>
                                    </div>
                                </div>

                                <div
                                v-if="$api.env === 'development'"
                                class="py-ui">
                                    <p>item.prices.sell: {{ item.prices.sell }} - {{ $filters.toHumanPrice(item.prices.sell) }}</p>
                                    <p>item.stock.on: {{ item.stock.on }}</p>
                                    <p>item.stock.quantity: {{ item.stock.quantity }}</p>
                                    <p>item.cart.quantity.min: {{ item.cart.quantity.min }}</p>
                                    <p>item.cart.quantity.max: {{ item.cart.quantity.max }}</p>
                                </div>

                                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                    actions
                                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                <div>
                                    <div v-if="is_available">
                                        <!-- --
                                            variants attributes
                                        -- -->
                                        <ul class="mb-ui">
                                            <li
                                            v-for="(attr, i) in item.attributes"
                                            :key="i"
                                            class="mb-1">
                                                <small>{{ attr.label }}</small>

                                                <bxs-select-field
                                                ref="attributes"
                                                v-model="attr.selected"
                                                :items="attr.options"
                                                item-text="value"
                                                item-value="id"
                                                return-object
                                                :label="attr.label"
                                                show-selected-item-value
                                                elevation
                                                block
                                                close-on-click-content
                                                @change="handleChangeVariantAttribute($event, attr)" />
                                            </li>
                                        </ul>

                                        <div class="mb-1">
                                            <small
                                            v-if="item.stock.on && (item.type === 'simple' || item.type === 'variant')"
                                            class="text-uppercase">
                                                <span v-if="item.stock.quantity > 1">{{ item.stock.quantity }} pezzi rimasti</span>
                                                <span v-else-if="item.stock.quantity === 1">Ultimo pezzo</span>
                                            </small>

                                            <bxs-qty-field
                                            v-if="item.type !== 'configurator' && item.type !== 'configurable'"
                                            v-model="quantity"
                                            :min="item.cart.quantity.min"
                                            :max="item.cart.quantity.max"
                                            style="max-width: 160px;" />
                                        </div>

                                        <div class="flex nowrap align-center">
                                            <bxs-btn
                                            v-if="item.type === 'configurator'"
                                            block
                                            :to="`/prodotti/${$route.params.slug}/configurazioni`"
                                            class="mr-2">Crea il tuo tappeto su misura</bxs-btn>

                                            <bxs-btn
                                            v-else-if="item.type === 'configurable'"
                                            disabled
                                            block
                                            class="mr-2">Seleziona la variante</bxs-btn>

                                            <bxs-btn
                                            v-else
                                            block
                                            color="primary"
                                            :loading="is_loading"
                                            :disabled="is_loading"
                                            class="mr-2"
                                            @click="addToCart">Aggiungi al carrello</bxs-btn>

                                            <bxs-btn
                                            icon
                                            outlined
                                            @click="addToWish">
                                                <bxs-icon name="heart"></bxs-icon>
                                            </bxs-btn>
                                        </div>

                                        <bxs-btn
                                        v-if="item.type === 'configurator_proposal'"
                                        :to="`/prodotti/${this.item.configurator}/configurazioni/${item.id}`"
                                        block
                                        class="mt-2">Personalizza questa proposta</bxs-btn>
                                    </div>

                                    <div v-else>
                                        <bxs-alert class="mb-1">Attualmente non disponibile, l'articolo potrebbe tornare presto</bxs-alert>

                                        <bxs-btn
                                        outlined
                                        block
                                        append-icon="heart"
                                        @click="addToWish">Aggiungi ai desideri</bxs-btn>
                                    </div>
                                </div>

                                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                <div
                                v-if="configurations_proposals.length > 0"
                                class="mt-ui">
                                    <bxs-drawer
                                    block
                                    title="Scopri le nostre proposte">
                                        <template #activator="{ on }">
                                            <bxs-list-item
                                            v-on="on"
                                            append-icon="chevron-right"
                                            spacer
                                            border>Scopri le {{ item.type === 'configurator' ? 'nostre' : 'altre' }} proposte</bxs-list-item>
                                        </template>

                                        <div>
                                            <ul>
                                                <li
                                                v-for="proposal in configurations_proposals"
                                                :key="proposal.id"
                                                class="mb-ui">
                                                    <router-link
                                                    :to="'/prodotti/' + proposal.slug"
                                                    class="d-block mb-1">
                                                        <bxs-figure
                                                        :src="proposal.imgs.length > 0 ? proposal.imgs[0].url : null"
                                                        ratio="4-3" />

                                                        <div class="px-layout">
                                                            <small>{{ proposal.name }}</small>
                                                        </div>
                                                    </router-link>

                                                    <div class="px-layout">
                                                        <small>Colore: {{ proposal.configuration.material.color.name }}</small> <br>
                                                        <small>Forma: {{ proposal.configuration.shape.name }}</small> <br>
                                                        <small>Dimensioni: {{ proposal.configuration.dimensions.a }} x {{ proposal.configuration.dimensions.b }} cm</small> <br>
                                                        <small>Tipo di Bordo: {{ proposal.configuration.border.name }}</small>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </bxs-drawer>
                                </div>

                                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                <!-- <div>
                                    <div class="mb-2">
                                        <p class="mb-0"><i>Indeciso sul tappeto da scegliere?</i></p>
                                        <p class="mb-0">Puoi acquistare fino a 5 campioni di tessuto</p>
                                    </div>

                                    <bxs-drawer
                                    title="Scegli il campione">
                                        <template #activator="{ on }">
                                            <bxs-btn
                                            v-on="on"
                                            outlined
                                            block>Aggiungi al kit</bxs-btn>
                                        </template>

                                        <div class="pa-layout">
                                            <bxs-figure class="mb-6" />

                                            <h4 class="mb-4">Sei indeciso su quale tessuto scegliere o hai dubbi sul colore?</h4>

                                            <p class="mb-8">Scegli fino a un massimo di 5 campioni di tessuto. Riceverai un codice sconto di 9,90 € da utilizzare nel tuo prossimo acquisto.</p>

                                            <bxs-btn outlined block>Inizia</bxs-btn>
                                        </div>

                                        <div>
                                            <div
                                            class="list">
                                                <div
                                                v-for="i in 4"
                                                :key="i"
                                                class="my-4 element-list">
                                                    <div class="flex nowrap align-center pa-2">
                                                        <div>
                                                            <bxs-avatar
                                                            max-width="44" />
                                                        </div>

                                                        <div class="flex-1 pl-2">
                                                            <small>Grigio</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </bxs-drawer>
                                </div> -->
                            </div>
                        </bxs-col>

                        <!-- ------------------------------------------------------------------------------------------------
                            col l 2
                        ------------------------------------------------------------------------------------------------ -->
                        <bxs-col
                        cols="12"
                        ds="8">
                            <div class="bxs-divider"></div>

                            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                            <bxs-drawer
                            v-if="item.features && item.features.length > 0"
                            title="Dettagli prodotto">
                                <template #activator="{ on }">
                                    <bxs-collapse-item
                                    v-on="on"
                                    label="Dettagli prodotto"
                                    icon="chevron-right" />
                                </template>

                                <div>
                                    <ul>
                                        <li
                                        v-for="(feature, i) in item.features"
                                        :key="i">
                                            <div class="flex nowrap justify-between py-2 px-layout">
                                                <p class="ml-2 mb-0">{{ feature.label }}:</p>
                                                <p class="mb-0">{{ feature.value }}</p>
                                            </div>

                                            <bxs-divider></bxs-divider>
                                        </li>
                                    </ul>
                                </div>
                            </bxs-drawer>

                            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                            <bxs-drawer
                            v-if="item.technical_data"
                            title="Dettagli tecnici">
                                <template #activator="{ on }">
                                    <bxs-collapse-item
                                    v-on="on"
                                    :open="false"
                                    label="Dettagli tecnici"
                                    icon="chevron-right" />
                                </template>

                                <div
                                v-html="item.technical_data"
                                class="px-layout"></div>
                            </bxs-drawer>

                            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                            <bxs-drawer
                            title="Dove lo utilizzo">
                                <template #activator="{ on }">
                                    <bxs-collapse-item
                                    v-on="on"
                                    label="Dove lo utilizzo"
                                    icon="chevron-right" />
                                </template>

                                <div class="px-layout">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, dolorem possimus. Nesciunt, odio. Vero magnam eum dolore eveniet sed! Harum obcaecati excepturi aliquam tempora eos officiis dolore incidunt illum enim?</p>
                                </div>
                            </bxs-drawer>

                            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                            <bxs-drawer
                            v-if="item.type === 'configurator' && 'configurator' in item && 'material' in item.configurator.steps && item.configurator.steps.material.colors.length > 0"
                            v-model="menu.color"
                            title="Colori disponibili">
                                <template #activator="{ on }">
                                    <bxs-collapse-item
                                    v-on="on"
                                    label="Colori disponibili"
                                    icon="chevron-right" />
                                </template>

                                <div>
                                    <ul>
                                        <li
                                        v-for="color in item.configurator.steps.material.colors"
                                        :key="color.id">
                                            <div class="flex nowrap align-center px-layout py-2">
                                                <bxs-avatar
                                                max-width="48"
                                                :src="color.img ? color.img.url : '/img/no-photo.png'" />

                                                <small class="ml-2">{{ color.name }}</small>
                                            </div>

                                            <bxs-divider></bxs-divider>
                                        </li>
                                    </ul>
                                </div>
                            </bxs-drawer>

                            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                            <bxs-drawer
                            v-if="item.type === 'configurator' && 'configurator' in item && 'shapes' in item.configurator.steps && item.configurator.steps.shapes.length > 0"
                            v-model="menu.shape"
                            title="Forme disponibili">
                                <template #activator="{ on }">
                                    <bxs-collapse-item
                                    v-on="on"
                                    label="Forme disponibili"
                                    icon="chevron-right" />
                                </template>

                                <div>
                                    <ul>
                                        <li
                                        v-for="shape in item.configurator.steps.shapes"
                                        :key="shape.id">
                                            <div class="flex nowrap align-center px-layout py-2">
                                                <bxs-avatar
                                                max-width="48"
                                                :src="shape.img ? shape.img.url : '/img/no-photo.png'" />

                                                <small class="ml-2">{{ shape.name }}</small>
                                            </div>

                                            <bxs-divider></bxs-divider>
                                        </li>
                                    </ul>
                                </div>
                            </bxs-drawer>
                        </bxs-col>
                    </bxs-row>
                </bxs-layout>
            </section>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section
            v-if="item.content"
            class="mute">
                <bxs-layout>
                    <div v-html="item.content"></div>
                </bxs-layout>
            </section>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section-guide />
        </div>
    </div>
</template>

<script>
export default {
    name: 'product',
    data () {
        return {
            is_preloaded: false,
            is_loading: true,
            key: 1,
            item: null,
            configurations_proposals: [],
            //
            colors: [
                {
                    id: '01',
                    name: 'Rosso',
                    img: 'https://cdn.tappetosumisura.it/files/materials/ACQUALINENerogrigio370-1666364007546.jpg',
                    value: 'red'
                },
                {
                    id: '02',
                    name: 'Verde',
                    img: 'https://cdn.tappetosumisura.it/files/materials/ACQUALINEpanna050-1666364009247.jpg',
                    value: 'green'
                },
                {
                    id: '03',
                    name: 'Blu',
                    img: '',
                    value: 'blue'
                }
            ],
            color: null,
            form: null,
            menu: {
                color: false,
                form: false
            },
            get_variant: {
                error: null,
                loading: false
            },
            quantity: 1
        }
    },
    computed: {
        is_available () {
            // è disponibile
            // è in stock o no e se è in stock non è esaurito
            const cond_stock = this.item.type === 'configurator' || !this.item.stock.on || (this.item.stock.on && this.item.stock.quantity > this.item.stock.thresholds.oos)

            return this.item.is.available && cond_stock
        },
        attributesAreAllSeleced () {
            return this.item.attributes.every(i => i.selected)
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start (route_to) {
            console.log('Product start()', route_to)

            // if (route_to) {
            //     this.$store.commit('set_is_loading', true)
            // }

            try {
                this.item = await this.$api.catalog.getProduct(
                    route_to ? route_to.params.slug : this.$route.params.slug
                )

                if (this.item.type === 'variant') {
                    this.item.attributes.forEach((attr) => {
                        attr.options.forEach(this.checkOption)
                    })
                } else if (this.item.type === 'configurator' || this.item.type === 'configurator_proposal') {
                    this.$api.catalog.getProductsConfigurationsProposals(this.item.id).then((docs) => {
                        this.configurations_proposals = docs
                    })
                }
            } catch (err) {
                console.error(err)
            } finally {
                this.is_preloaded = true
                this.is_loading = false
                // this.$store.commit('set_is_loading', false)
            }
        },
        //
        checkOption (option) {
            console.log('Product checkOption()', option)
            if (!option) return

            this.item.attributes.forEach((attr) => {
                attr.options.forEach((opt) => {
                    opt.disabled = option.attribute !== attr.id && option.variants_ids.every(v => !opt.variants_ids.includes(v))

                    if (attr.selected === opt.id && opt.disabled) {
                        attr.selected = null
                    }
                })
            })
        },
        handleChangeVariantAttribute (val, attribute) {
            console.log('Product handleChangeVariantAttribute()', val)

            this.checkOption(val)

            if (this.item.attributes.every(a => a.selected)) {
                console.log('this.$api.catalog.getProduct()')

                const options_ids = this.item.attributes.filter(a => a.selected).map(a => a.selected.id)
                this.is_loading = true

                this.$api.catalog.getProduct(
                    this.item.type === 'configurable' ? this.item.id : this.item.configurable,
                    { options: options_ids }
                ).then((doc) => {
                    console.log('variant', doc)

                    // this.item.id = doc.id
                    // this.item.name = doc.name
                    // if (doc.imgs.length > 0) this.item.imgs = doc.imgs
                    // this.item.is = doc.is
                    // this.item.stock = doc.stock
                    // this.item.cart = doc.cart
                    // this.item.slug = doc.cart
                    // this.item.meta = doc.meta
                    // this.quantity = 1

                    this.$router.push('/prodotti/' + doc.slug)
                }).catch((err) => {
                    this.is_loading = false
                    console.log('catalog.getProduct() err', err)
                    alert(err.message || err.name)
                })
            }
        },
        async addToCart () {
            this.loading = true

            try {
                await this.$api.me.checkout.addToCart(this.item.id, this.quantity)
                alert('Articolo aggiunto al carrello')
            } catch (err) {
                alert(err.message || err.name)
            } finally {
                this.loading = false
            }
        },
        async addToWish () {
            this.loading = true

            if (!this.$api.auth.me) {
                return this.$store.commit('set_drawer', {
                    key: 'sign',
                    value: true
                })
            }

            try {
                await this.$api.me.wish.addProduct(this.item.id)
            } catch (err) {
                if (err.status_code === 401) {
                    this.$store.commit('set_drawer', {
                        key: 'sign',
                        value: true
                    })
                }
            } finally {
                this.loading = false
            }
        },
        async toCustomProposal () {
            // this.$router.push(`/prodotti/${this.item.configurator}/configurazioni/${id}`)

            try {
                const { id } = await this.$api.me.products_configurations.create({
                    ...this.item.configuration,
                    configurator: this.item.configurator
                })

                this.$router.push(`/prodotti/${this.item.configurator}/configurazioni/${id}`)
            } catch (err) {
                alert(err.message || err.name)
            }
        }
    },
    beforeRouteUpdate (route_to) {
        console.log('product beforeRouteUpdate()', route_to)
        this.start(route_to)
    }
}
</script>
