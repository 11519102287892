<template>
    <div class="me-order-row--view">
        <bxs-data-lazy
        ref="data"
        :promises="() => $api.me.orders.getOrderRow($route.params.row_id)">
            <template #default="{ data }">
                <section>
                    <bxs-layout>
                        row {{ data.id }}
                    </bxs-layout>
                </section>
            </template>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'me_order_row'
}
</script>