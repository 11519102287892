<template>
    <bxs-col cols="12" ds="8">
        <bxs-card
        elevation
        outlined
        rounded
        class="pa-6">
            <h3 class="mb-4">I miei indirizzi</h3>

            <bxs-divider />

            <p class="my-4">Visualizza e gestisci i tuoi indirizzi</p>

            <bxs-menu
            title="Inserisci un nuovo indirizzo">
                <template #activator="{ on }">
                    <bxs-btn v-on="on" block outlined class="mt-4">Aggiungi indirizzo</bxs-btn>
                </template>

                <div class="pa-layout">
                    <bxs-form class="mt-4">
                        <label>Nome *</label>
                        <bxs-text-field label="Inserisci il tuo nome" name="nome" class="mb-4" />

                        <label>Cognome *</label>
                        <bxs-text-field label="Inserisci il tuo cognome" name="cognome" class="mb-4" />

                        <label>Indirizzo *</label>
                        <bxs-text-field label="Inserisci il tuo indirizzo" name="indirizzo" class="mb-4" />

                        <label>CAP *</label>
                        <bxs-text-field label="Inserisci il tuo CAP" name="cap" class="mb-4" />

                        <label>Città *</label>
                        <bxs-text-field label="Inserisci la tua città" name="città" class="mb-4" />

                        <label>Telefono *</label>
                        <bxs-text-field label="Inserisci il tuo numero di telefono" name="telefono" class="mb-4" />

                        <p class="my-4">Il tuo numero di telefono sarà utilizzato per comunicare con te in caso di problemi con il tuo ordine o con la tua spedizione.</p>

                        <label class="mt-4">Note *</label>
                        <bxs-textarea-field label="Inserisci le tue note" name="note" class="mb-4" />

                        <bxs-switch-field label="Indirizzo di fatturazione" name="fattura" class="mb-4" />

                        <br>

                        <bxs-switch-field label="Indirizzo di consegna" name="consegna" class="mb-4" />

                        <bxs-btn block class="mt-4">Salva</bxs-btn>
                    </bxs-form>
                </div>
            </bxs-menu>
        </bxs-card>

        <bxs-row>
            <bxs-col>
                <bxs-card
                elevation
                outlined
                rounded
                class="pa-6 mb-8">
                    <h3 class="mb-4">Indirizzo di consegna</h3>

                    <bxs-divider />

                    <bxs-data-lazy delay="2000">
                        <p class="mt-4 mb-2">Gino Paolino</p>
                        <p class="my-2">via Roma 1</p>
                        <p class="my-2">Mongrando (BI) 13888</p>
                        <p class="mt-2 mb-4">2491306875</p>

                        <bxs-row>
                            <bxs-col class="py-0">
                                <bxs-btn block outlined disabled>Elimina</bxs-btn>
                            </bxs-col>

                            <bxs-col class="py-0">
                                <bxs-menu
                                title="Modifica indirizzo di consegna">
                                    <template #activator="{ on }">
                                        <bxs-btn v-on="on" block outlined>Modifica</bxs-btn>
                                    </template>

                                    <div class="pa-layout">
                                        <bxs-form class="mt-4">
                                            <label>Nome *</label>
                                            <bxs-text-field label="Inserisci il tuo nome" name="nome" class="mb-4" />

                                            <label>Cognome *</label>
                                            <bxs-text-field label="Inserisci il tuo cognome" name="cognome" class="mb-4" />

                                            <label>Indirizzo *</label>
                                            <bxs-text-field label="Inserisci il tuo indirizzo" name="indirizzo" class="mb-4" />

                                            <label>CAP *</label>
                                            <bxs-text-field label="Inserisci il tuo CAP" name="cap" class="mb-4" />

                                            <label>Città *</label>
                                            <bxs-text-field label="Inserisci la tua città" name="città" class="mb-4" />

                                            <label>Telefono *</label>
                                            <bxs-text-field label="Inserisci il tuo numero di telefono" name="telefono" class="mb-4" />

                                            <p class="my-4">Il tuo numero di telefono sarà utilizzato per comunicare con te in caso di problemi con il tuo ordine o con la tua spedizione.</p>

                                            <label class="mt-4">Note *</label>
                                            <bxs-textarea-field label="Inserisci le tue note" name="note" class="mb-4" />

                                            <bxs-row>
                                                <bxs-col class="py-0">
                                                    <bxs-btn block outlined>Annulla</bxs-btn>
                                                </bxs-col>

                                                <bxs-col class="py-0">
                                                    <bxs-btn block>Salva</bxs-btn>
                                                </bxs-col>
                                            </bxs-row>
                                        </bxs-form>
                                    </div>
                                </bxs-menu>
                            </bxs-col>
                        </bxs-row>
                    </bxs-data-lazy>
                </bxs-card>
            </bxs-col>

            <bxs-col>
                <bxs-card
                elevation
                outlined
                rounded
                class="pa-6 mb-8">
                    <h3 class="mb-4">Indirizzo di spedizione</h3>

                    <bxs-divider />

                    <bxs-data-lazy delay="2000">
                        <p class="mt-4 mb-2">Gino Paolino</p>
                        <p class="my-2">via Roma 1</p>
                        <p class="my-2">Mongrando (BI) 13888</p>
                        <p class="mt-2 mb-4">2491306875</p>

                        <bxs-row>
                            <bxs-col class="py-0">
                                <bxs-btn block outlined disabled>Elimina</bxs-btn>
                            </bxs-col>

                            <bxs-col class="py-0">
                                <bxs-menu
                                title="Modifica indirizzo di spedizione">
                                    <template #activator="{ on }">
                                        <bxs-btn v-on="on" block outlined>Modifica</bxs-btn>
                                    </template>

                                    <div class="pa-layout">
                                        <bxs-form class="mt-4">
                                            <label>Nome *</label>
                                            <bxs-text-field label="Inserisci il tuo nome" name="nome" class="mb-4" />

                                            <label>Cognome *</label>
                                            <bxs-text-field label="Inserisci il tuo cognome" name="cognome" class="mb-4" />

                                            <label>Indirizzo *</label>
                                            <bxs-text-field label="Inserisci il tuo indirizzo" name="indirizzo" class="mb-4" />

                                            <label>CAP *</label>
                                            <bxs-text-field label="Inserisci il tuo CAP" name="cap" class="mb-4" />

                                            <label>Città *</label>
                                            <bxs-text-field label="Inserisci la tua città" name="città" class="mb-4" />

                                            <label>Telefono *</label>
                                            <bxs-text-field label="Inserisci il tuo numero di telefono" name="telefono" class="mb-4" />

                                            <p class="my-4">Il tuo numero di telefono sarà utilizzato per comunicare con te in caso di problemi con il tuo ordine o con la tua spedizione.</p>

                                            <label class="mt-4">Note *</label>
                                            <bxs-textarea-field label="Inserisci le tue note" name="note" class="mb-4" />

                                            <bxs-row>
                                                <bxs-col class="py-0">
                                                    <bxs-btn block outlined>Annulla</bxs-btn>
                                                </bxs-col>

                                                <bxs-col class="py-0">
                                                    <bxs-btn block>Salva</bxs-btn>
                                                </bxs-col>
                                            </bxs-row>
                                        </bxs-form>
                                    </div>
                                </bxs-menu>
                            </bxs-col>
                        </bxs-row>
                    </bxs-data-lazy>
                </bxs-card>
            </bxs-col>
        </bxs-row>
    </bxs-col>
</template>

<script>
export default {
    name: 'indirizzi'
}
</script>