export function auth(roles, { to, from, router, next }) {
    const api = window.$api
    console.log('mid auth', api.auth.me)

    if (typeof roles === 'string') {
        roles = roles.split(',').map(i => i.toUpperCase())
    }

    if (!api.auth.me) {
        if (
            to.name !== 'signin' &&
            to.name !== 'signup' &&
            to.name !== 'password_recovery' &&
            to.name !== 'password_reset'
        ) {
            return next('/signin')
        }
    }

    return next()
}

export function saveQuery({ to, from, router, next }) {
    if (!to.query) return router.push('/').catch(() => { })
    return next()
}

//

export function nextFactory(context, middlewares, index) {
    const subSequentMiddleware = middlewares[index]
    if (!subSequentMiddleware) return context.next

    return (...parameters) => {
        context.next(...parameters)
        const nextMiddleware = nextFactory(context, middlewares, index + 1)
        subSequentMiddleware({ ...context, next: nextMiddleware })
    }
}