<template>
    <bxs-col cols="12" ds="8">
        <bxs-card
        elevation
        outlined
        rounded
        class="pa-6 mb-8">
            <h3 class="mb-4">I miei messaggi</h3>

            <bxs-divider />

            <p class="my-4">Visualizza i messaggi che hai inviato e le risposte che hai ricevuto</p>

            <p class="mt-4"><strong>Hai bisogno di aiuto?</strong></p>

            <bxs-menu
            title="Scrivici">
                <template #activator="{ on }">
                    <bxs-btn v-on="on" block outlined class="mt-4">Scrivici</bxs-btn>
                </template>

                <div class="pa-layout">
                    <p class="mb-8">Ciao Gino, come possiamo aiutarti?</p>

                    <p class="my-4">Puoi leggere le domande frequenti qui</p>

                    <bxs-btn block outlined class="my-4">Domande frequenti</bxs-btn>

                    <p class="my-4">Oppure scrivici un messagio compilando i campi qui sotto, ti risponederemo il prima possibile</p>

                    <bxs-form class="my-4">
                        <label>Nome completo *</label>
                        <bxs-text-field label="Inserisci il tuo nome e cognome" class="mb-4" />

                        <label>Nome completo *</label>
                        <bxs-text-field label="Inserisci il tuo nome e cognome" class="mb-4" />

                        <label>Nome completo *</label>
                        <bxs-text-field label="Inserisci il tuo nome e cognome" class="mb-4" />
                    </bxs-form>

                    <p class="my-4">La tua domanda si riferisce a un ordine esistente?</p>

                    <bxs-radio-field id="si" name="ordine_esistente" value="si">
                        Si
                    </bxs-radio-field>

                    <bxs-radio-field id="no" name="ordine_esistente" value="no">
                        No
                    </bxs-radio-field>
                </div>
            </bxs-menu>
        </bxs-card>

        <bxs-card
        elevation
        outlined
        rounded
        class="px-6 mb-8">
            <bxs-menu
            title="Richiesta informazione">
                <template #activator="{ on }">
                    <div v-on="on">
                        <bxs-collapse-item
                        :open="false"
                        hide-border
                        label="Richiesta informazione"
                        icon="chevron-right">
                        </bxs-collapse-item>
                    </div>
                </template>

                <div class="pa-layout">
                    <p>Lorem ipsum</p>
                </div>
            </bxs-menu>
        </bxs-card>

        <bxs-card
        elevation
        outlined
        rounded
        class="px-6 mb-8">
            <bxs-menu
            title="Richiesta informazione">
                <template #activator="{ on }">
                    <div v-on="on">
                        <bxs-collapse-item
                        :open="false"
                        hide-border
                        label="Richiesta informazione"
                        icon="chevron-right">
                        </bxs-collapse-item>
                    </div>
                </template>

                <div class="pa-layout">
                    <p>Lorem ipsum</p>
                </div>
            </bxs-menu>
        </bxs-card>

        <bxs-card
        elevation
        outlined
        rounded
        class="px-6 mb-8">
            <bxs-menu
            title="Richiesta informazione">
                <template #activator="{ on }">
                    <div v-on="on">
                        <bxs-collapse-item
                        :open="false"
                        hide-border
                        label="Richiesta informazione"
                        icon="chevron-right">
                        </bxs-collapse-item>
                    </div>
                </template>

                <div class="pa-layout">
                    <p>Lorem ipsum</p>
                </div>
            </bxs-menu>
        </bxs-card>
    </bxs-col>
</template>

<script>
export default {
    name: 'assistenza'
}
</script>