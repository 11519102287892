<template>
    <div class="tsm-search-box">
        <div>
            <bxs-text-field
            v-model="keywords"
            name="search.keywords"
            placeholder="Ricerca"
            clearable
            solo
            class="ma-0 pa-0"
            @focus="handleFocus"
            @blur.passive="handleBlur" />
        </div>

        <!-- bxs-scrollable -->
        <div
        v-if="is_focused"
        class="tsm-search-box--content">
            <!-- <div
            v-if="loading"
            class="mb-ui text-center">
                <bxs-loader></bxs-loader>
            </div> -->

            <!-- -->
            <div v-if="items.products.length > 0">
                <div>
                    <h6>Prodotti</h6>
                </div>

                <ul>
                    <li
                    v-for="prod in items.products"
                    :key="prod.id">
                        <router-link
                        :to="`/prodotti/${prod.slug}`"
                        @click="setVal(prod.name)">{{ prod.name }}</router-link>
                    </li>
                </ul>

                <!-- <div v-else>
                    <small>Nessun elemento trovato</small>
                </div> -->
            </div>

            <!-- -->
            <div v-if="items.categories.length > 0">
                <div>
                    <h6>Categorie</h6>
                </div>

                <ul>
                    <li
                    v-for="cat in items.categories"
                    :key="cat.id">
                        <router-link
                        :to="`/catalogo/${cat.slug}`"
                        @click="setVal(cat.name)">{{ cat.name }}</router-link>
                    </li>
                </ul>

                <!-- <div v-else>
                    <small>Nessun elemento trovato</small>
                </div> -->
            </div>

            <!-- -->
            <div v-if="items.brands.length > 0">
                <div>
                    <h6>Marchi</h6>
                </div>

                <ul>
                    <li
                    v-for="brand in items.brands"
                    :key="brand.id">
                        <router-link
                        :to="`/catalogo/${brand.slug}`"
                        @click="setVal(brand.name)">{{ brand.name }}</router-link>
                    </li>
                </ul>

                <!-- <div v-else>
                    <small>Nessun elemento trovato</small>
                </div> -->
            </div>

            <!-- -->
            <div v-if="items.pages.length > 0">
                <div>
                    <h6>Pagine</h6>
                </div>

                <ul>
                    <li
                    v-for="page in items.pages"
                    :key="page.id">
                        <router-link
                        :to="`/${page.slug}`"
                        @click="setVal(page.name)">{{ page.name }}</router-link>
                    </li>
                </ul>

                <!-- <div v-else>
                    <small>Nessun elemento trovato</small>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tsm_search_box',
    data () {
        return {
            loading: false,
            keywords: null,
            ghost_keywords: null,
            is_focused: false,
            items: {
                products: [],
                categories: [],
                brands: [],
                pages: []
            }
        }
    },
    computed: {
        has_items () {
            const v = []

            for (const k in this.items) {
                if (this.items[k].length > 0) {
                    v.push(true)
                }
            }

            return v.includes(true)
        }
    },
    watch: {
        'keywords' () {
            this.getItems()
        },
        $route () {
            this.items = {
                products: [],
                categories: [],
                brands: [],
                pages: []
            }
        }
    },
    methods: {
        async getItems () {
            this.loading = true

            this.items = await this.$api.shop.search(this.keywords)

            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        handleFocus () {
            console.log('search box handleFocus()')
            this.is_focused = true
        },
        handleBlur (evt) {
            console.log('search box handleBlur()', evt)
            // evt.stopPropagation()
            this.is_focused = false
        },
        setVal (val) {
            this.keywords = val
            this.is_focused = false
        }
    }
}
</script>

<style lang="scss" scoped>
.tsm-search-box {
    position: relative;
    width: 100%;

    &--content {
        position: absolute;
        width: 100%;
        background-color: var(--color-mute);
        z-index: 1;

        div {
            div {
                padding: 0.75rem var(--size-layout);

                h6 {
                    color: rgba(#000, 0.5);
                    margin-bottom: 0;
                    text-transform: uppercase;
                }

                small {
                    color: rgba(#000, 0.75);
                }
            }

            ul {
                li {
                    a {
                        display: block;
                        padding: 0.75rem var(--size-layout);

                        &:hover {
                            background-color: rgba(#000, 0.05);
                        }
                    }
                }
            }
        }
    }
}
</style>