<template>
    <div class="cart-items">
        <ul>
            <li
            v-for="(item, i) in $api.me.checkout.cart"
            :key="i"
            class="mb-ui mb-0-last">
                <bxs-row no-gutters>
                    <bxs-col cols="3">
                        <router-link
                        :to="`/prodotti/${item.product.slug}`"
                        class="d-block">
                            <bxs-figure
                            :src="item.product.img ? item.product.img.url : null"
                            ratio="4-3" />
                        </router-link>
                    </bxs-col>

                    <bxs-col
                    cols="9"
                    class="pl-ui">
                        <div class="flex nowrap">
                            <div class="flex-1">
                                <div class="mb-4">
                                    <router-link
                                    :to="`/prodotti/${item.product.slug}`"
                                    exact
                                    class="text-500 mb-1">{{ item.product.name }}</router-link>

                                    <ul
                                    v-if="item.product.type === 'variant'"
                                    class="text-black-darken-1">
                                        <li
                                        v-for="(opt, opt_i) in item.product.options"
                                        :key="opt_i">
                                            <small>{{ opt.attribute.label }}: {{ opt.value }}</small>
                                        </li>
                                    </ul>

                                    <ul
                                    v-else-if="item.product.type === 'configurator_proposal'"
                                    class="text-black-darken-1">
                                        <li>
                                            <small>Colore: {{ item.product.configuration.material.color.name }}</small>
                                        </li>
                                        <li>
                                            <small>Misure: {{ item.product.configuration.dimensions.a }} x {{ item.product.configuration.dimensions.b }} cm</small>
                                        </li>
                                    </ul>
                                </div>

                                <div class="mb-ui">
                                    <small>Pronto entro il {{ $filters.toHumanDate(item.shipping.date, 'll') }}</small>
                                </div>
                            </div>

                            <div>
                                <router-link
                                v-if="item.product.type === 'configurator_proposal'"
                                :to="`/prodotti/${item.product.configurator}/configurazioni/${item.product.id}`"
                                class="d-block mb-2">
                                    <bxs-icon
                                    name="edit"
                                    class="mr-2" />
                                    <small>Modifica</small>
                                </router-link>

                                <a
                                role="button"
                                class="d-block text-disabled"
                                @click="removeFromCart(item.id)">
                                    <bxs-icon
                                    name="trash"
                                    class="mr-2" />
                                    <small>Rimuovi</small>
                                </a>
                            </div>
                        </div>

                        <div class="flex nowrap justify-between align-end">
                            <div class="flex-1">
                                <p class="mb-1">Quantità: {{ item.quantity }}</p>
                                <p><b>{{ $filters.toHumanPrice(item.prices.sell) }}</b></p>
                            </div>

                            <div>
                                <a
                                role="button"
                                @click="addToWish(item.id)">
                                    <bxs-icon
                                    name="heart"
                                    class="mr-2" />
                                    <small>Sulla lista dei desideri</small>
                                </a>
                            </div>
                        </div>
                    </bxs-col>
                </bxs-row>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'cart_items',
    methods: {
        removeFromCart (id) {
            try {
                this.$api.me.checkout.removeFromCart(id)
            } catch (err) {
                alert(err.message || err.name)
            }
        },
        addToWish (id) {
            this.loading = true

            try {
                this.$api.me.wish.addProduct(id)
            } catch (err) {
                alert(err.message || err.name)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>