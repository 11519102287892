<template>
    <section>
        <bxs-layout>
            <h1 class="text-center my-8">{{ page.name }}</h1>

            <bxs-row>
                <bxs-col
                v-for="item in items"
                :key="item.id"
                cols="12"
                ds="2">
                    <page-card
                    :to="item.url"
                    :img="item.img"
                    :title="item.title">
                        <p>{{ item.desc }}</p>
                    </page-card>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'page',
    data () {
        return {
            pages: [
                'ambienti',
                'test',
                'chi-siamo',
                'about-us'
            ],
            page: {
                id: 'pag_01',
                slug: 'Ambienti',
                name: "Cerca tra i tappeti su misura in base all'ambiente",
                seo: {
                    title: "Cerca il tappeto in base all'ambiente | Tappeto Su Misura",
                    description: "Trova il tappeto moderno, passatoia o zerbino personalizzato su misura adatto al tuo stile in base all'area in cui verrà inserito nella tua casa, ufficio o negozio."
                }
            },
            items: [
                {
                    id: 'itm_01',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_02',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_03',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_04',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_05',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_06',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_07',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_08',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_09',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_10',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_11',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                },
                {
                    id: 'itm_12',
                    img: 'https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg',
                    title: 'Tappeti soggiorno',
                    desc: 'Tappeti moderni, robusti ed eleganti su misura per donare un nuovo stile al tuo salotto',
                    url: '/soggiorno'
                }
            ]
        }
    },
    async mounted () {
        this.$store.commit('set_is_loading', true)
        await this.play()

        if (!this.pages.includes(this.$route.params.slug)) {
            this.$router.push('/errors/404')
        }
    },
    methods: {
        play () {
            return new Promise(resolve => {
                setTimeout(() => {
                    this.$store.commit('set_is_loading', false)

                    resolve()
                }, 500)
            })
        }
    }
}
</script>