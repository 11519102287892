<template>
    <div class="string-list my-4">
        <p v-if="el">
            {{ el }}
        </p>
        <div v-else>
            <slot name="el" />
        </div>

        <div style="flex: auto;" />

        <p v-if="value" class="text-right">
            {{ value }}
        </p>
        <div v-else>
            <slot name="value" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StringList',
    props: {
        el: {
            type: String,
            required: false,
            default: ''
        },
        value: {
            type: String,
            required: false,
            default: ''
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        boold: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.string-list {
    display: flex;
    flex-flow: row;
    align-items: center;

    div {
        background-color: red;
    }
}
</style>