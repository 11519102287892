<template>
    <div class="checkout-completed">
        <bxs-data-lazy
        ref="dataLazy"
        :promises="() => $api.me.orders.doc($route.params.id)">
            <template #default="{ data }">
                <bxs-card>
                    <div class="text-center">
                        <h2>Grazie per il tuo acquisto</h2>

                        <p class="mt-8 mb-4">Il numero del tuo ordine è: {{ data.code }}</p>

                        <p class="mb-8 mt-4">Riceverai un’email di conferma con i dettagli del tuo ordine</p>

                        <bxs-btn to="/" block>Continua con lo shopping</bxs-btn>
                    </div>
                </bxs-card>
            </template>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'checkout_completed'
}
</script>