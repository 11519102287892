<template>
    <section class="section-deps">
        <bxs-layout>
            <div class="text-center">
                <h2>Tappeti su misura per tutte le esigenze</h2>
            </div>

            <bxs-row
            v-for="(dep, i) in $api.shop.deps"
            :key="dep.id"
            align="center">
                <bxs-col
                cols="12"
                ds="6">
                    <bxs-figure
                    v-if="isOdd(i)"
                    :src="dep.img ? dep.img.url : null"
                    ratio="4-3" />

                    <div v-else>
                        <h4
                        v-html="dep.name"
                        class="text-600 mb-4"></h4>

                        <h6
                        v-if="dep.subtitle"
                        v-html="dep.subtitle"></h6>

                        <p
                        v-html="dep.description"
                        class="mb-4"></p>

                        <bxs-btn :to="`/catalogo/${dep.slug}`">Scopri</bxs-btn>
                    </div>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <div v-if="isOdd(i)">
                        <h4
                        v-html="dep.name"
                        class="text-600 mb-4"></h4>

                        <h6
                        v-if="dep.subtitle"
                        v-html="dep.subtitle"></h6>

                        <p
                        v-html="dep.description"
                        class="mb-4"></p>

                        <bxs-btn :to="`/catalogo/${dep.slug}`">Scopri</bxs-btn>
                    </div>

                    <bxs-figure
                    v-else
                    :src="dep.img ? dep.img.url : null"
                    ratio="4-3" />
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_deps',
    methods: {
        isOdd (num) {
            return num % 2
        }
    }
}
</script>