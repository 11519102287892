<template>
    <div class="payments-icons">
        <ul class="flex wrap align-center">
            <li class="mr-4 mb-4">
                <img src="/img/payments_icons/visa.svg" class="payment-logo">
            </li>

            <li class="mr-4 mb-4">
                <img src="/img/payments_icons/mastercard.svg" class="payment-logo">
            </li>

            <li class="mr-4 mb-4">
                <img src="/img/payments_icons/maestro.svg" class="payment-logo">
            </li>

            <li class="mr-4 mb-4">
                <img src="/img/payments_icons/paypal.svg" class="payment-logo">
            </li>

            <li class="mr-4 mb-4">
                <img src="/img/payments_icons/scalapay.svg" class="payment-logo">
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'payments_icons'
}
</script>

<style lang="scss" scoped>
.payment-logo {
    max-height: 20px;
    border-radius: var(--radius);
    overflow: hidden;
    vertical-align: middle;
}
</style>