<template>
    <div class="credit-card-field">
        <div class="mb-ui">
            <p>
                <b>Inserisci una nuova carta di credito/prepagata o selezionane una.</b>
            </p>
        </div>

        <!-- ------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------ -->
        <div>
            <div
            id="stripe-card-container"
            class="my-2"></div>

            <!--  -->
            <div class="text-right">
                <bxs-btn @click="create">Salva</bxs-btn>
            </div>

            <!--  -->
            <slot name="api-error" />
        </div>

        <!-- ------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------ -->
        <div v-if="items">
            <ul>
                <li
                v-for="(pm, i) in items.docs"
                :key="pm.id"
                class="flex nowrap align-center mb-2">
                    <bxs-radio-field
                    v-model="local_value"
                    :id="pm.id"
                    name="card"
                    :value="pm.id"
                    class="mb-0 mr-ui">{{ pm.brand }} ...{{ pm.last4 }}</bxs-radio-field>

                    <bxs-btn
                    icon
                    @click="$api.me.payments_methods.remove(pm.id).then(() => items.docs.splice(i, 1))">
                        <bxs-icon name="trash"></bxs-icon>
                    </bxs-btn>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// 4242424242424242
// 4000056655665556

import { loadStripe } from '@stripe/stripe-js'

export default {
    name: 'credit_card_field',
    props: {
        modelValue: {
            type: String,
            required: false,
            default: null
        }
    },
    emits: [
        'update:modelValue',
        'update:model-value'
    ],
    data () {
        return {
            show_new: false,
            local_value: this.modelValue,
            stripe: null,
            card_element: null,
            items: null,
            is_valid: false
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        local_value (newVal) {
            this.$emit('update:modelValue', newVal)
        }
    },
    methods: {
        async start () {
            this.stripe = await loadStripe(
                'pk_test_51NID5GHqWowVPE4KT2JM2HWwjUwFS04LFkmUG1jQEU2OuW5f97EU5BwkEmdSWr1FIuhSHiK0AiPltjhFXVtRV0Qy00Oqrbb0lV'
            )

            await this.getItems()

            this.show_new = !this.items.length

            const elements = this.stripe.elements()
            this.card_element = elements.create('card')

            this.$nextTick(() => {
                this.card_element.mount('#stripe-card-container')

                this.card_element.on('change', (val) => {
                    console.log('card change()', val)
                    this.is_valid = val.complete
                })

                this.card_element.on('ready', (val) => {
                    console.log('card ready ()', val)
                })

                this.card_element.on('focus', (val) => {
                    console.log('card focus ()', val)
                })

                // element.clear()
                // element.focus()
            })
        },
        async getItems () {
            const docs = await this.$api.me.payments_methods.list('card')
            this.items = docs
            return docs
        },
        async create () {
            if (!this.is_valid) {
                return console.error('card validation err')
            }

            const card = await this.stripe.createPaymentMethod({
                type: 'card',
                card: this.card_element
            })
            console.log('card', card)

            const card_token = await this.stripe.createToken(this.card_element)
            console.log('card token', card_token)

            const payment_method = await this.$api.me.payments_methods.createCard({
                type: 'card',
                token: card_token.token.id
            })

            this.getItems()

            return payment_method
        }
    }
}
</script>