<template>
    <div class="me-orders">
        <bxs-card class="mb-ui">
            <div class="mb-ui">
                <h3>I miei ordini</h3>
                <bxs-divider />
            </div>

            <p>Visualizza e controlla i tuoi ordini</p>
        </bxs-card>

        <!-- <bxs-data-lazy delay="2000"> -->
        <bxs-data-lazy
        ref="data"
        :promises="() => $api.me.orders.list()">
            <template #default="{ data }">
                <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <ul>
                    <li
                    v-for="item in data.docs"
                    :key="item.id">
                        <bxs-card>
                            <bxs-collapse-item
                            :label="`Ordine nr. ${item.number_id} - ${$filters.toHumanDate(item.created_at)}`"
                            hide-border>
                                <div class="mb-ui">
                                    <p class="mb-2"><strong>Data dell’ordine:</strong> {{ $filters.toHumanDate(item.created_at) }}</p>
                                    <p class="mb-2"><strong>Stato dell’ordine:</strong> {{ item.status }}</p>
                                    <p class="mb-0"><strong>Totale:</strong> {{ $filters.toHumanPrice(item.checkout.cashier.sell) }}</p>
                                </div>

                                <div>
                                    <p class="mb-ui"><strong>Prodotti:</strong></p>

                                    <!--
                                        caricamento file se:
                                        - forma personalizzata
                                        - intarsio
                                    -->

                                    <ul>
                                        <li
                                        v-for="(row, i) in item.rows"
                                        :key="row.id">
                                            <bxs-row>
                                                <bxs-col cols="2">
                                                    <bxs-figure />
                                                </bxs-col>

                                                <bxs-col cols="10">
                                                    <div class="flex nowrap justify-between mb-ui">
                                                        <div>
                                                            <h6 class="mb-1">{{ row.product.name }}</h6>
                                                            <small>
                                                                <b>#{{ row.uid }}</b>
                                                            </small>
                                                        </div>

                                                        <!-- <div>
                                                            <bxs-menu>
                                                                <bxs-list>
                                                                    <bxs-list-item prepend-icon="upload">Carica file forma personalizzata</bxs-list-item>
                                                                </bxs-list>
                                                            </bxs-menu>
                                                        </div> -->
                                                    </div>

                                                    <div>
                                                        <p>Tipo: <span class="text-uppercase">{{ row.product.configuration.type }}</span></p>
                                                        <p class="mt-2">Nome: {{ row.product.configuration.name || '--' }}</p>
                                                        <p class="mt-2">Materiale: {{ row.product.configuration.material.name }}</p>
                                                        <p class="mt-2">Colore: {{ row.product.configuration.material.color.name }}</p>

                                                        <p
                                                        v-if="row.product.configuration.material.backside"
                                                        class="mt-2">Sottofondo: {{ row.product.configuration.material.backside.name }}</p>

                                                        <p
                                                        v-if="row.product.configuration.shape"
                                                        class="mt-2">Forma: {{ row.product.configuration.shape.name }}</p>

                                                        <p class="mt-2">Misure: {{ row.product.configuration.dimensions.a }} (A) cm x {{ row.product.configuration.dimensions.b }} (B) cm <span v-if="row.product.configuration.shape && row.product.configuration.shape.side === 'elle'">x {{ row.product.configuration.dimensions.a1 }} (A1) cm x {{ row.product.configuration.dimensions.b2 }} (B1) cm</span></p>

                                                        <p
                                                        v-if="row.product.configuration.border"
                                                        class="mt-2">Bordo: {{ row.product.configuration.border.name }}</p>
                                                    </div>

                                                    <bxs-card
                                                    v-if="row.product.configuration.shape && row.product.configuration.shape.name === 'Forma personalizzata'"
                                                    class="mt-ui">
                                                        <div class="flex nowrap align-center justify-between">
                                                            <p class="mb-0"><b>File personalizzazione</b></p>

                                                            <file-uploader
                                                            multiple
                                                            inline
                                                            :upload-fn="file => $api.me.orders.uploadRowFile(item.id, row.id, file).then(() => this.$refs.data.call())">
                                                                <template #activator="{ on }">
                                                                    <bxs-btn
                                                                    v-on="on"
                                                                    text
                                                                    color="info"
                                                                    append-icon="plus">
                                                                        Aggiungi gile
                                                                        <!-- <bxs-icon name="plus"></bxs-icon> -->
                                                                    </bxs-btn>
                                                                </template>
                                                            </file-uploader>
                                                        </div>

                                                        <bxs-list v-if="row.user_files.length > 0">
                                                            <bxs-list-item
                                                            v-for="file in row.user_files"
                                                            :key="file.id"
                                                            prepend-icon="file-pdf">
                                                                <!-- <iframe
                                                                :src="file.url"
                                                                width="120"
                                                                height="120" /> -->

                                                                <span>{{ file.name }}.{{ file.extension }} <span class="text-disabled ml-2">{{ file.size.human }}</span> <span class="text-disabled ml-1">{{ $filters.toHumanDate(file.created_at) }}</span> <span v-if="file.status.length > 0" class="ml-2">{{ file.status[0].value }}</span></span>

                                                                <a
                                                                role="button"
                                                                class="ml-3 text-uppercase text-error"
                                                                @click="$api.me.orders.removeRowFile(item.id, row.id, file.id).then(() => this.$refs.data.call())">
                                                                    <small>rimuovi <bxs-icon name="close" class="ml-1" /></small>
                                                                </a>
                                                            </bxs-list-item>
                                                        </bxs-list>

                                                        <bxs-alert
                                                        v-else
                                                        type="warn">Ricordati di caricare il file per la personalizzazione.</bxs-alert>
                                                    </bxs-card>
                                                </bxs-col>
                                            </bxs-row>

                                            <bxs-divider v-if="i < item.rows.length - 1" />
                                        </li>
                                    </ul>
                                </div>

                                <bxs-divider class="my-ui" />

                                <bxs-row>
                                    <bxs-col cols="12" ds="6">
                                        <div>
                                            <h5>Indirizzo di fatturazione</h5>
                                            <p class="mb-2">{{ item.checkout.addresses.billing.name }}</p>
                                            <p class="mb-2">{{ item.checkout.addresses.billing.address }}</p>
                                            <p class="mb-2">{{ item.checkout.addresses.billing.geo.cap }} {{ item.checkout.addresses.billing.geo.city.name }}</p>
                                            <p>{{ item.checkout.addresses.billing.phone }}</p>
                                        </div>
                                    </bxs-col>

                                    <bxs-col cols="12" ds="6">
                                        <div>
                                            <h5>Indirizzo di consegna</h5>
                                            <p class="mb-2">{{ item.checkout.addresses.shipping.name }}</p>
                                            <p class="mb-2">{{ item.checkout.addresses.shipping.address }}</p>
                                            <p class="mb-2">{{ item.checkout.addresses.shipping.geo.cap }} {{ item.checkout.addresses.shipping.geo.city.name }}</p>
                                            <p>{{ item.checkout.addresses.shipping.phone }}</p>
                                        </div>
                                    </bxs-col>
                                </bxs-row>

                                <bxs-divider class="my-ui" />

                                <bxs-row>
                                    <bxs-col cols="12" ds="6">
                                        <div>
                                            <h5>Pagamento</h5>
                                            <p>{{ item.checkout.payment.method.id }}</p>
                                        </div>
                                    </bxs-col>

                                    <bxs-col cols="12" ds="6">
                                        <div>
                                            <h5>Cassa</h5>

                                            <bxs-row>
                                                <bxs-col class="py-1"><p>Subtototale</p></bxs-col>
                                                <bxs-col cols="auto" class="py-1"><p>{{ $filters.toHumanPrice(item.checkout.cashier.gross) }}</p></bxs-col>
                                            </bxs-row>
                                            <!-- <bxs-row>
                                                <bxs-col class="py-1"><p>Iva</p></bxs-col>
                                                <bxs-col cols="auto" class="py-1"><p>215,00 €</p></bxs-col>
                                            </bxs-row> -->
                                            <bxs-row>
                                                <bxs-col class="py-1"><p>Spedizione</p></bxs-col>
                                                <bxs-col cols="auto" class="py-1"><p>{{ $filters.toHumanPrice(item.checkout.cashier.shpping_gross) }}</p></bxs-col>
                                            </bxs-row>

                                            <bxs-divider class="my-2" />

                                            <bxs-row>
                                                <bxs-col class="py-1"><p><strong>Totale (iva inclusa)</strong></p></bxs-col>
                                                <bxs-col cols="auto" class="py-1"><p><strong>{{ $filters.toHumanPrice(item.checkout.cashier.sell) }}</strong></p></bxs-col>
                                            </bxs-row>
                                        </div>
                                    </bxs-col>
                                </bxs-row>

                                <!-- <bxs-divider class="my-ui" /> -->
                            </bxs-collapse-item>
                        </bxs-card>

                        <bxs-divider></bxs-divider>
                    </li>
                </ul>
            </template>
        </bxs-data-lazy>
    </div>
</template>

<script>
import FileUploader from '@/components/FileUploader.vue'

export default {
    name: 'me_orders',
    components: {
        'file-uploader': FileUploader
    }
}
</script>