<template>
    <footer :class="['app--footer bxs-footer', { 'bxs-footer-mobile': $store.state.is_mobile }]" id="footer">
        <!-- ------------------------------------------------------------------------------------------------------------------------ -->
        <section-newsletter />

        <!-- ------------------------------------------------------------------------------------------------------------------------ -->
        <section-infographics />

        <!-- ------------------------------------------------------------------------------------------------------------------------ -->
        <section class="mute">
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    :class="{ 'text-center': $store.state.is_mobile }"
                    cols="12"
                    ds="3">
                    <!-- <bxs-col
                    cols="12"
                    ds="3"> -->
                        <logo
                        max-width="100px"
                        class="mb-ui" />

                        <div class="mb-ui">
                            <p class="mb-0"><small>{{ new Date().getFullYear() }} Tappeto Su Misura ®</small></p>
                            <p class="mb-0"><small>by Rossetti Pavimenti S.R.L.</small></p>
                            <p class="mb-0"><small>via Cottolengo, 8 13900 Biella</small></p>
                            <p class="mb-0"><small><a href="+39015402898">tel. +39 015.40.28.98</a></small></p>
                            <p class="mb-0"><small>fax +39 015.40.28.98</small></p>
                        </div>

                        <div>
                            <p class="mb-0"><small>P.IVA IT01768330027</small></p>
                            <p class="mb-0"><small>REA Biella 162557</small></p>
                            <p class="mb-0"><small>Iscr. Reg. Imprese BI004-14184</small></p>
                            <p class="mb-0"><small>Capitale Sociale: € 27.000,00 interamente versato</small></p>
                        </div>
                    </bxs-col>

                    <bxs-col
                    v-if="!$store.state.is_mobile"
                    cols="3">
                        <div>
                            <h6>Hai bisogno di aiuto?</h6>
                        </div>

                        <ul class="mb-ui">
                            <li>
                                <router-link to="/termini-e-condizioni-di-vendita">Termini e condizioni di vendita</router-link>
                            </li>
                            <li>
                                <router-link to="/spedizioni-e-consegna">Spedizioni e consegna</router-link>
                            </li>
                            <li>
                                <router-link to="/metodi-di-pagamento">Metodi di pagamento</router-link>
                            </li>
                        </ul>

                        <ul class="mb-ui">
                            <li>
                                <router-link to="/assistenza-clienti">Assistenza clienti</router-link>
                            </li>
                            <li>
                                <router-link to="/domande-frequenti-faq">Domande frequenti F.A.Q.</router-link>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <router-link to="/cookie-policy">Cookie policy</router-link>
                            </li>
                            <li>
                                <router-link to="/informativa-sulla-privacy">Informativa sulla privacy</router-link>
                            </li>
                            <li>
                                <router-link to="/informazioni-legali">Informazioni legali</router-link>
                            </li>
                        </ul>
                    </bxs-col>

                    <bxs-col
                    v-else
                    cols="12">
                        <bxs-collapse-item label="Hai bisogno di aiuto?">
                            <ul class="mb-ui">
                                <li>
                                    <router-link to="/termini-e-condizioni-di-vendita">Termini e condizioni di vendita</router-link>
                                </li>
                                <li>
                                    <router-link to="/spedizioni-e-consegna">Spedizioni e consegna</router-link>
                                </li>
                                <li>
                                    <router-link to="/metodi-di-pagamento">Metodi di pagamento</router-link>
                                </li>
                            </ul>

                            <ul class="mb-ui">
                                <li>
                                    <router-link to="/assistenza-clienti">Assistenza clienti</router-link>
                                </li>
                                <li>
                                    <router-link to="/domande-frequenti-faq">Domande frequenti F.A.Q.</router-link>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    <router-link to="/cookie-policy">Cookie policy</router-link>
                                </li>
                                <li>
                                    <router-link to="/informativa-sulla-privacy">Informativa sulla privacy</router-link>
                                </li>
                                <li>
                                    <router-link to="/informazioni-legali">Informazioni legali</router-link>
                                </li>
                            </ul>
                        </bxs-collapse-item>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <div v-if="!$store.state.is_mobile" class="mb-8">
                            <div>
                                <h6>Tappeto Su Misura</h6>
                            </div>

                            <ul>
                                <li>
                                    <router-link to="/la-nostra-azienda">La nostra azienda</router-link>
                                </li>
                                <li>
                                    <router-link to="/informativa-sulla-privacy">Perchè comprare da noi</router-link>
                                </li>
                            </ul>
                        </div>

                        <div v-else class="mb-8">
                            <bxs-collapse-item label="Tappeto Su Misura">
                                <ul>
                                    <li>
                                        <router-link to="/la-nostra-azienda">La nostra azienda</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/informativa-sulla-privacy">Perchè comprare da noi</router-link>
                                    </li>
                                </ul>
                            </bxs-collapse-item>
                        </div>

                        <div>
                            <div>
                                <h6>Pagamenti sicuri</h6>
                            </div>

                            <payments-icons />
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <div>
                            <h6>Seguici su</h6>
                        </div>

                        <ul class="flex nowrap">
                            <li class="mr-ui">
                                <a href="">
                                    <bxs-icon name="fb" />
                                </a>
                            </li>
                            <li class="mr-ui">
                                <a href="">
                                    <bxs-icon name="ig" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <bxs-icon name="yt" />
                                </a>
                            </li>
                        </ul>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </footer>
</template>

<script>
import SectionNewsletter from '@/components/project/sections/SectionNewsletter.vue'
import SectionInfographics from '@/components/project/sections/SectionInfographics.vue'
import PaymentsIcons from '@/components/project/PaymentsIcons.vue'

export default {
    name: 'bxs-footer',
    components: {
        'section-newsletter': SectionNewsletter,
        'section-infographics': SectionInfographics,
        'payments-icons': PaymentsIcons
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

footer {
    position: relative;
    width: 100%;
    min-height: var(--footer-height);
    background-color: var(--footer-background-color);
    color: var(--footer-color);
    z-index: var(--layer-navbar);
    transition: transform 0.5s ease-out;

    > .bxs-layout {
        height: 100%;

        > .bxs-row {
            height: 100%;

            .bxs-col {
                height: 100%;
            }
        }
    }

    &.bxs-footer-mobile {
    }
}
</style>