<template>
    <div class="tsm-slideshow">
        <bxs-carousel
        :items="items"
        navigation
        :pagination="{
            clickable: true
        }">
            <template #item="{ item }">
                <router-link
                v-if="item.link.on"
                :to="item.link.to"
                class="tsm-slideshow--slide-item">
                    <bxs-figure
                    :src="item.img ? item.img.url : null"
                    :alt="item.img.alt"
                    :ratio="item.aspect_ratio || '21-6'"
                    ratio-mobile="16-9"
                    overlay>
                        <div class="tsm-slideshow--slide-item--inner">
                            <h1
                            v-if="item.title"
                            v-html="item.title"></h1>

                            <h6
                            v-if="item.subtitle"
                            v-html="item.subtitle"></h6>

                            <p
                            v-if="item.content"
                            v-html="item.content"></p>

                            <bxs-btn
                            v-if="item.link.label"
                            :to="item.to"
                            class="mt-ui">{{ item.link.label }}</bxs-btn>
                        </div>
                    </bxs-figure>
                </router-link>

                <bxs-figure
                v-else
                :src="item.img ? item.img.url : null"
                :alt="item.img.alt"
                :ratio="item.aspect_ratio || '21-6'"
                ratio-mobile="16-9"
                overlay
                class="tsm-slideshow--slide-item">
                    <div class="tsm-slideshow--slide-item--inner">
                        <h1
                        v-if="item.title"
                        v-html="item.title"></h1>

                        <h6
                        v-if="item.subtitle"
                        v-html="item.subtitle"></h6>

                        <p
                        v-if="item.content"
                        v-html="item.content"></p>

                        <bxs-btn
                        v-if="item.link.label"
                        :to="item.to"
                        class="mt-ui">{{ item.link.label }}</bxs-btn>
                    </div>
                </bxs-figure>
            </template>
        </bxs-carousel>
    </div>
</template>

<script>
export default {
    name: 'tsm_slideshow',
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        }
    }
}
</script>

<style lang="scss" scoped>
.tsm-slideshow--slide-item {
    display: block;

    &:hover {
        text-decoration: none;
        color: inherit;
    }

    &--inner {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: var(--color-white);
        padding: 0 var(--size-layout);
    }
}
</style>