<template>
    <div class="me-configuration-file-view">
        <div class="mb-ui py-ui">
            <h2>Gestisci i file della tua configurazione</h2>
            <bxs-divider></bxs-divider>
        </div>
    </div>
</template>

<script>
export default {
    name: 'me_configuration_file'
}
</script>