<template>
    <div class="password-recovery-view">
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col v-if="!$store.state.is_mobile" />

                    <bxs-col class="text-center">
                        <bxs-card
                        outlined
                        elevation
                        rounded
                        :width="$store.state.is_mobile ? '100%' : '450px'"
                        class="pa-8">
                            <h2 class="text-center mb-4">Hai dimenticato la password?</h2>

                            <bxs-form class="my-8">
                                <h5 class="text-left ma-4">Nome dell'utente o Email</h5>
                                <bxs-text-field
                                v-model="data.name"
                                name="mail"
                                label="Inserisci il tuo nome utente o la tua Email"
                                class="ma-4" />

                                <p class="px-4 mt-8">
                                    <bxs-btn to="/password/reset" block class="mb-6">Invia</bxs-btn>
                                </p>

                                <p class="my-4">Ti manderemo una mail con il codice per resettare la password.</p><p>Non uscire da questa pagina durante la procedura</p>
                            </bxs-form>

                            <bxs-divider></bxs-divider>

                            <a href="/signin" class="text-underline mt-8"><bxs-icon name="arrow-left" /> Torna indietro</a>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col v-if="!$store.state.is_mobile" />
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'password_recovery',
    data () {
        return {
            data: {
                mail: ''
            }
        }
    }
}
</script>