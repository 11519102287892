<template>
    <div class="ver">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'bxs-ver'
}
</script>

<style lang="scss" scoped>
.ver {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
}
</style>