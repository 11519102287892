<template>
    <router-link
    to="/"
    class="logo bxs-logo"
    :style="{ maxWidth: maxWidth }">
        <img
        :src="src"
        :alt="alt">
    </router-link>
</template>

<script>
export default {
    name: 'logo',
    props: {
        'max-width': {
            type: String,
            required: false,
            default: '90px'
        },
        alt: {
            type: String,
            required: false,
            default: null
        },
        src: {
            type: String,
            required: false,
            default: '/img/logo.svg'
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    vertical-align: middle;

    > img {
        display: block;
        position: relative;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}
</style>