<template>
    <bxs-drawer
    ref="drawer"
    solo
    max-width="576"
    title="Carrello">
        <template #activator="{ on }">
            <slot name="activator" :on="on"></slot>
        </template>

        <!--  -->
        <div class="px-layout py-ui">
            <div
            v-if="!$api.me.checkout.cart.length"
            class="text-center">
                <h6 class="mb-4">Nessun prodotto nel carrello</h6>
                <bxs-btn
                block
                @click="$refs.drawer.close()">Continua gli acquisti</bxs-btn>
            </div>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <div v-else>
                <cart-items />

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    kit campioni
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <!-- <div class="my-4 element-list">
                    <div class="flex nowrap align-center pa-2">
                        <bxs-row>
                            <bxs-col
                            cols="4"
                            style="padding-top: 0; padding-right: 0;">
                                <bxs-figure
                                src="https://ometto.it/wp-content/uploads/2021/03/76-CF012763u_%C2%A9Francesco-Castagna.jpg"
                                radio="4-3" />
                            </bxs-col>

                            <bxs-col
                            cols="5"
                            style="padding-top: 0; padding-right: 0;">
                                <h6>Nome prodotto proposta di configurazione</h6>
                                <p><small>Misure: 120 x 250 cm</small></p>
                                <p><small>Quantità: 1</small></p>
                                <p class="mb-2"><strong>9 €</strong></p>
                                <a><small><bxs-icon name="edit" /> Sulla lista dei desideri</small></a>
                            </bxs-col>

                            <bxs-col
                            v-if="$store.state.is_mobile"
                            cols="3"
                            style="padding-top: 0; padding-right: 0;">
                                <div class="text-center">
                                    <a><bxs-icon name="edit" height="44px" wudth="44px" /><br><small>Modifica</small></a>
                                </div><br>
                                <div class="text-center">
                                    <a><bxs-icon name="trash" height="44px" wudth="44px" /><br><small>Elimina</small></a>
                                </div>
                            </bxs-col>

                            <bxs-col
                            v-else
                            cols="3"
                            style="padding-top: 0; padding-right: 0;">
                                <a><small><bxs-icon name="edit" /> Modifica</small></a><br>
                                <a><small><bxs-icon name="trash" /> Elimina</small></a>
                            </bxs-col>
                        </bxs-row>
                    </div>
                </div> -->

                <!-- <div class="list">
                    <div
                    v-for="el in 4"
                    :key="el"
                    class="element-list my-4">
                        <bxs-row>
                            <bxs-col cols="auto" class="pt-0 pb-4">
                                <bxs-avatar />
                            </bxs-col>

                            <bxs-col class="pt-0 pb-4">
                                <h4>Acqualine</h4>
                                <p>Rosso</p>
                            </bxs-col>

                            <bxs-col cols="auto" class="pt-0 pb-4">
                                <div class="text-center">
                                    <a><bxs-icon name="trash" height="24px" wudth="24px" /><br><small>Elimina</small></a>
                                </div>
                            </bxs-col>
                        </bxs-row>
                    </div>

                    <div class="element-list">
                        <bxs-row>
                            <bxs-col cols="auto" class="pt-0 pb-4">
                                <bxs-avatar />
                            </bxs-col>

                            <bxs-col class="pt-0 pb-4">
                                <h4>Acqualine</h4>
                                <p>Rosso</p>
                            </bxs-col>

                            <bxs-col cols="auto" class="pt-0 pb-4">
                                <div class="text-center">
                                    <a><bxs-icon name="trash" height="24px" wudth="24px" /><br><small>Elimina</small></a>
                                </div>
                            </bxs-col>
                        </bxs-row>
                    </div>
                </div>

                <bxs-row>
                    <bxs-col>Kit campioni</bxs-col>
                    <bxs-col cols="auto">9,90 €</bxs-col>
                </bxs-row> -->
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            footer
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <template #actions>
            <div
            v-if="$api.me.checkout.cart.length > 0"
            class="px-layout py-ui">
                <div class="flex nowrap justify-between mb-2">
                    <p class="mb-0">Spedizione</p>
                    <p :class="{ 'text-success': !$api.me.checkout.cashier.shipping_gross }">{{ !$api.me.checkout.cashier.shipping_gross ? 'Gratuita' : $filters.toHumanPrice($api.me.checkout.cashier.shipping_gross) }}</p>
                </div>

                <div class="flex nowrap justify-between mb-2">
                    <p><b>Totale (iva inclusa)</b></p>
                    <p><b>{{ $filters.toHumanPrice($api.me.checkout.cashier.sell) }}</b></p>
                </div>

                <div>
                    <small>Il tuo ordine sarà pronto per la spedizione entro il {{ $filters.toHumanDate($api.me.checkout.shipping.date, 'll') }}</small>
                </div>

                <div class="mt-ui">
                    <bxs-btn to="/carrello" block>Vai al carrello</bxs-btn>
                </div>
            </div>
        </template>
    </bxs-drawer>
</template>

<script>
export default {
    name: 'checkout_rapid',
    methods: {
        removeFromCart (id) {
            try {
                this.$api.me.checkout.removeFromCart(id)
            } catch (err) {
                alert(err.message || err.name)
            }
        },
        addToWish (id) {
            this.loading = true

            try {
                this.$api.me.wish.addProduct(id)
            } catch (err) {
                alert(err.message || err.name)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>

p,
a,
small {
    margin-bottom: 0;
}
</style>