<template>
    <section class="section-infographics">
        <bxs-layout>
            <bxs-row
            no-gutter
            align="center">
                <bxs-col
                cols="12"
                ds="3"
                class="text-center">
                    <img src="/img/shipping-icon.svg" alt="" class="bxs-d-inline-block mb-ui" style="max-width: 40px;">
                    <p class="text-500 text-uppercase">Spedizioni</p>
                    <p>Spedizioni gratuite in Italia per acquisti oltre i 120€, oppure 9,90€ spese fisse.</p>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="3"
                class="text-center">
                    <img src="/img/payment-icon.svg" alt="" class="bxs-d-inline-block mb-ui" style="max-width: 40px;">
                    <p class="text-500 text-uppercase">Pagamenti</p>
                    <p>Transazioni sicure attraverso i più conosciuti e diffusi sistemi di pagamento.</p>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="3"
                class="text-center">
                    <img src="/img/maintenance-icon.svg" alt="" class="bxs-d-inline-block mb-ui" style="max-width: 40px;">
                    <p class="text-500 text-uppercase">Manutenzione</p>
                    <p>Ti seguiamo anche dopo la vendita: ripara da noi il tuo tappeto su misura usato!</p>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="3"
                class="text-center">
                    <img src="/img/quality-icon.svg" alt="" class="bxs-d-inline-block mb-ui" style="max-width: 40px;">
                    <p class="text-500 text-uppercase">Qualità</p>
                    <p>Utilizziamo solo materie prime certificate provenienti dall'Unione Europea.</p>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_infographics'
}
</script>