<template>
    <div class="password-reset-view">
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col v-if="!$store.state.is_mobile" />

                    <bxs-col class="text-center">
                        <bxs-card
                        outlined
                        elevation
                        rounded
                        :width="$store.state.is_mobile ? '100%' : '450px'"
                        class="pa-8">
                            <h2 class="text-center mb-4">Reimposta Password</h2>

                            <bxs-form class="mt-8 mb-4">
                                <h5 class="text-left ma-4">Codice di verifica</h5>
                                <p class="text-left mx-4"><small>Inserisci il codice di verifica arrivato all'indirizzo example@email.com</small></p>
                                <bxs-text-field
                                v-model="data.code"
                                name="code"
                                label="Inserisci il codice di verifica"
                                class="ma-4" />

                                <h5 class="text-left ma-4">Password</h5>
                                <bxs-text-field
                                v-model="data.pass"
                                name="password"
                                label="Inserisci la password"
                                type="password"
                                hide-details
                                class="ma-4" />

                                <bxs-text-field
                                v-model="data.confPass"
                                name="confPass"
                                label="Conferma la Password"
                                type="password"
                                hide-details
                                class="ma-4" />

                                <p class="px-4 mt-8">
                                    <bxs-btn to="/password/confirm" block>Cambia password</bxs-btn>
                                </p>
                            </bxs-form>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col v-if="!$store.state.is_mobile" />
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'password_reset',
    data () {
        return {
            data: {
                code: '',
                pass: '',
                confPass: ''
            }
        }
    }
}
</script>