<template>
    <div class="configurator-dimensions">
        <!-- ------------------------------------------------------------------------------------------------------------------------------------------
            mobile
        ------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-menu
        v-if="$store.state.is_mobile"
        v-model="list.on"
        :title="title">
            <template #activator="{ on }">
                <div
                v-on="on"
                class="configurator-dimensions--header mb-2 bxs-pointer">
                    <h6 class="text-600">{{ title }}</h6>

                    <div class="configurator-dimensions--header--activator mb-2">
                        <span v-if="!local_value.b && !local_value.a">Inserisci le misure</span>
                        <span v-else>{{ local_value.b }} x {{ local_value.a }} cm</span>
                    </div>

                    <div class="text-center">
                        <small class="text-500">* Il taglio su misura potrà avere una tolleranza del ±2%</small>
                    </div>
                </div>
            </template>

            <!-- ---------------------------------------------------------------------
            --------------------------------------------------------------------- -->
            <div class="px-layout">
                <!-- ---------------------------------------------------------------------
                    header
                --------------------------------------------------------------------- -->
                <div class="mb-ui">
                    <bxs-figure
                    v-if="shape"
                    :src="shape.img ? shape.img.url : null"
                    max-width="160px"
                    class="mx-auto mb-ui" />

                    <small>Le misure del tappeto si intendono comprensive di eventuale bordo.</small>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------
                    tappeto
                ------------------------------------------------------------------------------------------------------------------------------------------ -->
                <div v-if="type === 'tappeto'">
                    <bxs-qty-field
                    v-model.number="local_value.b"
                    name="dimensions.b"
                    :min="limits.min"
                    :max="limits.max"
                    label="Lato B (base) in cm"
                    placeholder="Lato B (base) in cm"
                    suffix="cm"
                    hide-actions />

                    <div
                    v-if="shape && shape.side && shape.side !== 'quad'"
                    class="mt-1">
                        <small
                        v-if="shape && shape.ratio && shape.ratio > 1"
                        class="mb-2">- misura calcolata in proporzione</small>

                        <bxs-qty-field
                        v-model.number="local_value.a"
                        name="dimensions.a"
                        :disabled="shape && shape.ratio && shape.ratio > 1"
                        :min="limits.min"
                        :max="limits.max"
                        label="Lato A (altezza) in cm"
                        placeholder="Lato A (altezza) in cm"
                        suffix="cm"
                        hide-actions />
                    </div>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------
                    passatoia / zerbino
                ------------------------------------------------------------------------------------------------------------------------------------------ -->
                <div v-else-if="type === 'passatoia' || type === 'zerbino'">
                    <div class="mb-2">
                        <bxs-qty-field
                        v-model.number="local_value.b"
                        name="dimensions.b"
                        :currency="null"
                        :disabled="shape && shape.ratio && shape.ratio > 1"
                        :min="limits.min"
                        :max="limits.max"
                        label="Lato B (base) in cm"
                        placeholder="Lato B (base) in cm"
                        suffix="cm"
                        hide-actions />
                        <small v-if="$api.env === 'development'">min: {{ limits.min }}cm - max: {{ limits.maxSizing }}cm</small>
                    </div>

                    <div>
                        <div class="flex wrap mb-1">
                            <div
                            v-for="size in sizes"
                            :key="size.id"
                            class="flex mr-ui">
                                <!-- <bxs-card>
                                    {{ size.dimensions.width }} cm
                                </bxs-card> -->

                                <input
                                v-model="selected_width"
                                type="radio"
                                :id="'sizing-' + size.id"
                                name="sizing"
                                :value="size.dimensions.width" />

                                <label
                                :for="'sizing-' + size.id"
                                class="ml-1">{{ size.dimensions.width }} cm</label>
                            </div>

                            <div
                            v-if="hasVariableWidth"
                            class="flex">
                                <!-- <bxs-card>Larghezza variabile</bxs-card> -->

                                <input
                                v-model="selected_width"
                                type="radio"
                                id="sizing-variable"
                                name="sizing"
                                value="is_variable_width" />

                                <label
                                for="sizing-variable"
                                class="ml-1">Larghezza variabile</label>
                            </div>
                        </div>

                        <!-- v-if="shape && shape.side && shape.side !== 'quad'" -->
                        <bxs-qty-field
                        v-model.number="local_value.a"
                        name="dimensions.a"
                        :currency="null"
                        :disabled="selected_width !== 'is_variable_width'"
                        :min="limits.min"
                        :max="limits.maxSizing"
                        label="Lato A (altezza) in cm"
                        placeholder="Lato A (altezza) in cm"
                        suffix="cm"
                        hide-actions />
                        <small v-if="$api.env === 'development'">min: {{ limits.min }}cm - max: {{ limits.maxSizing }}cm</small>
                    </div>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------
                    forma elle
                ------------------------------------------------------------------------------------------------------------------------------------------ -->
                <div
                v-if="shape && shape.side && shape.side === 'elle'"
                class="mt-1">
                    <bxs-qty-field
                    v-model.number="local_value.b1"
                    name="dimensions.b1"
                    :min="1"
                    :max="local_value.a"
                    label="Lato B (base) in cm"
                    placeholder="Lato B (base)"
                    suffix="cm"
                    hide-actions
                    class="mb-1" />
                    <small v-if="$api.env === 'development'">min: 1cm - max: {{ local_value.a }}cm</small>

                    <bxs-qty-field
                    v-model.number="local_value.a1"
                    name="dimensions.a1"
                    label="Lato A (altezza) in cm"
                    placeholder="Lato A (altezza) in cm"
                    :min="1"
                    :max="local_value.b"
                    suffix="cm"
                    hide-actions />
                    <small v-if="$api.env === 'development'">min: 1cm - max: {{ local_value.b }}cm</small>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------
                    footer
                ------------------------------------------------------------------------------------------------------------------------------------------ -->
                <div class="mt-ui">
                    <p
                    v-if="shape"
                    class="text-500">
                        <span v-if="shape.side === 'quad'">ATTENZIONE: la misura dei lati deve essere inferiore a {{ limits.maxSizing }} cm</span>
                        <span v-else>ATTENZIONE: uno dei due lati deve essere inferiore a {{ limits.maxSizing }} cm</span>
                    </p>

                    <!-- passatoia -->
                    <!-- <div class="mb-4">
                        <p>Larghezza min-max (Lato A): {{ limits.min }}cm - {{ limits.maxSizing }}cm</p>
                        <p>Lunghezza min-max (Lato B): {{ limits.min }}cm - {{ limits.max }}cm</p>
                    </div> -->
                </div>
            </div>

            <template #actions>
                <div class="flex nowrap align-center pa-layout">
                    <bxs-btn
                    block
                    outlined
                    class="mr-1"
                    @click="close">Annulla</bxs-btn>

                    <bxs-btn
                    block
                    class="ml-1"
                    @click="save">Salva</bxs-btn>
                </div>
            </template>
        </bxs-menu>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------
            desktop
        ------------------------------------------------------------------------------------------------------------------------------------------ -->
        <div v-else>
            <!-- ---------------------------------------------------------------------
                header
            --------------------------------------------------------------------- -->
            <div class="mb-ui">
                <h6 class="text-600 mb-1">{{ title }}</h6>

                <bxs-figure
                v-if="shape"
                :src="shape.img ? shape.img.url : null"
                max-width="160px"
                class="mx-auto mb-ui" />

                <div>
                    <bxs-alert
                    v-if="(shape && shape.id === 'shp_3f67dbf4d88c40b0be99e43b9f7c7d1c') || (inlay && inlay.input_file)"
                    type="warn"
                    class="mb-2">Potrai caricare il file successivamente nella tua area personale</bxs-alert>

                    <bxs-alert class="mb-2">
                        <span v-if="shape && shape.side === 'quad'">ATTENZIONE: la misura dei lati deve essere inferiore a {{ limits.maxSizing }} cm</span>
                        <span v-else>Uno dei due lati deve essere inferiore a {{ limits.maxSizing }} cm</span>
                    </bxs-alert>

                    <bxs-alert
                    v-if="$api.is_dev_env"
                    class="mb-2">limits.min {{ limits.min }}cm - limits.max (lunghezza max) {{ limits.max }}cm - limits.maxSizing {{ limits.maxSizing }}cm</bxs-alert>

                    <bxs-alert class="mb-2">
                        <span>Le misure del tappeto si intendono comprensive di eventuale bordo.</span> <br>
                        <span>* Il taglio su misura potrà avere una tolleranza del ±2%.</span>
                    </bxs-alert>
                </div>
            </div>

            <!-- ---------------------------------------------------------------------
                body
            --------------------------------------------------------------------- -->
            <div class="mb-ui">
                <!-- ---------------------------------------------------------------------
                    tappeto
                --------------------------------------------------------------------- -->
                <div v-if="type === 'tappeto'">
                    <div
                    v-if="shape && shape.side && shape.side !== 'quad'"
                    class="mb-1">
                        <bxs-qty-field
                        v-model.number="local_value.a"
                        name="dimensions.a"
                        :disabled="shape && shape.ratio && shape.ratio > 1"
                        :min="limits.min"
                        :max="limits.max"
                        label="Lato A (altezza) in cm"
                        placeholder="Lato A (altezza) in cm"
                        suffix="cm"
                        hide-actions />
                        <small v-if="$api.env === 'development'">min: {{ limits.min }}cm - max: {{ limits.max }}cm</small>

                        <small
                        v-if="shape && shape.ratio && shape.ratio > 1"
                        class="mt-0 text-disabled">Misura calcolata in proporzione</small>
                    </div>

                    <div>
                        <bxs-qty-field
                        v-model.number="local_value.b"
                        name="dimensions.b"
                        :min="limits.min"
                        :max="limits.max"
                        label="Lato B (base) in cm"
                        placeholder="Lato B (base) in cm"
                        suffix="cm"
                        hide-actions />
                        <small v-if="$api.env === 'development'">min: {{ limits.min }}cm - max: {{ limits.max }}cm</small>
                    </div>
                </div>

                <!-- ---------------------------------------------------------------------
                    passatoia / zerbino
                --------------------------------------------------------------------- -->
                <div v-else-if="type === 'passatoia' || type === 'zerbino'">
                    <div class="mb-4">
                        <!-- sizes -->
                        <ul class="flex wrap">
                            <li class="mr-2">
                                <bxs-chip color="mute">Lato A (altezza)</bxs-chip>
                            </li>

                            <li
                            v-for="size in sizes"
                            :key="size.id"
                            class="mr-2">
                                <bxs-chip
                                link
                                @click="selected_width = size.dimensions.width">
                                    <bxs-icon
                                    :name="selected_width === size.dimensions.width ? 'check-cirlce-fill' : 'circle'"
                                    class="mr-3" />
                                    <span>{{ size.dimensions.width }} cm</span>
                                </bxs-chip>
                            </li>

                            <li v-if="hasVariableWidth">
                                <bxs-chip
                                link
                                @click="selected_width = 'is_variable_width'">
                                    <bxs-icon
                                    :name="selected_width === 'is_variable_width' ? 'check-cirlce-fill' : 'circle'"
                                    class="mr-3" />
                                    <span>Larghezza variabile</span>
                                </bxs-chip>
                            </li>
                        </ul>
                    </div>

                    <!-- fields -->
                    <div>
                        <!-- v-if="shape && shape.side && shape.side !== 'quad'" -->
                        <bxs-qty-field
                        v-model.number="local_value.a"
                        name="dimensions.a"
                        :disabled="selected_width !== 'is_variable_width'"
                        :min="limits.min"
                        :max="limits.maxSizing"
                        label="Lato A (altezza) in cm"
                        placeholder="Lato A (altezza) in cm"
                        suffix="cm"
                        hide-actions
                        class="mb-2" />
                        <small v-if="$api.env === 'development'">min: {{ limits.min }}cm - max: {{ limits.maxSizing }}cm</small>

                        <bxs-qty-field
                        v-model.number="local_value.b"
                        name="dimensions.b"
                        :disabled="shape && shape.ratio && shape.ratio > 1"
                        :min="limits.min"
                        :max="limits.max"
                        label="Lato B (Base) in cm"
                        placeholder="Lato B (Base) in cm"
                        suffix="cm"
                        hide-actions />
                        <small v-if="$api.env === 'development'">min: {{ limits.min }}cm - max: {{ limits.max }}cm</small>
                    </div>
                </div>

                <!-- ---------------------------------------------------------------------
                    forma elle
                --------------------------------------------------------------------- -->
                <div
                v-if="shape && shape.side && shape.side === 'elle'"
                class="mt-2">
                    <bxs-qty-field
                    v-model.number="local_value.a1"
                    name="dimensions.a1"
                    label="Lato A1 (altezza) in cm"
                    placeholder="Lato A1 (altezza) in cm"
                    :min="1"
                    :max="local_value.b"
                    suffix="cm"
                    hide-actions
                    class="mb-2" />
                    <small v-if="$api.env === 'development'">min: 1cm - max: {{ local_value.b }}cm</small>

                    <bxs-qty-field
                    v-model.number="local_value.b1"
                    name="dimensions.b1"
                    :min="1"
                    :max="local_value.a"
                    label="Lato B1 (base) in cm"
                    placeholder="Lato B1 (base) in cm"
                    suffix="cm"
                    hide-actions />
                    <small v-if="$api.env === 'development'">min: 1cm - max: {{ local_value.a }}cm</small>
                </div>

                <!--  -->
                <div
                v-if="inlay && inlay.orientation"
                class="mt-ui">
                    <div class="mb-1">
                        <p>Orientamento della grafica:</p>
                    </div>

                    <div>
                        <bxs-chip
                        @click="local_inlay_orientation = 'vertical'"
                        class="mr-1">
                            <bxs-icon
                            :name="local_inlay_orientation === 'vertical' ? 'check-cirlce-fill' : 'circle'"
                            class="mr-3" />
                            <span>Verticale</span>
                        </bxs-chip>

                        <bxs-chip @click="local_inlay_orientation = 'horizontal'">
                            <bxs-icon
                            :name="local_inlay_orientation === 'horizontal' ? 'check-cirlce-fill' : 'circle'"
                            class="mr-3" />
                            <span>Orizzontale</span>
                        </bxs-chip>
                    </div>
                </div>
            </div>

            <!-- ---------------------------------------------------------------------
                footer
            --------------------------------------------------------------------- -->
            <div>
                <div class="flex nowrap align-center">
                    <bxs-btn
                    block
                    @click="save">Prosegui</bxs-btn>
                </div>

                <!-- passatoia -->
                <!-- <div class="mb-4">
                    <p>Larghezza min-max (Lato A): {{ limits.min }}cm - {{ limits.maxSizing }}cm</p>
                    <p>Lunghezza min-max (Lato B): {{ limits.min }}cm - {{ limits.max }}cm</p>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'configurator_dimensions',
    props: {
        title: {
            type: String,
            required: true,
            default: null
        },
        modelValue: {
            type: Object,
            required: false,
            default: () => {
                return {
                    a: 0,
                    b: 0,
                    a1: 0,
                    b1: 0,
                    variable_b: false
                }
            }
        },
        inlayOrientation: {
            type: String,
            required: false,
            default: null
        },
        //
        limits: {
            type: Object,
            required: false,
            default: () => ({ min: 0, max: 0, maxSizing: 0 })
        },
        'has-variable-width': {
            type: Boolean,
            required: false,
            default: false
        },
        sizes: {
            type: Array,
            required: false,
            default: () => []
        },
        size: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        //
        shape: {
            type: Object,
            required: false,
            default: null
        },
        'shape-ratio': {
            type: [String, Number],
            required: false,
            default: 1
        },
        'shape-side': {
            type: String,
            required: false,
            default: null
        },
        inlay: {
            type: Object,
            required: false,
            default: null
        }
    },
    emits: [
        'update:modelValue',
        'update:model-value',
        'update:inlayOrientation',
        'update:inlay-orientation',
        'save'
    ],
    data () {
        return {
            local_inlay_orientation: this.inlayOrientation,
            selected_width: this.modelValue.variable_b ? 'is_variable_width' : this.modelValue.a,
            local_value: this.modelValue,
            list: {
                on: false
            }
        }
    },
    watch: {
        shapeRatio (newVal, oldVal) {
            if (oldVal && newVal !== oldVal) this.reset()
        },
        shapeSide (newVal, oldVal) {
            if (oldVal && newVal !== oldVal) this.reset()
        },
        local_inlay_orientation (val) {
            this.$emit('update:inlay-orientation', val)
        },
        //
        size (newVal) {
            console.log('configurator dimensions change size', newVal)
            this.selected_width = this.hasVariableWidth ? 'is_variable_width' : newVal
        },
        hasVariableWidth (newVal) {
            console.log('configurator dimensions hasVariableWidth', newVal)
            this.selected_width = newVal ? 'is_variable_width' : this.local_value.a
        },
        // passatoia selected dimensions.a
        selected_width (newVal) {
            console.log('configurator dimensions change selected_width', newVal)

            if (this.shape && this.shape.side && this.shape.side === 'quad') {
                this.local_value.b = newVal
            } else {
                this.local_value.a = newVal === 'is_variable_width' ? null : newVal
            }

            this.local_value.variable_b = newVal === 'is_variable_width'
        },
        'local_value.b' (newVal) {
            console.log('configurator dimensions change local_value.b', this.shape, newVal)

            if (this.shape) {
                if (this.shape.ratio && this.shape.ratio > 1) {
                    this.local_value.a = Math.round(newVal / this.shape.ratio) || null
                }

                if (this.shape.side && this.shape.side === 'quad') {
                    this.local_value.a = this.local_value.b
                }
            }
        }
        // local_value: {
        //     deep: true,
        //     handler (newVal) {
        //         this.$emit('update:modelValue', newVal)
        //     }
        // }
    },
    methods: {
        reset () {
            this.selected_width = null
            this.local_value = {
                a: 0,
                b: 0,
                a1: 0,
                b1: 0,
                variable_b: false
            }

            console.log('configurator dimensions reset()', this.local_value, this.selected_width)

            this.$emit('update:modelValue', this.local_value)
        },
        open () {
            this.list.on = true
        },
        close () {
            this.list.on = false
        },
        save () {
            console.log('configurator dimensions save()')

            if (this.local_value.a > this.limits.maxSizing && this.local_value.b > this.limits.maxSizing) {
                return alert('Attenzione, misure errate!')
            }

            this.$emit('update:modelValue', this.local_value)
            this.$emit('save')
            this.close()
        }
    }
}
</script>

<style lang="scss" scoped>
.configurator-dimensions {
    &--header {
        &--activator {
            min-height: 44px;
            text-align: center;
            border: 1px solid #75787B;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            border-radius: var(--radius);

            > * {
                margin: 0;
                padding: 0;
            }
        }
    }
}
</style>