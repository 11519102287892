import Resource from './BaseResource'

class ProductConfiguration extends Resource {
    constructor (config, tools, version, path) {
        super(config, tools, version, path)
    }

    getDocs () {
        return this.tools.http.get(`${this.api}`)
    }

    getDoc (id) {
        return this.tools.http.get(`${this.api}/${id}`)
    }

    remove (id) {
        return this.tools.http.delete(`${this.api}/${id}`)
    }
}

class Checkout extends Resource {
    constructor (config, tools, version, path) {
        super(config, tools, version, path)

        this.item = null
        this.intent = null
        this.shippings_methods = []
        this.payments_methods = []
    }

    get id () {
        if (!this.item) return null
        return this.item.id
    }

    get cart () {
        if (!this.item) return []
        return this.item.cart
    }

    get cart_count () {
        if (!this.item) return 0
        return this.item.cart.length
    }

    get addresses () {
        if (!this.item) return null
        return this.item.addresses
    }

    get shipping () {
        if (!this.item) return null
        return this.item.shipping
    }

    get payment () {
        if (!this.item) return null
        return this.item.payment
    }

    get cashier () {
        if (!this.item) {
            return {
                gross: 0,
                net: 0,
                sell: 0,
                shipping_gross: 0,
                packaging_gross: 0
            }
        }

        return this.item.cashier
    }

    async getDoc () {
        const doc = await this.tools.http.get(`${this.api}`)
        this.item = doc
        return doc
    }

    async getStatus () {
        const doc = await this.tools.http.get(`${this.api}/status`)
        this.item = doc
        return doc
    }

    async getCarts () {
        const doc = await this.tools.http.get(`${this.api}/carts`)
        this.item = doc
        return doc
    }

    async addToCart (
        product_id,
        quantity = 1
    ) {
        const doc = await this.tools.http.post(`${this.api}/carts/items`, {
            product_id,
            quantity
        })

        this.item = doc

        return doc
    }

    async removeFromCart (id) {
        const doc = await this.tools.http.delete(`${this.api}/carts/items/${id}`)
        this.item = doc
        return doc
    }

    async clearCart () {
        const doc = await this.tools.http.delete(`${this.api}/carts`)
        this.item = doc
        return doc
    }

    async addPromo (code) {
        const doc = await this.tools.http.patch(`${this.api}/promos`, { code })
        this.item = doc
        return doc
    }

    async removePromo () {
        const doc = await this.tools.http.delete(`${this.api}/promos`)
        this.item = doc
        return doc
    }

    async addAddress (id) {
        const doc = await this.tools.http.patch(`${this.api}/addresses/${id}`)
        this.item = doc
        return doc
    }

    async getShippingsMethods () {
        const doc = await this.tools.http.get(`${this.api}/shippings_methods`)
        this.shippings_methods = doc
        return doc
    }

    async addShippingMethod (id) {
        const doc = await this.tools.http.patch(`${this.api}/shippings_methods/${id}`)
        this.item = doc
        return doc
    }

    async getPaymentsMethods () {
        const doc = await this.tools.http.get(`${this.api}/payments_methods`)
        this.payments_methods = doc
        return doc
    }

    async addPaymentMethod (id, pm_id) {
        const doc = await this.tools.http.patch(`${this.api}/payments_methods/${id}`, {
            payment_method_id: pm_id
        })
        this.item = doc
        return doc
    }

    async createIntent (id) {
        const doc = await this.tools.http.post(`${this.api}/${id || this.doc.id}/intents`)
        this.intent = doc
        return doc
    }
}

class Payment extends Resource {
    constructor (config, tools, version, path) {
        super(config, tools, version, path)
    }

    list (type = 'card') {
        return this.tools.http.get(`${this.api}`, { type })
    }

    createCard (body) {
        return this.tools.http.post(`${this.api}`, {
            type: 'card',
            ...body
        })
    }

    createDirectIban (iban) {
        return this.tools.http.post(`${this.api}`, {
            type: 'sepa_debit',
            iban
        })
    }

    remove (id) {
        return this.tools.http.delete(`${this.api}/${id}`)
    }
}

class Order extends Resource {
    constructor (config, tools, version, path) {
        super(config, tools, version, path)
    }

    confirmIntent (id, payment_intent_id) {
        return this.tools.http.patch(`${this.api}/${id}/intents/${payment_intent_id}`)
    }

    getOrderRow (order_id, row_id) {
        return this.tools.http.get(`${this.api}/${order_id}/rows/${row_id}`)
    }

    uploadRowFile (order_id, row_id, file) {
        return this.tools.http.post(`${this.api}/${order_id}/rows/${row_id}/files`, {
            file
        })
    }

    removeRowFile (order_id, row_id, file_id) {
        return this.tools.http.delete(`${this.api}/${order_id}/rows/${row_id}/files/${file_id}`)
    }
}

class Wish extends Resource {
    constructor (config, tools, version, path) {
        super(config, tools, version, path)
        this.items = []
    }

    async list () {
        const docs = await this.tools.http.get(`${this.api}`)
        this.items = docs
        return docs
    }

    async addProduct (id) {
        const docs = await this.tools.http.post(`${this.api}/items`, { product_id: id })
        this.items = docs
        return docs
    }

    async removeProduct (id) {
        const docs = await this.tools.http.delete(`${this.api}/items/${id}`)
        this.items = docs
        return docs
    }
}

class Me extends Resource {
    constructor (config, tools, version, path) {
        super(config, tools, version, path)

        this.checkout = new Checkout(config, tools, version, path + '/checkouts')
        this.wish = new Wish(config, tools, version, path + '/wishes')
        this.payments_methods = new Payment(config, tools, version, path + '/payments_methods')
        this.orders = new Order(config, tools, version, path + '/orders')
        this.products_configurations = new ProductConfiguration(config, tools, version, path + '/products_configurations')

        this.item = null
    }

    async doc(id, query, cb) {
        const data = await this.tools.http.get(this.api, query)
        this.tools.emitter.emit('doc', data)
        if (cb && typeof cb === 'function') cb(data)
        return data
    }

    async getAddresses () {
        const docs = await this.tools.http.get(`${this.api}/addresses`)
        return docs
    }

    async createAddress (body) {
        return this.tools.http.post(`${this.api}/addresses`, body)
    }

    async deleteAddress (id) {
        return this.tools.http.delete(`${this.api}/addresses/${id}`)
    }

    async selectAddress (id, key = 'shipping', val = true) {
        return this.tools.http.patch(`${this.api}/addresses/${id}/selections`, {
            key: key,
            value: val
        })
    }
}

export default Me