import Resource from './BaseResource'

class Catalog extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)
    }

    getProducts (deps_slugs, q) {
        return this.tools.http.get(`${this.api}/${deps_slugs}`, q)
    }

    getProduct (slug, q) {
        return this.tools.http.get(`${this.api}/products/${slug}`, q)
    }

    getProductsConfigurationsProposals (id, q) {
        return this.tools.http.get(`${this.api}/products/${id}/configurations_proposals`, q)
    }

    getRelatedsProduct (slug) {
        return this.tools.http.get(`${this.api}/products/${slug}/relateds`)
    }
}

export default Catalog