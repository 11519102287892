<template>
    <div class="signup-view">
        <section>
            <bxs-layout max-width="768">
                <div class="mb-ui">
                    <div class="mb-ui">
                        <h1 class="text-center mb-ui">Sei un nuovo cliente?</h1>
                        <bxs-divider></bxs-divider>
                    </div>

                    <bxs-form
                    ref="form"
                    @submit="signup">
                        <div class="mb-ui">
                            <h6 class="mb-1">Nome</h6>
                            <bxs-text-field
                            v-model="model.name.first"
                            required
                            name="name.first"
                            label="Inserisci il tuo nome" />
                        </div>

                        <div class="mb-ui">
                            <h6 class="mb-1">Cognome</h6>
                            <bxs-text-field
                            v-model="model.name.last"
                            required
                            name="name.last"
                            label="Inserisci il tuo cognome" />
                        </div>

                        <div class="mb-ui">
                            <h6 class="mb-1">Email</h6>
                            <bxs-text-field
                            v-model="model.email"
                            required
                            name="email"
                            label="Inserisci l’indirizzo email" />
                        </div>

                        <div class="mb-ui">
                            <h6 class="mb-1">Password</h6>
                            <bxs-text-field
                            v-model="model.password"
                            required
                            name="password"
                            label="Inserisci la password"
                            type="password" />
                        </div>

                        <div>
                            <h6 class="mb-1">Conferma password</h6>
                            <bxs-text-field
                            v-model="model.password_confirm"
                            required
                            name="password_confirm"
                            label="Conferma la Password"
                            type="password"
                            :rules="[(v) => v === model.password || 'Password errata']" />
                        </div>

                        <div class="mt-ui">
                            <bxs-btn
                            block
                            @click="$refs.form.submit()">Registrati</bxs-btn>

                            <router-link
                            to="/"
                            class="text-underline">Hai problemi a registrarti?</router-link>
                        </div>
                    </bxs-form>
                </div>

                <div class="mt-ui">
                    <div>
                        <h3 class="mb-ui">Sei già registrato?</h3>
                        <bxs-divider></bxs-divider>
                    </div>

                    <div>
                        <bxs-btn to="/signin" outlined block>Accedi</bxs-btn>
                    </div>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'signup',
    data () {
        return {
            model: {
                name: {
                    first: null,
                    last: null
                },
                email: null,
                password: null,
                password_confirm: null
            }
        }
    },
    methods: {
        async signup () {
            try {
                await this.$api.auth.signup(this.model)
                this.$api.me.checkout.getDoc()
                alert('Registrazione avvenuta con successo!')
                this.$router.push(this.$store.state.page_leave || '/me').catch(() => {})
            } catch (err) {
                alert(err.message || err.name)
            }
        }
    }
}
</script>