<template>
    <div class="tsm-sign">
        <div>
            <h2 class="text-center mb-4">Sei già registrato?</h2>

            <bxs-divider></bxs-divider>

            <bxs-form class="my-8">
                <h5 class="text-left ma-4">Indirizzo Email</h5>
                <bxs-text-field
                v-model="data.mail"
                name="mail"
                label="Inserisci l’indirizzo email"
                class="ma-4" />

                <h5 class="text-left ma-4">Password</h5>
                <bxs-text-field
                v-model="data.pass"
                name="password"
                label="Inserisci la password"
                type="password"
                hide-details
                class="ma-4" />

                <p class="px-4 mt-8">
                    <bxs-btn to="/me" block class="mb-6">Accedi</bxs-btn>
                    <router-link to="/password/recovery" class="text-underline">Hai dimenticato la password?</router-link>
                </p>
            </bxs-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tsm_sign'
}
</script>