<template>
    <div class="configurator-select">
        <div
        :class="['configurator-select--header', 'flex-inline', 'nowrap', 'align-center', 'mb-2', {
            'bxs-pointer': items.length > 0
        }]"
        @click="open">
            <h6 class="mb-0 text-600">{{ title }}</h6>

            <bxs-icon
            name="list-bullet"
            width="1em"
            height="1em"
            class="ml-3"
            style="margin-top: -2px;" />
        </div>

        <bxs-carousel
        v-if="$store.state.is_mobile"
        :items="items"
        item-width="160px"
        :items-space-between="8">
            <template #item="{ item }">
                <bxs-card
                :actived="selected === item.id"
                hoverable
                ratio="21-9">
                    <div
                    class="flex nowrap align-center px-2"
                    style="height: 100%; line-height: 100%;"
                    @click="select(item.id)">
                        <div>
                            <bxs-avatar
                            v-if="imageIsAvatar"
                            :src="item.img_picker ? item.img_picker.url : item.img ? item.img.url : null" />

                            <bxs-figure
                            v-else
                            :src="item.img_picker ? item.img_picker.url : item.img ? item.img.url : null"
                            max-width="44" />
                        </div>

                        <div class="flex-1 pl-2">
                            <small>{{ item[itemText] }}</small>
                        </div>
                    </div>

                    <!-- <div style="position: absolute; top: 0; right: 0; widht: 40px; heigth: 40px; z-index: 1;">
                        <bxs-icon
                        name="close"
                        @click="deselect" />
                    </div> -->
                </bxs-card>
            </template>
        </bxs-carousel>

        <!-- style="height: calc(100vh - 100px); padding-bottom: 200px;" -->
        <div
        v-else
        class="bxs-scrollable">
            <ul class="flex wrap">
                <li
                v-for="(item, i) in items"
                :key="i"
                class="mr-2 mb-2">
                    <bxs-card
                    :actived="selected === item.id"
                    hoverable
                    ratio="21-9"
                    width="160"
                    @click="select(item.id)">
                        <div
                        class="flex nowrap align-center px-2"
                        style="height: 100%; line-height: 100%;">
                            <div>
                                <bxs-avatar
                                v-if="imageIsAvatar"
                                :src="item.img_picker ? item.img_picker.url : item.img ? item.img.url : null" />

                                <bxs-figure
                                v-else
                                :src="item.img_picker ? item.img_picker.url : item.img ? item.img.url : null"
                                max-width="44" />
                            </div>

                            <div class="flex-1 pl-2">
                                <small>{{ item[itemText] }}</small>
                            </div>
                        </div>
                    </bxs-card>
                </li>
            </ul>
        </div>

        <bxs-menu
        v-if="items.length > 0"
        v-model="list.on"
        :title="title"
        solo>
            <div class="pa-layout">
                <ul>
                    <li
                    v-for="(_item, i) in items"
                    :key="i">
                        <slot
                        v-if="$slots['item.menu']"
                        name="item.menu"
                        :item="_item" />

                        <bxs-collapse-item
                        v-else
                        :label="_item.name">
                            <bxs-figure :src="_item.img_picker ? _item.img_picker.url : null" />

                            <p v-if="_item.description">{{ _item.description }}</p>
                        </bxs-collapse-item>
                    </li>
                </ul>
            </div>
        </bxs-menu>
    </div>
</template>

<script>
import { groupBy } from 'lodash'

export default {
    name: 'configurator_select',
    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        },
        title: {
            type: String,
            required: true,
            default: null
        },
        modelValue: {
            type: String,
            required: false,
            default: null
        },
        'item-value': {
            type: String,
            required: false,
            default: 'id'
        },
        'item-text': {
            type: String,
            required: false,
            default: 'name'
        },
        'image-is-avatar': {
            type: Boolean,
            required: false,
            default: true
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:modelValue',
        'update:model-value',
        'select'
    ],
    data () {
        return {
            cacheItems: this.items,
            selected: this.modelValue,
            list: {
                on: false
            }
        }
    },
    computed: {
        empty () {
            return this.selected === null
        },
        group () {
            return groupBy(this.cacheItems, 'groupkey')
        }
    },
    watch: {
        modelValue (newVal) {
            this.selected = newVal
        },
        items () {
            this.selected = null
            this.updateVmodel()
        }
    },
    methods: {
        deselect () {
            this.selected = null
            this.$emit('deselect', null)
            this.updateVmodel()
        },
        select (id) {
            console.log('configurator select select()', id)

            if (this.selected === id) return this.deselect()

            this.selected = id
            // this.selected = this.selected && item.id === this.selected.id ? null : item
            this.$emit('select', this.selected)
            this.updateVmodel()
        },
        updateVmodel () {
            console.log('configurator select updateModel()', this.title, this.selected)
            this.$emit('update:modelValue', this.selected)
        },
        open () {
            this.list.on = true
        },
        close () {
            this.list.on = false
        }
    }
}
</script>

<style lang="scss" scoped>
.configurator-select {
    position: relative;

    &--header {
    }
}
</style>