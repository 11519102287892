<template>
    <div class="signup-confirm-view">
        <section>
            <bxs-layout>
                <div>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'signup_confirm'
}
</script>