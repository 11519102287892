<template>
    <bxs-col cols="12" ds="8">
        <bxs-card
        elevation
        outlined
        rounded
        class="pa-6 mb-8">
            <h3 class="mb-4">I miei preferiti</h3>

            <bxs-divider />

            <p class="mt-4">Gestisci i tuoi prodotti preferiti</p>
        </bxs-card>

        <bxs-card
        elevation
        outlined
        rounded
        class="px-6 mb-8">
            <bxs-collapse-item
            label="Nome prodotto base"
            hide-border>
                <bxs-row>
                    <bxs-col cols="4">
                        <bxs-figure />
                    </bxs-col>
                    <bxs-col />
                    <bxs-col cols="auto">
                        <router-link to="/prodotti/nome_prodotto_configurabile/configurazioni/configurazione_esempio" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="edit" />
                            <br>
                            <small>Modifica</small>
                        </router-link>

                        <br>

                        <router-link to="/me/desideri" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="trash" />
                            <br>
                            <small>Elimina</small>
                        </router-link>
                    </bxs-col>
                </bxs-row>

                <bxs-row no-gutters>
                    <bxs-col>
                        <h4 class="mb-0">Nome prodotto base</h4>
                    </bxs-col>

                    <bxs-col cols="auto">215,00 €</bxs-col>
                </bxs-row>

            </bxs-collapse-item>
        </bxs-card>

        <bxs-card
        elevation
        outlined
        rounded
        class="px-6 mb-8">
            <bxs-collapse-item
            label="Nome prodotto variante"
            hide-border>
                <bxs-row>
                    <bxs-col cols="4">
                        <bxs-figure />
                    </bxs-col>
                    <bxs-col />
                    <bxs-col cols="auto">
                        <router-link to="/prodotti/nome_prodotto_configurabile/configurazioni/configurazione_esempio" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="edit" />
                            <br>
                            <small>Modifica</small>
                        </router-link>

                        <br>

                        <router-link to="/me/desideri" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="trash" />
                            <br>
                            <small>Elimina</small>
                        </router-link>
                    </bxs-col>
                </bxs-row>

                <bxs-row no-gutters>
                    <bxs-col>
                        <h4 class="mb-0">Nome prodotto variante</h4>
                        <p class="mb-2">Colore: panna 050</p>
                    </bxs-col>

                    <bxs-col cols="auto">215,00 €</bxs-col>
                </bxs-row>
            </bxs-collapse-item>
        </bxs-card>

        <bxs-card
        elevation
        outlined
        rounded
        class="px-6 mb-8">
            <bxs-collapse-item
            label="Nome prodotto configurabile"
            hide-border>
                <bxs-row>
                    <bxs-col cols="4">
                        <bxs-figure />
                    </bxs-col>
                    <bxs-col />
                    <bxs-col cols="auto">
                        <router-link to="/prodotti/nome_prodotto_configurabile/configurazioni/configurazione_esempio" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="edit" />
                            <br>
                            <small>Modifica</small>
                        </router-link>

                        <br>

                        <router-link to="/me/desideri" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="trash" />
                            <br>
                            <small>Elimina</small>
                        </router-link>
                    </bxs-col>
                </bxs-row>

                <bxs-row no-gutters>
                    <bxs-col>
                        <h4 class="mb-0">Nome prodotto configurabile</h4>
                        <p class="mb-2">Colore: panna 050</p>
                        <p class="my-2">Misure: 120 x 250 cm</p>
                    </bxs-col>

                    <bxs-col cols="auto">215,00 €</bxs-col>
                </bxs-row>
            </bxs-collapse-item>
        </bxs-card>

        <bxs-card
        elevation
        outlined
        rounded
        class="px-6 mb-8">
            <bxs-collapse-item
            label="Nome prodotto configuratore"
            hide-border>
                <bxs-row>
                    <bxs-col cols="4">
                        <bxs-figure />
                    </bxs-col>
                    <bxs-col />
                    <bxs-col cols="auto">
                        <router-link to="/prodotti/nome_prodotto_configurabile/configurazioni/configurazione_esempio" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="edit" />
                            <br>
                            <small>Modifica</small>
                        </router-link>

                        <br>

                        <router-link to="/me/desideri" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="trash" />
                            <br>
                            <small>Elimina</small>
                        </router-link>
                    </bxs-col>
                </bxs-row>

                <bxs-row no-gutters>
                    <bxs-col>
                        <h4 class="mb-0">Nome prodotto configuratore</h4>
                        <p class="mb-2">Colore: panna 050</p>
                        <p class="my-2">Forma: rettangolare</p>
                        <p class="my-2">Misure: 120 x 250 cm</p>
                        <p class="mt-2">Bordo: senza bordo</p>
                    </bxs-col>

                    <bxs-col cols="auto">215,00 €</bxs-col>
                </bxs-row>
            </bxs-collapse-item>
        </bxs-card>

        <bxs-card
        elevation
        outlined
        rounded
        class="px-6 mb-8">
            <bxs-collapse-item
            label="Nome prodotto proposta"
            hide-border>
                <bxs-row>
                    <bxs-col cols="4">
                        <bxs-figure />
                    </bxs-col>
                    <bxs-col />
                    <bxs-col cols="auto">
                        <router-link to="/prodotti/nome_prodotto_configurabile/configurazioni/configurazione_esempio" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="edit" />
                            <br>
                            <small>Modifica</small>
                        </router-link>

                        <br>

                        <router-link to="/me/desideri" class="text-center mb-4">
                            <bxs-icon height="24px" width="auto" name="trash" />
                            <br>
                            <small>Elimina</small>
                        </router-link>
                    </bxs-col>
                </bxs-row>

                <bxs-row no-gutters>
                    <bxs-col>
                        <h4 class="mb-0">Nome prodotto proposta</h4>
                        <p class="mb-2">Colore: panna 050</p>
                        <p class="my-2">Forma: rettangolare</p>
                        <p class="my-2">Misure: 120 x 250 cm</p>
                        <p class="mt-2">Bordo: senza bordo</p>
                    </bxs-col>

                    <bxs-col cols="auto">215,00 €</bxs-col>
                </bxs-row>
            </bxs-collapse-item>
        </bxs-card>
    </bxs-col>
</template>

<script>
export default {
    name: 'desideri'
}
</script>