<template>
    <div class="tsm-product-card">
        <!--  -->
        <router-link
        :to="'/prodotti/' + item.slug"
        class="bxs-d-block">
            <bxs-figure
            :src="item.imgs.length > 0 ? item.imgs[0].url : null"
            ratio="4-3">
                <div class="flex nowrap justify-between">
                    <div
                    v-if="item.labels.length > 0"
                    class="flex column nowrap align-start">
                        <bxs-chip
                        v-for="(v, i) in item.labels"
                        :key="i"
                        uppercase
                        tile
                        x-small
                        class="mb-2">{{ v }}</bxs-chip>
                    </div>
                    <div v-else></div>

                    <div class="pa-1">
                        <bxs-btn icon>
                            <bxs-icon name="heart" />
                        </bxs-btn>
                    </div>
                </div>
            </bxs-figure>
        </router-link>

        <!--  -->
        <div class="tsm-product-card--content white">
            <div class="py-ui text-truncate">
                <h6 class="mb-0">{{ item.name }}</h6>

                <p class="mb-0">
                    <small>{{ item.description }}</small>
                </p>

                <p class="mt-2">
                    <i>{{ $filters.toHumanPrice(item.prices.sell) }}</i>
                </p>
            </div>

            <!-- TODO -->
            <!-- <div class="mt-ui">
                <div class="mb-2">
                    <small class="text-500">Colori disponibili <span>(4+)</span></small>
                </div>

                <bxs-carousel
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                item-width="30px"
                :items-space-between="5">
                    <template #item="{ item }">
                        <bxs-avatar
                        width="30"
                        height="30">{{ item }}</bxs-avatar>
                    </template>
                </bxs-carousel>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'product_card',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.tsm-product-card {
    a {
        &:hover {
            color: inherit;
        }
    }

    &--content {
        min-height: 120px;
    }
}
</style>