<template>
    <div class="me-configurazioni-view">
        <div class="mb-ui py-ui">
            <h2>Le tue configurazioni</h2>
            <bxs-divider></bxs-divider>
        </div>

        <bxs-data-lazy
        ref="data"
        :promises="() => $api.me.products_configurations.list()">
            <template #default="{ data, call }">
                <div
                v-if="!data.length"
                class="text-center">
                    <h6>Ancora nessuna configurazione</h6>
                    <p>In questa sezione puoi trovare la cronologia e le tue confugurazioni salvate.</p>
                </div>

                <ul v-else>
                    <li
                    v-for="item in data"
                    :key="item.id"
                    class="mb-ui mb-0-last">
                        <div class="flex nowrap justify-between mb-ui">
                            <div class="flex-1">
                                <h6 class="mb-2">{{ item.name }}</h6>

                                <ul class="text-black-darken-1 mb-3">
                                    <li>
                                        <p>Colore: {{ item.configuration.material.color.name }}</p>
                                    </li>
                                    <li v-if="item.configuration.dimensions.a && item.configuration.dimensions.b">
                                        <p>Misure: {{ item.configuration.dimensions.a }} x {{ item.configuration.dimensions.b }} cm</p>
                                    </li>
                                </ul>

                                <p>
                                    <b>{{ $filters.toHumanPrice(item.prices.sell) }}</b>
                                </p>
                            </div>

                            <div>
                                <bxs-menu>
                                    <bxs-list>
                                        <bxs-list-item prepend-icon="share">Condividi</bxs-list-item>

                                        <bxs-list-item
                                        :to="`/prodotti/${item.configurator}/configurazioni/${item.id}`"
                                        prepend-icon="edit">Modifica</bxs-list-item>

                                        <bxs-list-item
                                        prepend-icon="trash"
                                        @click="$api.me.products_configurations.remove(item.id).then(call)">Elimina</bxs-list-item>
                                    </bxs-list>
                                </bxs-menu>
                            </div>
                        </div>

                        <div>
                            <small>Data creazione: {{ $filters.toHumanDate(item.created_at) }}</small> <br>
                            <small>Ultimo aggiornamento: {{ $filters.toHumanDate(item.updated_at) }}</small>
                        </div>

                        <bxs-divider class="mt-ui"></bxs-divider>
                    </li>
                </ul>
            </template>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'me_configurazioni'
}
</script>