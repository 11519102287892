import Auth from './Auth'
import Catalog from './Catalog'
import Geo from './Geo'
import Me from './Me'
import Shop from './Shop'
import File from './File'

export const resources = {
    auth: {
        v: 'v1',
        path: 'auths',
        class: Auth
    },
    catalog: {
        v: 'v1',
        path: 'catalogs',
        class: Catalog
    },
    geo: {
        v: 'v1',
        path: 'geos',
        class: Geo
    },
    me: {
        v: 'v1',
        path: 'me',
        class: Me
    },
    shop: {
        v: 'v1',
        path: 'shop',
        class: Shop
    },
    files: {
        v: 'v1',
        path: 'files',
        class: File
    }
}

export function genResources(config, tools) {
    for (const key in resources) {
        const resource = resources[key]
        const Class = resource.class
        resources[key] = new Class(config, tools, resource.v, resource.path)
    }

    return resources
}