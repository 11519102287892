<template>
    <div class="me-profile">
        <bxs-data-lazy
        v-model="data_page"
        :promises="() => $api.me.doc()">
            <div v-if="data_page">
                <bxs-card class="mb-ui">
                    <h3 class="mb-4">I miei dati</h3>

                    <bxs-divider />

                    <p class="my-4">Visualizza i tuoi dati e modificali se necessario</p>

                    <p class="my-4">
                        <strong>Nome</strong><br>
                        {{ data_page.name.first }}
                    </p>

                    <p class="my-4">
                        <strong>Cognome</strong><br>
                        {{ data_page.name.last }}
                    </p>

                    <p class="my-4">
                        <strong>Numero di telefono</strong><br>
                        {{ data_page.phone || '--' }}
                    </p>

                    <label><small><bxs-icon name="info" /> Il tuo numero di telefono sarà utilizzato per comunicare con te in caso di problemi al tuo account</small></label>

                    <bxs-drawer title="Modifica i miei dati">
                        <template #activator="{ on }">
                            <bxs-btn v-on="on" block outlined class="mt-4">Modifica</bxs-btn>
                        </template>

                        <div class="pa-layout">
                            <bxs-form>
                                <label>Nome</label>
                                <bxs-text-field label="Nome inserito" name="nome" class="mb-6 mt-2" />

                                <label>Cognome</label>
                                <bxs-text-field label="Cognome inserito" name="cognome" class="mb-6 mt-2" />

                                <label>Telefono</label>
                                <bxs-text-field label="Telefono inserito" name="telefono" class="mb-6 mt-2" />

                                <bxs-btn block>Salva</bxs-btn>
                            </bxs-form>
                        </div>
                    </bxs-drawer>
                </bxs-card>

                <bxs-card class="mb-ui">
                    <h3 class="mb-4">La mia email</h3>

                    <bxs-divider />

                    <p class="my-4">
                        <strong>Email</strong><br>
                        {{ data_page.email }}
                    </p>

                    <bxs-drawer title="Modifica il mio indirizzo email">
                        <template #activator="{ on }">
                            <bxs-btn v-on="on" block outlined class="mt-4">Modifica</bxs-btn>
                        </template>

                        <div class="pa-layout">
                            <p class="mb-4">Ti invieremo tutte le comunicazioni importanti all’indirizzo email aggiornato</p>

                            <p class="my-4">
                                <strong>Email attuale</strong><br>
                                indirizzoemail@gmail.com
                            </p>

                            <bxs-form>
                                <label>Nuova email</label>
                                <bxs-text-field label="Inserisci l’indirizzo email" name="email" class="mb-6 mt-2" type="email" />

                                <label>Conferma email</label>
                                <bxs-text-field label="Conferma l’indirizzo email" name="conferma email" class="mb-6 mt-2" type="email" />

                                <label>Password</label>
                                <bxs-text-field label="Inserisci la password" name="password" class="mb-6 mt-2" type="password" />

                                <bxs-btn block>Salva</bxs-btn>
                            </bxs-form>
                        </div>
                    </bxs-drawer>
                </bxs-card>

                <bxs-card>
                    <h3 class="mb-4">La mia password</h3>

                    <bxs-divider />

                    <p class="my-4">
                        <strong>Password</strong><br>
                        ******************
                    </p>

                    <bxs-drawer title="Modifica la mia password">
                        <template #activator="{ on }">
                            <bxs-btn v-on="on" block outlined class="mt-4">Modifica</bxs-btn>
                        </template>

                        <div class="pa-layout">
                            <p class="mb-4">Cambia la tua password ogni volta che vuoi per mantenere sicuro il tuo account</p>

                            <bxs-form>
                                <label>La tua password</label>
                                <bxs-text-field label="Inserisci la password" name="vecchia password" type="password" class="mb-6 mt-2" />

                                <label>Nuova password</label>
                                <bxs-text-field label="Inserisci la password" name="nuova password" type="password" class="mb-6 mt-2" />

                                <label>Conferma nuova password</label>
                                <bxs-text-field label="Conferma la password" name="conferma password" type="password" class="mb-6 mt-2" />

                                <bxs-btn block>Salva</bxs-btn>
                            </bxs-form>
                        </div>
                    </bxs-drawer>
                </bxs-card>
            </div>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'me_profile',
    data () {
        return {
            data_page: null
        }
    }
}
</script>