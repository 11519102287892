<template>
    <bxs-drawer
    v-model="$store.state.drawers.sign.on"
    solo
    max-width="576"
    :title="view === 'signin' ? 'Accedi' : 'Registrati'">
        <div class="px-layout py-ui">
            <div v-if="view === 'signin'">
                <div class="mb-ui">
                    <div class="text-center mb-ui">
                        <h3>Sei già registrato?</h3>
                        <bxs-divider></bxs-divider>
                    </div>

                    <bxs-form
                    ref="form"
                    @submit="submit">
                        <div class="mb-ui">
                            <label for="email" class="mb-1">Email</label>
                            <bxs-text-field
                            v-model="model.email"
                            required
                            name="email"
                            label="Inserisci l’indirizzo email" />
                        </div>

                        <div class="mb-ui">
                            <label
                            for="password"
                            class="mb-1">Password</label>
                            <bxs-text-field
                            v-model="model.password"
                            required
                            name="password"
                            label="Inserisci la password"
                            type="password" />
                        </div>
                    </bxs-form>
                </div>

                <div class="mt-ui">
                    <h5 class="mb-ui">Sei un nuovo cliente?</h5>

                    <bxs-divider></bxs-divider>

                    <div>
                        <bxs-btn
                        outlined
                        block
                        @click="view = 'signup'">Registrati</bxs-btn>
                    </div>
                </div>
            </div>

            <!-- ------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------ -->
            <div v-else-if="view === 'signup'">
                <div class="mb-ui">
                    <div class="mb-ui">
                        <h3 class="text-center mb-ui">Sei un nuovo cliente?</h3>
                        <bxs-divider></bxs-divider>
                    </div>

                    <bxs-form
                    ref="form"
                    @submit="submit">
                        <div class="mb-ui">
                            <label
                            for="name.first"
                            class="mb-1">Nome</label>
                            <bxs-text-field
                            v-model="model.name.first"
                            required
                            name="name.first"
                            label="Inserisci il tuo nome" />
                        </div>

                        <div class="mb-ui">
                            <label
                            for="name.last"
                            class="mb-1">Cognome</label>
                            <bxs-text-field
                            v-model="model.name.last"
                            required
                            name="name.last"
                            label="Inserisci il tuo cognome" />
                        </div>

                        <div class="mb-ui">
                            <label
                            for="email"
                            class="mb-1">Email</label>
                            <bxs-text-field
                            v-model="model.email"
                            required
                            name="email"
                            label="Inserisci l’indirizzo email" />
                        </div>

                        <div class="mb-ui">
                            <label
                            for="password"
                            class="mb-1">Password</label>
                            <bxs-text-field
                            v-model="model.password"
                            required
                            name="password"
                            label="Inserisci la password"
                            type="password" />
                        </div>

                        <div>
                            <label
                            for="password_confirm"
                            class="mb-1">Conferma password</label>
                            <bxs-text-field
                            v-model="model.password_confirm"
                            required
                            name="password_confirm"
                            label="Conferma la Password"
                            type="password"
                            :rules="[(v) => v === model.password || 'Password errata']" />
                        </div>
                    </bxs-form>
                </div>

                <div class="mt-ui">
                    <div>
                        <h5 class="mb-ui">Sei già registrato?</h5>
                        <bxs-divider></bxs-divider>
                    </div>

                    <div>
                        <bxs-btn
                        outlined
                        block
                        @click="view = 'signin'">Accedi</bxs-btn>
                    </div>
                </div>
            </div>
        </div>

        <template #actions>
            <div class="pa-layout">
                <bxs-btn
                block
                @click="$refs.form.submit()">{{ view === 'signin' ? 'Accedi' : 'Registrati' }}</bxs-btn>

                <router-link
                v-if="view === 'signin'"
                to="/password/recovery"
                class="text-underline">Hai dimenticato la password?</router-link>

                <router-link
                v-else
                to="/"
                class="text-underline">Hai problemi a registrarti?</router-link>
            </div>
        </template>
    </bxs-drawer>
</template>

<script>
export default {
    name: 'sign_drawer',
    data () {
        return {
            view: 'signin',
            model: {
                email: null,
                password: null,
                name: {
                    first: null,
                    last: null
                },
                password_confirm: null
            }
        }
    },
    methods: {
        async submit () {
            try {
                if (this.view === 'signin') {
                    await this.$api.auth.signInWithEmailAndPassword(
                        this.model.email,
                        this.model.password
                    )
                } else {
                    await this.$api.auth.signup(this.model)
                }

                this.$api.me.checkout.getDoc()

                alert('Operazione effettuata con successo!')

                return this.$store.commit('set_drawer', {
                    key: 'sign',
                    value: false
                })
            } catch (err) {
                alert(err.message || err.name)
            }
        }
    }
}
</script>