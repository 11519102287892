import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import components from './components'
import directives from './directives'
import filters from './filters'

import Api from './assets/libs/Api'
import { useToast } from 'vue-toastification'
import Dinero from 'dinero.js'

Dinero.defaultCurrency = 'EUR'
Dinero.globalLocale = 'it-IT'
Dinero.defaultPrecision = 4

const app = createApp(App)
const api = new Api({
    app_id: 'tappetosumisura',
    prefix: 'tsm'
})

app.config.globalProperties.$api = reactive(api)
app.config.globalProperties.$toast = useToast()
app.config.globalProperties.$filters = filters
app.config.globalProperties.$dinero = Dinero

window.$api = app.config.globalProperties.$api

components(app)
directives(app)

app.config.errorHandler = (err, vm, info) => {
    console.log('error handler', err, vm, info)
    // const payload = Object.values(err.payload || {})
    let message = err.message || err.code

    if (err.code === 'resource.validation' && process.env.NODE_ENV === 'development') {
        message += Object.values(err.fields)
    }

    // vm.$toast.error(String(message))
    return message
}

app.use(store).use(router).mount('#app')

// Promise.all([
//     // router.isReady(),
//     api.auth.getAccess()
// ]).then(() => {
//     app.mount('#app', true)
// })