<template>
    <div class="rig">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'bxs-rig'
}
</script>

<style lang="scss" scoped>
.rig {
    width: 100%;
}
</style>