import Resource from './BaseResource'

class Shop extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)
    }

    async doc () {
        const doc = await this.tools.http.get(`${this.api}`)

        for (const k in doc) {
            this[k] = doc[k]
        }

        return doc
    }

    getMenu (id) {
        return this.tools.http.get(`${this.api}/shop/menu/${id}`)
    }

    getBanners (type) {
        return this.tools.http.get(`${this.api}/banners`, { type })
    }

    getDeps (q) {
        return this.tools.http.get(`${this.api}/deps`, q)
    }

    getPage (slug) {
        return this.tools.http.get(`${this.api}/deps/${slug}`)
    }

    search (keywords) {
        return this.tools.http.get(`${this.api}/search`, { keywords })
    }
}

export default Shop