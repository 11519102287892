<template>
    <div class="catalog-view">
        <section
        v-if="!is_preloaded"
        class="text-center">
            <bxs-loader />
        </section>

        <div v-else>
            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                dep
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section
            v-if="data_page.dep && data_page.dep.cover_img"
            class="py-0">
                <bxs-figure
                :src="data_page.dep.cover_img.url"
                ratio="15-4" />
            </section>

            <section>
                <bxs-layout>
                    <div class="text-center">
                        <h1 class="text-uppercase">{{ data_page.dep.name }}</h1>
                        <p>{{ data_page.dep.description }}</p>
                    </div>

                    <bxs-row v-if="data_page.dep.children.length > 0">
                        <bxs-col
                        v-for="dep in data_page.dep.children"
                        :key="dep.id"
                        cols="12"
                        ds="2">
                        <router-link
                        :to="`/catalogo/${dep.slug}`"
                        class="bxs-d-block">
                            <bxs-figure ratio="4-3" />

                            <div class="text-truncate">
                                <small>{{ dep.name }} ({{ dep.counts.products }})</small>
                            </div>
                        </router-link>
                        </bxs-col>
                    </bxs-row>
                </bxs-layout>
            </section>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section class="black-lighten-4 pa-0">
                <bxs-layout>
                    <bxs-row align="center">
                        <bxs-col cols="12" ds="7">
                            <h3>Come scegliere il tappeto giusto</h3>
                            <p>Il mondo dei tappeti non è mai stato così interessante: scopri i materiali, le funzioni e come scegliere il tappeto più adatto alle tue esigenze!</p>
                        </bxs-col>

                        <bxs-col cols="12" ds="5">
                            <bxs-figure ratio="4-3" />
                        </bxs-col>
                    </bxs-row>
                </bxs-layout>
            </section>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                products
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section id="section-products">
                <bxs-layout>
                    <div class="text-center">
                        <h1>Scopri tutti i prodotti</h1>
                    </div>

                    <bxs-row>
                        <bxs-col cols="8">
                            <bxs-chip
                            link
                            class="mr-2 mb-1"
                            @click="menu.filter = true">Ordina</bxs-chip>

                            <bxs-chip
                            v-for="(filter, i) in data_page.filters"
                            :key="i"
                            link
                            class="mr-2 mb-1"
                            @click="menu.filter = true">{{ filter.label }}</bxs-chip>

                            <bxs-chip
                            link
                            class="mb-1"
                            @click="menu.filter = true">Tutti i filtri</bxs-chip>
                        </bxs-col>

                        <bxs-col
                        v-if="data_page.materials_types && data_page.materials_types.options.length > 0"
                        cols="4"
                        class="text-right">
                            <bxs-btn
                            v-for="(v, i) in data_page.materials_types.options"
                            :key="i"
                            tile>{{ v }}</bxs-btn>
                        </bxs-col>
                    </bxs-row>

                    <div>
                        <bxs-row>
                            <bxs-col
                            v-for="(prd, i) in data_page.docs"
                            :key="i"
                            cols="12"
                            ds="4">
                                <product-card :item="prd" />
                                <div class="bxs-divider"></div>
                            </bxs-col>
                        </bxs-row>

                        <div
                        v-if="data_page.paginator.total_pages > 1"
                        class="text-center py-ui">
                            <bxs-btn
                            v-if="data_page.paginator.has_prev_page"
                            icon
                            @click="$router.push({ query: { ...$route.query, page: data_page.paginator.prev_page } })">
                                <bxs-icon name="chevron-left" />
                            </bxs-btn>

                            <span class="mx-ui">Pagina {{ data_page.paginator.page }} di {{ data_page.paginator.total_pages }}</span>

                            <bxs-btn
                            v-if="data_page.paginator.has_next_page"
                            icon
                            @click="$router.push({ query: { ...$route.query, page: data_page.paginator.next_page } })">
                                <bxs-icon name="chevron-right" />
                            </bxs-btn>
                        </div>
                    </div>
                </bxs-layout>
            </section>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section-guide />

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                dep page content
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section>
                <bxs-layout>
                    <h4>{{ data_page.dep.name }}</h4>
                    <div
                    v-html="data_page.dep.content"
                    class="tsm-wysiwyg"></div>
                </bxs-layout>
            </section>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                drawer filters
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <bxs-drawer
            v-model="menu.filter"
            :key="key"
            title="Filtra e ordina">
                <div class="px-layout">
                    <bxs-collapse-item label="Ordina">
                        <tsm-radio-group-field
                        v-model="data_page.sorter.selected"
                        :items="data_page.sorter.options" />
                    </bxs-collapse-item>

                    <bxs-collapse-item
                    v-for="(filter, i) in data_page.filters"
                    :key="i"
                    :disabled="filter.disabled"
                    :label="filter.label">
                        <tsm-radio-group-field
                        v-model="filter.selected"
                        :items="filter.options" />
                    </bxs-collapse-item>
                </div>

                <template #actions>
                    <div class="flex nowrap align-center px-layout py-ui">
                        <bxs-btn
                        :disabled="!has_selected_filters"
                        block
                        outlined
                        class="mr-1"
                        @click="cancelFilters">Cancella tutto</bxs-btn>

                        <bxs-btn
                        block
                        @click="filter">Applica</bxs-btn>
                    </div>
                </template>
            </bxs-drawer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'catalog',
    data () {
        return {
            is_preloaded: false,
            is_loading: true,
            key: 1,
            data_page: null,
            menu: {
                filter: false
            },
            item: {
                slug: 'nome-prodotto-base',
                name: 'Nome Prodotto',
                imgs: [{ url: null }],
                labels: ['-10%', 'Novità']
            }
        }
    },
    computed: {
        has_selected_filters () {
            if (!this.data_page) return false
            return this.data_page.filters.some(i => i.selected.length > 0)
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start (route_to) {
            console.log('catalog start()', this.$route.query)

            if (route_to) {
                this.$store.commit('set_is_loading', true)
            }

            try {
                this.data_page = await this.$api.catalog.getProducts(
                    route_to ? route_to.params.deps_slugs : this.$route.params.deps_slugs,
                    route_to ? route_to.query : this.$route.query
                )

                this.key += 1

                this.$nextTick(() => {
                    if (!route_to) {
                        window.scrollTo(0, 0)

                        // const section_products = this.$el.querySelector('#section-products')
                        // section_products.scrollIntoView({
                        //     behavior: 'smooth',
                        //     block: 'start',
                        //     inline: 'nearest'
                        // })
                    }
                })
            } catch (err) {
                console.error(err)
            } finally {
                this.is_preloaded = true
                this.is_loading = true
                this.$store.commit('set_is_loading', false)
            }
        },
        filter () {
            const r = {}

            this.data_page.filters.forEach((item) => {
                if (item.selected.length > 0) {
                    r[item.query_key] = item.selected.join(',')
                }
            })

            this.$router.push({
                query: {
                    ...this.$route.query,
                    ...r
                }
            })
        },
        cancelFilters () {
            this.$router.push({ query: {} })
        }
    },
    beforeRouteUpdate (route_to) {
        console.log('catalog beforeRouteUpdate()', route_to)
        this.start(route_to)
    }
}
</script>