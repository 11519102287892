<template>
    <section class="section-guide">
        <bxs-layout>
            <div>
                <h4>La guida giusta per te</h4>
            </div>

            <bxs-row>
                <bxs-col cols="12" ds="4">
                    <bxs-figure ratio="4-3" />
                    <div class="black-lighten-4 pa-ui">
                        <h6>Come prendere le misure</h6>
                        <p class="mb-0">Scopri come prendere le misure dei tuoi spazi seguendo i nostri suggerimenti</p>
                    </div>
                </bxs-col>

                <bxs-col cols="12" ds="4">
                    <bxs-figure ratio="4-3" />
                    <div class="black-lighten-4 pa-ui">
                        <h6>I materiali dei nostri tappeti</h6>
                        <p class="mb-0">Scopri di cosa sono fatti i nostri tappeti e le nostre passatoie</p>
                    </div>
                </bxs-col>

                <bxs-col cols="12" ds="4">
                    <bxs-figure ratio="4-3" />
                    <div class="black-lighten-4 pa-ui">
                        <h6>Bordi e finiture</h6>
                        <p class="mb-0">Scopri tutti i bordi e le finiture che possiamo realizzare</p>
                    </div>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_guide'
}
</script>