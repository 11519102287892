<template>
    <div class="checkout-payment">
        <bxs-data-lazy
        ref="dataLazy"
        :promises="() => $api.me.checkout.getPaymentsMethods()">
            <template #default="{ data }">
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="8">
                        <bxs-card>
                            <div class="mb-ui">
                                <h3>Scegli il tipo di pagamento</h3>
                                <bxs-divider />
                            </div>

                            <ul class="mb-ui">
                                <li
                                v-for="item in data"
                                :key="item.id"
                                class="mb-ui">
                                    <bxs-radio-field
                                    v-model="payment_method"
                                    :id="item.id"
                                    name="payment_method"
                                    :value="item.id">{{ item.name }}</bxs-radio-field>

                                    <div
                                    v-if="payment_method === 'stripe_card' && item.id === 'stripe_card'"
                                    class="mt-2">
                                        <bxs-card>
                                            <credit-card-field v-model="payment_method_id" />
                                        </bxs-card>
                                    </div>

                                    <div
                                    v-if="payment_method === 'stripe_sepa_debit' && item.id === 'stripe_sepa_debit'"
                                    class="mt-2">
                                        <bxs-card>
                                            <div>
                                                <bxs-text-field
                                                v-model="iban"
                                                label="Iban"
                                                placeholder="####  ####  ####  ####"
                                                class="mb-1" />

                                                <div class="text-right">
                                                    <bxs-btn @click="$api.me.payments_methods.createDirectIban(iban)">Salva</bxs-btn>
                                                </div>
                                            </div>

                                            <bxs-data-lazy
                                            :promises="() => $api.me.payments_methods.list('sepa_debit')"
                                            class="mt-2">
                                                <template #default="{ data }">
                                                    <ul>
                                                        <li
                                                        v-for="(_pm, i) in data.docs"
                                                        :key="_pm.id"
                                                        class="flex nowrap align-center mb-2 mb-0-last">
                                                            <bxs-radio-field
                                                            v-model="payment_method_id"
                                                            :id="_pm.id"
                                                            name="sepa_debit"
                                                            :value="_pm.id"
                                                            class="mb-0 mr-ui">{{ _pm.country }}...{{ _pm.last4 }}</bxs-radio-field>

                                                            <bxs-btn
                                                            icon
                                                            @click="$api.me.payments_methods.remove(_pm.id).then(() => data.docs.splice(i, 1))">
                                                                <bxs-icon name="trash"></bxs-icon>
                                                            </bxs-btn>
                                                        </li>
                                                    </ul>
                                                </template>
                                            </bxs-data-lazy>
                                        </bxs-card>
                                    </div>
                                </li>
                            </ul>

                            <div>
                                <p class="mt-4 mb-2"><small>Codice sconto</small></p>
                                <bxs-divider />
                                <p class="mt-2"><small>Potrai utilizzare il tuo codice sconto nella fase successiva.</small></p>
                            </div>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="4">
                        <bxs-card>
                            <div class="mb-ui">
                                <h3>Riepilogo</h3>
                                <bxs-divider />
                            </div>

                            <div class="flex nowrap justify-between mb-1">
                                <p>Subtotale</p>
                                <p>{{ $filters.toHumanPrice($api.me.checkout.cashier.gross) }}</p>
                            </div>

                            <div class="flex nowrap justify-between mb-1">
                                <p>Spedizione</p>
                                <p>{{ $filters.toHumanPrice($api.me.checkout.cashier.shipping_gross) }}</p>
                            </div>

                            <bxs-divider class="my-2" />

                            <div class="flex nowrap justify-between">
                                <p><b>Totale (iva inclusa)</b></p>
                                <p><b>{{ $filters.toHumanPrice($api.me.checkout.cashier.sell) }}</b></p>
                            </div>

                            <bxs-btn
                            color="primary"
                            :disabled="!payment_method"
                            block
                            class="mt-ui"
                            @click="next">Procedi</bxs-btn>
                        </bxs-card>
                    </bxs-col>
                </bxs-row>
            </template>
        </bxs-data-lazy>
    </div>
</template>

<script>
import CreditCardField from '@/components/project/CreditCardField.vue'

export default {
    name: 'checkout_payment',
    components: {
        'credit-card-field': CreditCardField
    },
    data () {
        return {
            iban: null,
            payment_method: null,
            payment_method_id: null
        }
    },
    created () {
        this.payment_method = this.$api.me.checkout.payment.method.id || this.$api.me.checkout.payment.method
        this.payment_method_id = this.$api.me.checkout.payment.gateway_customer_payment_method
    },
    methods: {
        async next () {
            try {
                await this.$api.me.checkout.addPaymentMethod(
                    this.payment_method,
                    this.payment_method_id
                )
            } catch (err) {
                alert(err.message || err.name || err)
            } finally {
                this.$router.push('/checkout/riepilogo')
            }
        }
    }
}
</script>