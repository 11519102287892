<template>
    <div class="checkout-addresses">
        <bxs-data-lazy
        ref="dataLazy"
        v-model="data_page"
        :promises="() => $api.me.getAddresses()">
            <template #default="{ data }">
                <bxs-row>
                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <bxs-col
                    cols="12"
                    ds="6">
                        <bxs-card class="mb-ui">
                            <h3 class="mb-ui">Indirizzo di consegna</h3>

                            <bxs-divider />

                            <bxs-row v-if="shipping_address">
                                <bxs-col>
                                    <p>{{ shipping_address.name }}</p>
                                    <p>{{ shipping_address.address }}</p>
                                    <p>{{ shipping_address.geo.label }}</p>
                                    <p>{{ shipping_address.phone }}</p>
                                </bxs-col>

                                <bxs-col cols="auto">
                                    <a
                                    role="button"
                                    class="text-center mb-4"
                                    @click="showAddresses('shipping')">
                                        <bxs-icon name="edit" />
                                        <br>
                                        <small>Cambia</small>
                                    </a>

                                    <br>

                                    <a
                                    role="button"
                                    class="text-center"
                                    @click="on = true">
                                        <bxs-icon name="plus" />
                                        <br>
                                        <small>Nuovo</small>
                                    </a>
                                </bxs-col>
                            </bxs-row>

                            <div v-else>
                                <p>No indirizzo di consegna</p>
                                <bxs-btn @click="on = true">Aggiungi nuovo indirizzo</bxs-btn>
                            </div>
                        </bxs-card>
                    </bxs-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <bxs-col
                    v-if="data.length > 0"
                    cols="12"
                    ds="6">
                        <bxs-card class="mb-ui">
                            <h3 class="mb-ui">Indirizzo di fatturazione</h3>

                            <bxs-divider />

                            <div v-if="billing_address">
                                <bxs-row>
                                    <bxs-col>
                                        <div v-if="!is_same">
                                            <p class="my-2">{{ billing_address.name }}</p>
                                            <p class="my-2">{{ billing_address.address }}</p>
                                            <p class="my-2">{{ billing_address.geo.label }}</p>
                                            <p class="my-2">{{ billing_address.phone }}</p>
                                        </div>

                                        <div v-else>
                                            <p>L'indirizzo di spedizione coincide con l'indirizzo di spedizione</p>
                                        </div>
                                    </bxs-col>

                                    <bxs-col cols="auto">
                                        <a
                                        role="button"
                                        class="text-center mb-4"
                                        @click="showAddresses('billing')">
                                            <bxs-icon name="edit" />
                                            <br>
                                            <small>Cambia</small>
                                        </a>

                                        <br>

                                        <a
                                        role="button"
                                        class="text-center"
                                        @click="on = true">
                                            <bxs-icon name="plus" />
                                            <br>
                                            <small>Nuovo</small>
                                        </a>
                                    </bxs-col>
                                </bxs-row>

                                <div>
                                    <bxs-btn
                                    block
                                    color="primary"
                                    @click="next">Procedi</bxs-btn>
                                </div>
                            </div>

                            <div v-else>
                                <p>No indirizzo di consegna</p>
                                <bxs-btn @click="on = true">Aggiungi nuovo indirizzo</bxs-btn>
                                <bxs-btn @click="showAddresses('billing')">Seleziona indirizzo esistente</bxs-btn>
                            </div>
                        </bxs-card>
                    </bxs-col>
                </bxs-row>
            </template>
        </bxs-data-lazy>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-menu
        title="I tuoi indirizzi"
        v-model="show_addresses"
        solo>
            <div class="pa-layout">
                <ul v-if="data_page.length > 0">
                    <li
                    v-for="(item, i) in data_page"
                    :key="item.id">
                        <div class="flex nowrap">
                            <div class="flex-1">
                                <div
                                v-if="item.is.default_shipping || item.is.default_billing"
                                class="mb-4">
                                    <bxs-icon
                                    name="check-cirlce-fill"
                                    class="text-primary mr-2"></bxs-icon>
                                    <small v-if="item.is.default_shipping && !item.is.default_billing">Indirizzo spedizione predefinito</small>
                                    <small v-else-if="!item.is.default_shipping && item.is.default_billing">Indirizzo fatturazione predefinito</small>
                                    <small v-else-if="item.is.default_shipping && item.is.default_billing">Indirizzo spedizione e fatturazione predefinito</small>
                                </div>

                                <p class="my-2">{{ item.name }}</p>
                                <p class="my-2">{{ item.address }}</p>
                                <p class="my-2">{{ item.geo.label }}</p>
                                <p class="my-2">{{ item.phone }}</p>
                            </div>

                            <div>
                                <a
                                v-if="(view === 'shipping' && !item.is.default_shipping) || (view === 'billing' && !item.is.default_billing)"
                                role="button"
                                class="text-center mb-4"
                                @click="select(item.id)">
                                    <small>Seleziona</small>
                                </a>

                                <br>

                                <a
                                role="button"
                                class="text-center mb-4"
                                @click="setEdit(item)">
                                    <bxs-icon name="edit" />
                                    <br>
                                    <small>Modifica</small>
                                </a>

                                <br>

                                <a
                                role="button"
                                class="text-center"
                                @click="deleteAddress(item.id)">
                                    <bxs-icon name="trash" />
                                    <br>
                                    <small>Elimina</small>
                                </a>
                            </div>
                        </div>

                        <bxs-divider
                        v-if="i < data_page.length - 1"
                        class="my-4 "></bxs-divider>
                    </li>
                </ul>
            </div>
        </bxs-menu>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-drawer
        v-model="on"
        :title="`${!model.id ? 'Aggiungi un nuovo' : 'Modifica'} indirizzo`">
            <bxs-form
            ref="form"
            class="pa-layout"
            @submit="createAddress">
                <!-- <div class="mb-ui">
                    <label>Label</label>
                    <bxs-text-field
                    label="Inserisci il tuo nome"
                    name="nome" />
                </div> -->

                <div class="mb-ui">
                    <label>Nome completo (nome e cognome)*</label>
                    <bxs-text-field
                    v-model="model.name"
                    label="Inserisci il tuo nome completo"
                    name="nome"
                    required />
                </div>

                <div class="mb-ui">
                    <label>Indirizzo *</label>
                    <bxs-text-field
                    v-model="model.address"
                    label="Inserisci il tuo indirizzo"
                    name="indirizzo"
                    required />
                </div>

                <div class="mb-ui">
                    <div v-if="!geo.selected">
                        <div class="mb-2">
                            <label>Città *</label>
                            <bxs-text-field
                            v-model="geo.keywords"
                            label="Città"
                            name="city"
                            required />
                        </div>

                        <div>
                            <div v-if="geo.items.length > 0 && !!geo.keywords">
                                <p><b>Seleziona la città</b></p>
                                <bxs-list class="mb-ui">
                                    <bxs-list-item
                                    v-for="item in geo.items"
                                    :key="item.id"
                                    append-icon="check"
                                    @click="selectCity(item)">{{ item.label }}</bxs-list-item>
                                </bxs-list>
                            </div>

                            <div v-else-if="!geo.items.length && !!geo.keywords">
                                <small>Nessun elemento trovato</small>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="flex nowrap align-center justify-between">
                            <p><b>Città</b></p>
                            <a
                            role="button"
                            @click="geo.selected = null"><small>Modifica</small></a>
                        </div>
                        <p>{{ geo.selected.label }}</p>
                    </div>
                </div>

                <div class="mb-ui">
                    <label>Telefono *</label>
                    <bxs-text-field
                    v-model="model.phone"
                    label="Inserisci il tuo numero di telefono"
                    name="telefono"
                    required />
                    <small class="mt-1">Il tuo numero di telefono sarà utilizzato per comunicare con te in caso di problemi con il tuo ordine o con la tua spedizione.</small>
                </div>

                <div>
                    <label class="mt-4">Note *</label>
                    <bxs-textarea-field
                    v-model="model.note"
                    label="Inserisci le tue note"
                    name="note" />
                </div>
            </bxs-form>

            <template #actions>
                <bxs-btn
                block
                @click="$refs.form.submit()">Salva</bxs-btn>
            </template>
        </bxs-drawer>
    </div>
</template>

<script>
export default {
    name: 'checkout_addresses',
    data () {
        return {
            data_page: null,
            on: false,
            geo: {
                keywords: null,
                busy: false,
                selected: null,
                items: []
            },
            show_addresses: false,
            view: 'shipping',
            //
            error: null,
            loading: false,
            model: {
                label: null,
                name: null,
                geo: null,
                address: null,
                phone: null,
                note: null,
                id: null
            }
        }
    },
    computed: {
        shipping_address () {
            return this.data_page.find(i => i.is.default_shipping)
        },
        billing_address () {
            return this.data_page.find(i => i.is.default_billing)
        },
        is_same () {
            return this.shipping_address && this.billing_address && this.shipping_address.id === this.billing_address.id
        }
    },
    watch: {
        'geo.keywords' () {
            this.getGeo()
        }
    },
    methods: {
        selectCity (item) {
            console.log('selectCity()', item)
            this.geo.selected = JSON.parse(JSON.stringify(item))
            this.model.geo = item.id
        },
        setEdit (item) {
            const _item = JSON.parse(JSON.stringify(item))
            this.model.id = _item.id
            this.model.name = _item.name
            this.model.geo = _item.geo.id
            this.geo.selected = _item.geo
            this.model.address = _item.address
            this.model.phone = _item.phone
            this.model.note = _item.note
            this.on = true
        },
        showAddresses (view) {
            this.view = view
            this.show_addresses = true
        },
        getGeo () {
            if (this.geo.busy || this.geo.keywords.length < 3) return

            this.$api.geo.list({
                filters: {
                    'city.name': this.geo.keywords
                },
                pagination: false,
                sort: { 'city.name': 1 }
            }).then((docs) => {
                this.geo.items = docs
                this.geo.busy = false
            })
        },
        async select (id, key) {
            await this.$api.me.selectAddress(id, key || this.view)
            this.$refs.dataLazy.play()
        },
        async createAddress () {
            this.error = null
            this.loading = true

            if (!this.data_page.length) {
                this.model.is = {
                    default_shipping: true,
                    default_billing: true
                }
            }

            try {
                await this.$api.me.createAddress(this.model)
                this.on = false
                this.$refs.dataLazy.play()
            } catch (err) {
                this.error = err.message || err.name
                alert(err.message || err.name || err)
            } finally {
                this.loading = false
            }
        },
        async deleteAddress (id) {
            try {
                await this.$api.me.deleteAddress(id)
                this.$refs.dataLazy.play()
            } catch (err) {
                alert(err.message || err.name || err)
            }
        },
        async next () {
            try {
                await this.$api.me.checkout.addAddress(this.shipping_address.id)
                await this.$api.me.checkout.addAddress(this.billing_address.id)
                this.$router.push('/checkout/spedizione')
            } catch (err) {
                alert(err.message || err.name || err)
            }
        }
    }
}
</script>