<template>
    <div class="password-confirm-view">
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col v-if="!$store.state.is_mobile" />

                    <bxs-col class="text-center">
                        <bxs-card
                        outlined
                        elevation
                        rounded
                        :width="$store.state.is_mobile ? '100%' : '450px'"
                        class="pa-8">
                            <h2 class="text-center mb-8">Password reimpostata</h2>

                            <p class="text-left">La tua password è stata reimpostata, adesso puoi tornare agli acquisti</p>

                            <p class="mt-8">
                                <bxs-btn to="/" block>Torna al negozio</bxs-btn>
                            </p>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col v-if="!$store.state.is_mobile" />
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'password_reset',
    data () {
        return {
            data: {
                code: '',
                pass: '',
                confPass: ''
            }
        }
    }
}
</script>