import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import {
    auth,
    nextFactory
} from './middlewares'

import Error404 from '../views/Error404.vue'
import Home from '../views/Home.vue'
import Signin from '../views/Signin.vue'
import Signup from '../views/Signup.vue'
import SignupConfirm from '../views/SignupConfirm.vue'
import PasswordRecovery from '../views/PasswordRecovery.vue'
import PasswordReset from '../views/PasswordReset.vue'
import PasswordConfirm from '../views/PasswordConfirm.vue'
import Page from '../views/Page.vue'
import Catalog from '../views/Catalog.vue'
import Product from '../views/Product.vue'
import ProductConfigurator from '../views/ProductConfigurator.vue'

import PrivacyPolicy from '../views/PrivacyPolicy.vue'

import Carrello from '../views/Carrello.vue'
import Checkout from '../views/Checkout.vue'
import Indirizzi from '../views/children/checkout/Indirizzi.vue'
import CheckoutShipping from '../views/children/checkout/CheckoutShipping.vue'
import CheckoutPayment from '../views/children/checkout/CheckoutPayment.vue'
import CheckoutRecap from '../views/children/checkout/CheckoutRecap.vue'
import CheckoutCompleted from '../views/children/checkout/CheckoutCompleted.vue'

import Me from '../views/Me.vue'
import MeProfilo from '../views/children/me/MeProfilo.vue'
import MeIndirizzi from '../views/children/me/MeIndirizzi.vue'
import MeOrders from '../views/children/me/MeOrders.vue'
import MeOrderRow from '../views/children/me/MeOrderRow.vue'
import MeDesideri from '../views/children/me/MeDesideri.vue'
import MeAssistenza from '../views/children/me/MeAssistenza.vue'
import MeConfigurazioni from '../views/children/me/MeConfigurazioni.vue'
import MeConfigurationFile from '../views/children/me/MeConfigurationFile.vue'

const routes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: '/errors/404'
    },
    {
        path: '/errors/404',
        name: 'error_404',
        component: Error404
    },
    // ----------------------------------------------------
    {
        path: '/signin',
        name: 'signin',
        component: Signin,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/signup/confirm',
        name: 'signup_confirm',
        component: SignupConfirm
    },
    {
        path: '/password/recovery',
        name: 'password_recovery',
        component: PasswordRecovery
    },
    {
        path: '/password/reset',
        name: 'password_reset',
        component: PasswordReset
    },
    {
        path: '/password/confirm',
        name: 'password_confirm',
        component: PasswordConfirm
    },
    // ----------------------------------------------------
    {
        path: '/policy',
        name: 'policies',
        component: PrivacyPolicy
    },
    // ----------------------------------------------------
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/:slug',
        name: 'page',
        component: Page
    },
    // ----------------------------------------------------
    {
        path: '/catalogo/:deps_slugs+',
        name: 'catalog',
        component: Catalog
    },
    {
        path: '/prodotti/:slug',
        name: 'product',
        component: Product
    },
    {
        path: '/prodotti/:slug/configurazioni/:configuration_id?',
        name: 'product_configurator',
        component: ProductConfigurator,
        meta: {
            is_funnell: true
        }
    },
    // ----------------------------------------------------
    {
        path: '/carrello',
        name: 'cart',
        component: Carrello
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout,
        meta: {
            middlewares: [auth],
            is_funnell: true
        },
        children: [
            {
                path: 'indirizzi',
                name: 'checkout_addresses',
                component: Indirizzi,
                meta: {
                    is_funnell: true
                }
            },
            {
                path: 'spedizione',
                name: 'checkout_shipping',
                component: CheckoutShipping,
                meta: {
                    is_funnell: true
                }
            },
            {
                path: 'pagamento',
                name: 'checkout_payment',
                component: CheckoutPayment,
                meta: {
                    is_funnell: true
                }
            },
            {
                path: 'riepilogo',
                name: 'checkout_recap',
                component: CheckoutRecap,
                meta: {
                    is_funnell: true
                }
            },
            {
                path: 'completato/:id',
                name: 'checkout_completed',
                component: CheckoutCompleted,
                meta: {
                    is_funnell: true
                }
            }
        ]
    },
    // ----------------------------------------------------
    {
        path: '/me',
        name: 'me',
        component: Me,
        meta: {
            middlewares: [auth]
        },
        children: [
            {
                path: 'profilo',
                name: 'me_profile',
                component: MeProfilo
            },
            {
                path: 'indirizzi',
                name: 'me_indirizzi',
                component: MeIndirizzi
            },
            {
                path: 'configurazioni',
                name: 'me_configurazioni',
                component: MeConfigurazioni
            },
            {
                path: 'configuration/:id/files',
                name: 'me_configuration_file',
                component: MeConfigurationFile
            },
            {
                path: 'ordini',
                name: 'me_orders',
                component: MeOrders
            },
            {
                path: 'ordini/:order_id/row/:row_id',
                name: 'me_order_row',
                component: MeOrderRow
            },
            {
                path: 'desideri',
                name: 'me_desideri',
                component: MeDesideri
            },
            {
                path: 'assistenza',
                name: 'me_assistenza',
                component: MeAssistenza
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition || to.name === 'catalog') {
            return savedPosition
        } else {
            return { top: 0 }
        }

        // else if (to.name === 'catalog') {
        //     return {
        //         // could also be
        //         el: '#section-products',
        //         // 0px above the element
        //         top: 0
        //     }
        // }
    }
})

router.beforeEach((to, from, next) => {
    console.log('Router ext beforeEach()')

    store.commit('set_page_enter', to.fullPath)
    store.commit('set_page_leave', from.fullPath)

    if (to.name !== 'signin') {
        sessionStorage.setItem('tsm_last_page', to.fullPath)
    }

    if (to.meta.middlewares) {
        const middlewares = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
        const context = { from, to, next, router }
        const nextMiddleware = nextFactory(context, middlewares, 1)
        const auths = to.meta.authorize ? to.meta.authorize : []
        return middlewares[0](auths, { ...context, next: nextMiddleware })
    }

    next()
})

export default router
