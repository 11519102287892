<template>
    <div class="pa-ui small">
        <div class="mb-ui">
            <p class="text-mute mb-1">Descrizione del servizio</p>
            <p class="mb-0">Google Analytics (Universal Analytics) è un servizio di analisi web fornito da Google LLC oppure da Google Ireland Limited, a seconda di come il Titolare gestisce il trattamento dei Dati, (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google. Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Società di trasformazione</p>
            <p class="mb-0">Google Ireland Limited</p>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Finalità dei dati</p>
            <p class="mb-2">Questa lista presenta gli scopi della raccolta e del trattamento dei dati.</p>
            <bxs-chip tile outlined small class="mr-2">Analytics</bxs-chip>
            <bxs-chip tile outlined small class="mr-2">Trasferimento internazionale di dati</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Tecnologie impiegate</p>
            <p class="mb-2">Questa lista presenta tutte le tecnologie utilizzate da questo servizio per raccogliere dati. Tecnologie tipiche sono i cookie e i pixel che vengono inseriti nel browser.</p>
            <bxs-chip tile outlined small class="mr-2">Cookies</bxs-chip>
            <bxs-chip tile outlined small class="mr-2">Tag</bxs-chip>
            <bxs-chip tile outlined small>SDK</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Dati raccolti</p>
            <p class="mb-2">Questa lista presenta tutti i dati (personali) raccolti da o tramite l'utilizzo di questo servizio.</p>
            <bxs-chip tile outlined small class="mr-2 mb-1">Informazioni sull'ora (ad es. ora dell'evento)</bxs-chip>
            <bxs-chip tile small outlined>Informazioni sul negozio (ad es. Paese)</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Base giuridica</p>
            <p class="mb-2">Di seguito si elencano le basi giuridiche richieste per il trattamento dei dati.</p>
            <bxs-chip tile outlined small>Art. 6 (1) (a) GDPR (consenso)</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Luogo del trattamento</p>
            <p class="mb-2">È il luogo principale in cui vengono elaborati i dati raccolti. Qualora i dati vengano elaborati anche in altri paesi, all'utente viene fornita separatamente una comunicazione.</p>
            <bxs-chip tile outlined small>Stati Uniti d'America e Unione Europea</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Periodo di conservazione</p>
            <p class="mb-1">Il periodo di conservazione è il periodo di tempo in cui i dati raccolti vengono salvati ai fini del trattamento. I dati devono essere eliminati una volta che non sono più necessari per gli scopi di elaborazione dichiarati.</p>
            <p class="mb-0">Fusco Express non memorizza internamente alcuni dei dati raccolti da Google Analytics.</p>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Fare clic qui per leggere l'informativa sulla privacy del servizio responsabile del trattamento dei dati</p>
            <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cookie_item_ga'
}
</script>