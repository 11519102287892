<template>
    <section class="section-newsletter white-lighten-4">
        <bxs-layout>
            <bxs-row>
                <bxs-col cols="12" ds="7">
                    <newsletter-box />
                </bxs-col>

                <bxs-col cols="12" ds="5">
                    <bxs-figure ratio="1-1" />
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
import NewsletterBox from '@/components/project/NewsletterBox.vue'

export default {
    name: 'section_newsletter',
    components: {
        'newsletter-box': NewsletterBox
    }
}
</script>