// comps
import Layout from './layout/Layout.vue'
import Row from './layout/Row/Row.vue'
import Col from './layout/Col/Col.vue'
import Ver from './layout/Ver.vue'
import Rig from './layout/Rig.vue'

import Alert from './Alert.vue'
import Btn from './Btn.vue'
import Chip from './Chip.vue'
import ChipGroup from './ChipGroup.vue'
import Icon from './Icon.vue'
import Menu from './Menu.vue'
import List from './List.vue'
import ListItem from './ListItem.vue'
import CollapseItem from './CollapseItem.vue'
import Spacer from './Spacer.vue'
import Toolbar from './Toolbar.vue'
import Card from './Card.vue'
import Figure from './media/FigureComp.vue'
import Loader from './Loader.vue'
import Avatar from './Avatar.vue'
import Carousel from './Carousel.vue'
import Divider from './Divider.vue'
import Drawer from './Drawer.vue'
import Modal from './Modal.vue'

import Form from './form/Form.vue'
import BxsSwitchField from './form/BxsSwitchField.vue'
import BxsTextField from './form/textField.vue'
import BxsRadioField from './form/radioField.vue'
import BxsSelectField from './form/selectField.vue'
import BxsTextareaField from './form/BxsTextareaField.vue'
import QtyField from './form/QtyField.vue'
import RadioGroupField from './form/RadioGroupField.vue'

import DataLazy from './DataLazy.vue'

import Logo from './logo.vue'
import Footer from './layout/Footer.vue'
import TopNavigation from './TopNavigation.vue'

// project
import ProductCard from '@/components/project/ProductCard.vue'
import PageCard from '@/components/project/PageCard.vue'
import SectionGuide from '@/components/project/sections/SectionGuide.vue'
import SectionDeps from '@/components/project/sections/SectionDeps.vue'
import StringList from '@/components/project/StringList.vue'
import Sign from '@/components/project/Sign.vue'
import CartItems from '@/components/project/CartItems.vue'

// libs
import Toast from 'vue-toastification'
import stickybits from 'stickybits'

import 'vue-toastification/dist/index.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default function createComp(app) {
    app.component('section-guide', SectionGuide)
    app.component('section-deps', SectionDeps)
    app.component('product-card', ProductCard)
    app.component('page-card', PageCard)
    app.component('bxs-string-list', StringList)
    app.component('tsm-sign', Sign)
    app.component('cart-items', CartItems)

    app.component('logo', Logo)
    app.component('bxs-top-nav', TopNavigation)

    app.component('bxs-layout', Layout)
    app.component('bxs-row', Row)
    app.component('bxs-col', Col)
    app.component('bxs-ver', Ver)
    app.component('bxs-rig', Rig)
    app.component('bxs-footer', Footer)
    app.component('bxs-divider', Divider)
    app.component('bxs-alert', Alert)
    app.component('bxs-drawer', Drawer)
    app.component('bxs-modal', Modal)

    app.component('bxs-toolbar', Toolbar)
    app.component('bxs-card', Card)
    app.component('bxs-data-lazy', DataLazy)
    app.component('bxs-figure', Figure)
    app.component('bxs-carousel', Carousel)

    app.component('bxs-btn', Btn)
    app.component('bxs-menu', Menu)
    app.component('bxs-list', List)
    app.component('bxs-list-item', ListItem)
    app.component('bxs-collapse-item', CollapseItem)

    app.component('bxs-icon', Icon)
    app.component('bxs-chip', Chip)
    app.component('bxs-avatar', Avatar)
    app.component('bxs-chip-group', ChipGroup)
    app.component('bxs-loader', Loader)

    app.component('bxs-form', Form)
    app.component('bxs-switch-field', BxsSwitchField)
    app.component('bxs-text-field', BxsTextField)
    app.component('bxs-radio-field', BxsRadioField)
    app.component('bxs-select-field', BxsSelectField)
    app.component('bxs-textarea-field', BxsTextareaField)
    app.component('bxs-qty-field', QtyField)
    app.component('tsm-radio-group-field', RadioGroupField)

    app.component('bxs-spacer', Spacer)

    app.use(Toast, {
        position: 'bottom-center',
        timeout: 2500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        newestOnTop: false,
        transition: 'Vue-Toastification__fade',
        bodyClassName: ['bxs-notify'],
        maxToasts: 1,
        showCloseButtonOnHover: true,
        hideProgressBar: true,
        draggable: true
    })

    app.config.globalProperties.$sticky = stickybits
}