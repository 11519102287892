<template>
    <div class="checkout-recap">
        <div v-if="!is_preloaded">
            <div class="text-center h1">
                ...loading
            </div>
        </div>

        <bxs-row v-else>
            <bxs-col
            cols="12"
            ds="8">
                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card class="mb-ui">
                    <div class="mb-ui">
                        <h5>Carrello</h5>
                        <bxs-divider />
                    </div>

                    <cart-items />
                </bxs-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card class="mb-ui">
                    <bxs-row>
                        <bxs-col class="py-0">
                            <h5 class="mb-4">Indirizzi di consegna</h5>
                        </bxs-col>

                        <bxs-col cols="auto" class="py-0">
                            <router-link to="/checkout/indirizzi">
                                <small><bxs-icon name="edit" /> MODIFICA</small>
                            </router-link>
                        </bxs-col>
                    </bxs-row>

                    <bxs-divider />

                    <p>{{ $api.me.checkout.addresses.shipping.name }}</p>
                    <p>{{ $api.me.checkout.addresses.shipping.address }}</p>
                    <p>{{ $api.me.checkout.addresses.shipping.geo.label }}</p>
                    <p>{{ $api.me.checkout.addresses.shipping.phone }}</p>
                </bxs-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card>
                    <bxs-row>
                        <bxs-col class="py-0">
                            <h5 class="mb-4">Indirizzi di fatturazione</h5>
                        </bxs-col>

                        <bxs-col cols="auto" class="py-0">
                            <router-link to="/checkout/indirizzi">
                                <small><bxs-icon name="edit" /> MODIFICA</small>
                            </router-link>
                        </bxs-col>
                    </bxs-row>

                    <bxs-divider />

                    <p>{{ $api.me.checkout.addresses.billing.name }}</p>
                    <p>{{ $api.me.checkout.addresses.billing.address }}</p>
                    <p>{{ $api.me.checkout.addresses.billing.geo.label }}</p>
                    <p>{{ $api.me.checkout.addresses.billing.phone }}</p>
                </bxs-card>
            </bxs-col>

            <bxs-col
            cols="12"
            ds="4">
                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card class="mb-ui">
                    <bxs-row>
                        <bxs-col class="py-0">
                            <h5 class="mb-4">Pagamento</h5>
                        </bxs-col>

                        <bxs-col cols="auto" class="py-0">
                            <router-link to="/checkout/pagamento">
                                <small><bxs-icon name="edit" /> MODIFICA</small>
                            </router-link>
                        </bxs-col>
                    </bxs-row>

                    <bxs-divider />

                    <p class="mt-4">{{ $api.me.checkout.payment.method.name }}</p>
                </bxs-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card class="mb-ui">
                    <h5 class="mb-4">Codice sconto</h5>

                    <bxs-divider />

                    <bxs-text-field
                    label="Codice sconto"
                    solo
                    class="mt-4" />
                </bxs-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card>
                    <div class="mb-ui">
                        <h5>Riepilogo</h5>
                        <bxs-divider />
                    </div>

                    <div class="flex nowrap justify-between mb-1">
                        <p>Subtotale</p>
                        <p>{{ $filters.toHumanPrice($api.me.checkout.cashier.gross) }}</p>
                    </div>

                    <div class="flex nowrap justify-between mb-1">
                        <p>Spedizione</p>
                        <p>{{ $filters.toHumanPrice($api.me.checkout.cashier.shipping_gross) }}</p>
                    </div>

                    <bxs-divider class="my-2" />

                    <div class="flex nowrap justify-between">
                        <p><b>Totale (iva inclusa)</b></p>
                        <p><b>{{ $filters.toHumanPrice($api.me.checkout.cashier.sell) }}</b></p>
                    </div>

                    <div
                    v-if="$api.me.checkout.payment.method.id === 'paypal'"
                    id="paypal-button-container"
                    class="mt-ui"></div>

                    <bxs-btn
                    v-else
                    block
                    color="primary"
                    class="mt-ui"
                    @click="createIntentOrder">Conferma e procedi all'acquisto</bxs-btn>
                </bxs-card>
            </bxs-col>
        </bxs-row>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
// 4242424242424242
// 4000056655665556

export default {
    name: 'checkout_recap',
    data () {
        return {
            is_preloaded: false,
            is_loading: false,
            data_page: null
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            try {
                this.data_page = await this.$api.me.checkout.getDoc()

                if (this.$api.me.checkout.payment.method.id.split('_')[0] === 'stripe') {
                    this.stripe = await loadStripe('pk_test_51NID5GHqWowVPE4KT2JM2HWwjUwFS04LFkmUG1jQEU2OuW5f97EU5BwkEmdSWr1FIuhSHiK0AiPltjhFXVtRV0Qy00Oqrbb0lV')
                } else if (this.$api.me.checkout.payment.method.id === 'paypal') {
                    await this.paypal().load()
                }

                this.is_preloaded = true

                this.$nextTick(() => {
                    if (this.$api.me.checkout.payment.method.id === 'paypal') {
                        this.paypal().init()
                    }
                })
            } catch (err) {
                alert(err.message || err.name || err)
            } finally {
                this.is_preloaded = true
            }
        },
        paypal () {
            return {
                load: () => {
                    return new Promise((resolve, reject) => {
                        const script = document.createElement('script')
                        script.setAttribute('data-sdk-integration-source', 'button-factory')
                        const pk = 'AYnxDTg2uf3HttOtKB2VD_McDWyzTncv4Ij5uMCh5Yl2RkX006rhkaO28f7YucFB_3xoL0l_yybD0wd8'
                        // const query_components = '&components=buttons'
                        const query = '?client-id=' + pk + '&currency=EUR&disable-funding=card,credit,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo'
                        script.src = 'https://www.paypal.com/sdk/js' + query
                        // data-sdk-integration-source="button-factory"
                        // script.setAttribute('data-sdk-integration-source', 'button-factory')
                        script.addEventListener('load', resolve)
                        document.body.appendChild(script)
                    })
                },
                init: () => {
                    // eslint-disable-next-line
                    return paypal.Buttons({
                        style: {
                            shape: 'rect',
                            color: 'blue',
                            layout: 'vertical',
                            label: 'paypal'
                        },
                        onClick: async (data, actions) => {
                            this.$store.commit('loading', true)
                            console.log('paypal onClick()', data)

                            try {
                                this.intent = await this.$api.me.checkout.createIntent(this.$api.me.checkout.id)

                                if (this.intent.errors) {
                                    return actions.reject()
                                }
                            } catch (err) {
                                this.$store.commit('loading', false)
                                console.log('paypal onClick() error', err)
                                return actions.reject()
                            }
                        },
                        createOrder: (data, actions) => {
                            console.log('paypal createOrder()', data)

                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: 'EUR',
                                            value: this.$dinero({
                                                amount: this.$api.me.checkout.cashier.sell
                                            }).toUnit().toFixed(2)
                                        }
                                    }
                                ]
                                // shipping: {
                                //     method: this.$api.me.checkuot.doc.shipping.method.id,
                                //     address: {
                                //         name: {
                                //             full_name: this.addressShipping.name.first,
                                //             surname: this.addressShipping.name.last
                                //         },
                                //         address_line_1: this.addressShipping.street,
                                //         postal_code: this.addressShipping.zipcode
                                //     }
                                // }
                            })
                        },
                        onCancel: (data, actions) => {
                            console.log('paypal onCancel()', data)
                            this.$store.commit('loading', false)
                        },
                        onApprove: async (data, actions) => {
                            console.log('paypal onApprove()', data, actions)

                            try {
                                const gateway_response = await actions.order.capture()
                                console.log('paypal onApprove() order.capture()', gateway_response)
                                this.createIntentOrder(gateway_response)
                            } catch (err) {
                                console.log('paypal onApprove() order.capture() error', err)
                            }
                        },
                        //
                        payment: (data, actions) => {
                            console.log('paypal payment()', data)
                        },
                        onAuthorize: (data, actions) => {
                            console.log('paypal onAuthorize()', data)
                        },
                        onError: (err) => {
                            this.$store.commit('loading', false)
                            console.log('paypal onError()', err)
                        }
                    }).render('#paypal-button-container')
                }
            }
        },
        async createIntentOrder (gateway_response) {
            this.$store.commit('set_is_loading', true)
            this.error = null
            this.is_loading = true

            try {
                if (!this.intent) {
                    this.intent = await this.$api.me.checkout.createIntent(this.data_page.id)
                    console.log(this.intent)
                }

                if (this.intent.errors) {
                    throw new Error('Ops, qualcosa è andato storto, riprova più tardi!')
                }

                if (this.intent.payment_method.split('_')[0] === 'stripe') {
                    if (this.intent.payment_method.includes('stripe_')) {
                        const payment_confirmed = await this.stripe[
                            this.intent.payment_method === 'stripe_card' ? 'confirmCardPayment' : 'confirmSepaDebitPayment'
                        ](this.intent.payment_intent.client_secret, {
                            payment_method: this.intent.payment_intent.payment_method
                        })

                        console.log('payment_confirmed', payment_confirmed)

                        if (payment_confirmed.error) {
                            throw new Error(payment_confirmed.error.message)
                        }
                    }
                }

                // confirm intent
                const order_id = await this.$api.me.orders.confirmIntent(
                    this.intent.order_id,
                    this.intent.payment_intent.id,
                    gateway_response
                )

                await this.$api.me.checkout.getDoc()

                // alert('completato!')
                this.$router.push('/checkout/completato/' + order_id)
            } catch (err) {
                console.log(err)
                this.error = err.message || err.name || err
                alert(err.message || err.name || err)
            } finally {
                this.is_loading = false
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>