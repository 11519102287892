<template>
    <div
    :key="key"
    class="product-configurator-view">
        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            navbar
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <div class="product-configurator-view--navbar">
            <div class="flex-1">
                <logo max-width="60px" />
            </div>

            <div class="flex-1 text-center">
                <small class="text-700">
                    <span v-if="!is.preload">...</span>
                    <span v-else>Configuratore {{ product.name }}</span>
                </small>
            </div>

            <div class="flex-1 text-right">
                <button
                class="mr-4"
                @click="saveInProfile">
                    <bxs-icon name="heart" />
                </button>

                <button @click="exit">
                    <bxs-icon name="close" />
                </button>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            main
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <div class="product-configurator-view--main">
            <!-- --------------------------------------------------
                hero
            -------------------------------------------------- -->
            <div class="product-configurator-view--main--hero">
                <!-- <div class="product-configurator-view--main--hero--toolbar flex nowrap align-center justify-between px-layout">
                    <h5 class="mb-0">
                        <span v-if="!is.preload">...</span>
                        <span v-else>{{ product.name }}</span>
                    </h5>

                    <a role="button">
                        <router-link :to="`/prodotti/${$route.params.slug}`">
                            <small>Scheda prodotto</small>
                        </router-link>
                    </a>
                </div> -->

                <div
                ref="figure_box"
                class="flex align-center">
                    <bxs-figure
                    ratio="4-3"
                    src="/img/floor.jpeg"
                    style="position: relative;">
                        <img
                        v-if="selectedMaterial && selectedMaterial.img"
                        :src="selectedMaterial.img.url"
                        style="position: absolute; top: 0; left: 0; z-index: 1; max-width: 80%; box-shadow: 0 0 10px rgba(0,0,0,0.75);" />

                        <img
                        v-if="selectedBorderColor && selectedBorderColor.img"
                        :src="selectedBorderColor.img.url"
                        style="position: absolute; top: 0; left: 0; z-index: 2; max-width: 80%;" />
                    </bxs-figure>
                </div>
            </div>

            <!-- ----------------------------------------------------------------------------------------------------
                errors contents
            ---------------------------------------------------------------------------------------------------- -->
            <div
            v-if="!is.preload"
            class="product-configurator-view--main--content">
                <bxs-loader label="Caricamento configuratore" />
            </div>

            <div
            v-else-if="is.preload && (is.error || status.value === 'ko' || configuration_status.value === 'ko')"
            class="product-configurator-view--main--content">
                <bxs-alert
                type="error"
                class="mb-ui">
                    <p>Ops, questa configurazione non è al momento disponibile</p>
                    <ul class="mt-1">
                        <li
                        v-for="(v, i) in [...status.errors, ...configuration_status.errors]"
                        :key="i">- {{ v }}</li>
                    </ul>
                </bxs-alert>

                <bxs-btn
                block
                :to="`/prodotti/${product.slug}/configurazioni`"
                class="mb-2">Riparti</bxs-btn>
            </div>

            <!-- ----------------------------------------------------------------------------------------------------
                content
            ---------------------------------------------------------------------------------------------------- -->
            <div
            v-else
            class="product-configurator-view--main--content">
                <!-- <bxs-chip-group
                v-if="status.value === 'ok'"
                class="mb-ui">
                    <bxs-chip
                    v-for="(tab, i) in tabs_items"
                    :key="i"
                    :color="tab.completed ? 'primary' : null"
                    :disabled="tab.disabled"
                    :outlined="tab.disabled"
                    :actived="tabs.i === i"
                    :elevation="tabs.i === i"
                    @click="toStep(i)">
                        <svg
                        viewBox="0 0 28 28"
                        fill="none"
                        width="1em"
                        height="1em"
                        xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.9918 28.0003H6.96421C3.76026 28.0003 1.07854 25.5746 0.985821 22.5936C0.9397 21.1048 1.49315 19.6885 2.54335 18.6051C3.62863 17.4857 5.16022 16.8434 6.74561 16.8434H25.987C27.0973 16.8434 28 17.7226 28 18.803V26.0401C28 27.1206 27.0992 27.9998 25.9918 27.9998V28.0003ZM6.74561 17.8042C5.41869 17.8042 4.13836 18.3399 3.23276 19.2739C2.36464 20.1689 1.90775 21.3378 1.94619 22.5638C2.02257 25.0317 4.27384 27.0394 6.96373 27.0394H25.9918C26.5693 27.0394 27.0392 26.5912 27.0392 26.0406V18.8035C27.0392 18.253 26.5674 17.8047 25.987 17.8047H6.74561V17.8042Z" fill="#75787B"/>
                            <path d="M6.96437 27.5198C4.07222 27.5198 1.55336 25.369 1.46641 22.5787C1.39723 20.3505 2.81255 18.437 4.82649 17.6774L4.92305 17.5808L4.96821 17.626C5.32036 17.5045 5.68885 17.417 6.07078 17.3699L5.60237 16.9015L8.92834 13.5756L12.6766 17.3238H14.0352L9.60766 12.8962L13.7345 8.7694L20.2649 15.2998L18.2404 17.3243H19.599L24.2092 12.7142C24.5873 12.3361 24.7996 11.8379 24.8078 11.3108C24.8164 10.779 24.6156 10.2808 24.2428 9.90849L19.1258 4.7915C18.3614 4.02715 17.1013 4.04396 16.3163 4.82897L13.3948 7.75043L2.71022 18.435C1.5894 19.5554 0.960041 21.0923 0.984062 22.6512C1.0076 24.1602 1.61774 25.553 2.7035 26.5729C3.71623 27.5246 5.04172 27.9935 6.39747 27.9935C7.18536 27.9935 7.98335 27.8336 8.73665 27.5198H6.96437ZM16.9951 5.50781C17.207 5.29594 17.4842 5.18881 17.7552 5.18881C18.0083 5.18881 18.2577 5.28201 18.446 5.47034L23.563 10.5873C23.9526 10.9769 23.9372 11.626 23.5294 12.0344L20.9437 14.62L14.4133 8.0896L16.9951 5.50781Z" fill="#75787B"/>
                            <path d="M9.39424 27.8009C9.12857 27.8009 8.91382 27.5861 8.91382 27.3205V17.3238C8.91382 17.0581 9.12857 16.8434 9.39424 16.8434C9.65992 16.8434 9.87466 17.0581 9.87466 17.3238V27.3205C9.87466 27.5861 9.65992 27.8009 9.39424 27.8009Z" fill="#75787B"/>
                            <path d="M15.0583 27.8009C14.7926 27.8009 14.5779 27.5861 14.5779 27.3205V17.3238C14.5779 17.0581 14.7926 16.8434 15.0583 16.8434C15.324 16.8434 15.5387 17.0581 15.5387 17.3238V27.3205C15.5387 27.5861 15.324 27.8009 15.0583 27.8009Z" fill="#75787B"/>
                            <path d="M21.8554 27.8009C21.5897 27.8009 21.375 27.5861 21.375 27.3205V17.3238C21.375 17.0581 21.5897 16.8434 21.8554 16.8434C22.1211 16.8434 22.3358 17.0581 22.3358 17.3238V27.3205C22.3358 27.5861 22.1211 27.8009 21.8554 27.8009Z" fill="#75787B"/>
                            <path d="M5.4287 24.0353C4.53895 24.0353 3.81592 23.3113 3.81592 22.4221C3.81592 21.5328 4.53944 20.8088 5.4287 20.8088C6.31796 20.8088 7.04196 21.5328 7.04196 22.4221C7.04196 23.3113 6.31796 24.0353 5.4287 24.0353ZM5.4287 21.7692C5.06934 21.7692 4.77676 22.0618 4.77676 22.4216C4.77676 22.7814 5.06934 23.074 5.4287 23.074C5.78805 23.074 6.08111 22.7814 6.08111 22.4216C6.08111 22.0618 5.78854 21.7692 5.4287 21.7692Z" fill="#75787B"/>
                            <path d="M7.68002 27.6375V27.3632C6.04466 27.7567 4.29447 27.4084 3.0324 26.2222C1.40665 24.6945 1.05498 22.3385 1.94424 20.3764V20.0699H2.08885C2.26853 19.7327 2.48952 19.4113 2.74607 19.1091H1.94424V14.4052H7.41819L8.37903 13.4444H1.94424V7.6087H11.1794V10.6445L12.1403 9.68365V2.99135C12.1403 1.88398 11.2611 0.983185 10.1806 0.983185H2.94304C1.86257 0.983185 0.983398 1.88638 0.983398 2.99664V22.2385C0.983398 23.8239 1.62524 25.3551 2.74463 26.4408C3.78667 27.4507 5.13569 28.0003 6.56063 28.0003C6.6178 28.0003 6.67545 27.9993 6.7331 27.9979C7.33555 27.9791 7.91398 27.8513 8.45542 27.638H7.67954L7.68002 27.6375ZM1.94424 2.99664C1.94424 2.41629 2.39248 1.94451 2.94304 1.94451H10.1801C10.7307 1.94451 11.1789 2.41437 11.1789 2.99183V6.64833H1.94424V2.99664Z" fill="#75787B"/>
                        </svg>

                        <span class="ml-2">{{ tab.label }}</span>
                    </bxs-chip>
                </bxs-chip-group> -->

                <!-- tabs -->
                <bxs-carousel
                v-if="status.value === 'ok'"
                :items="tabs_items"
                item-width="48px"
                :items-space-between="12"
                class="mb-ui">
                    <template #item="{ item, index }">
                        <bxs-card
                        ratio="1-1"
                        :color="item.completed ? 'primary' : null"
                        :disabled="item.disabled"
                        :actived="tabs.index === index"
                        :elevation="tabs.index === index"
                        @click="toStep(index)">
                            <div
                            class="flex nowrap align-center justify-center"
                            style="height: 100%;">
                                <svg
                                v-if="item.value === 'material.color'"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.9918 28.0003H6.96421C3.76026 28.0003 1.07854 25.5746 0.985821 22.5936C0.9397 21.1048 1.49315 19.6885 2.54335 18.6051C3.62863 17.4857 5.16022 16.8434 6.74561 16.8434H25.987C27.0973 16.8434 28 17.7226 28 18.803V26.0401C28 27.1206 27.0992 27.9998 25.9918 27.9998V28.0003ZM6.74561 17.8042C5.41869 17.8042 4.13836 18.3399 3.23276 19.2739C2.36464 20.1689 1.90775 21.3378 1.94619 22.5638C2.02257 25.0317 4.27384 27.0394 6.96373 27.0394H25.9918C26.5693 27.0394 27.0392 26.5912 27.0392 26.0406V18.8035C27.0392 18.253 26.5674 17.8047 25.987 17.8047H6.74561V17.8042Z" fill="#75787B"/>
                                    <path d="M6.96437 27.5198C4.07222 27.5198 1.55336 25.369 1.46641 22.5787C1.39723 20.3505 2.81255 18.437 4.82649 17.6774L4.92305 17.5808L4.96821 17.626C5.32036 17.5045 5.68885 17.417 6.07078 17.3699L5.60237 16.9015L8.92834 13.5756L12.6766 17.3238H14.0352L9.60766 12.8962L13.7345 8.7694L20.2649 15.2998L18.2404 17.3243H19.599L24.2092 12.7142C24.5873 12.3361 24.7996 11.8379 24.8078 11.3108C24.8164 10.779 24.6156 10.2808 24.2428 9.90849L19.1258 4.7915C18.3614 4.02715 17.1013 4.04396 16.3163 4.82897L13.3948 7.75043L2.71022 18.435C1.5894 19.5554 0.960041 21.0923 0.984062 22.6512C1.0076 24.1602 1.61774 25.553 2.7035 26.5729C3.71623 27.5246 5.04172 27.9935 6.39747 27.9935C7.18536 27.9935 7.98335 27.8336 8.73665 27.5198H6.96437ZM16.9951 5.50781C17.207 5.29594 17.4842 5.18881 17.7552 5.18881C18.0083 5.18881 18.2577 5.28201 18.446 5.47034L23.563 10.5873C23.9526 10.9769 23.9372 11.626 23.5294 12.0344L20.9437 14.62L14.4133 8.0896L16.9951 5.50781Z" fill="#75787B"/>
                                    <path d="M9.39424 27.8009C9.12857 27.8009 8.91382 27.5861 8.91382 27.3205V17.3238C8.91382 17.0581 9.12857 16.8434 9.39424 16.8434C9.65992 16.8434 9.87466 17.0581 9.87466 17.3238V27.3205C9.87466 27.5861 9.65992 27.8009 9.39424 27.8009Z" fill="#75787B"/>
                                    <path d="M15.0583 27.8009C14.7926 27.8009 14.5779 27.5861 14.5779 27.3205V17.3238C14.5779 17.0581 14.7926 16.8434 15.0583 16.8434C15.324 16.8434 15.5387 17.0581 15.5387 17.3238V27.3205C15.5387 27.5861 15.324 27.8009 15.0583 27.8009Z" fill="#75787B"/>
                                    <path d="M21.8554 27.8009C21.5897 27.8009 21.375 27.5861 21.375 27.3205V17.3238C21.375 17.0581 21.5897 16.8434 21.8554 16.8434C22.1211 16.8434 22.3358 17.0581 22.3358 17.3238V27.3205C22.3358 27.5861 22.1211 27.8009 21.8554 27.8009Z" fill="#75787B"/>
                                    <path d="M5.4287 24.0353C4.53895 24.0353 3.81592 23.3113 3.81592 22.4221C3.81592 21.5328 4.53944 20.8088 5.4287 20.8088C6.31796 20.8088 7.04196 21.5328 7.04196 22.4221C7.04196 23.3113 6.31796 24.0353 5.4287 24.0353ZM5.4287 21.7692C5.06934 21.7692 4.77676 22.0618 4.77676 22.4216C4.77676 22.7814 5.06934 23.074 5.4287 23.074C5.78805 23.074 6.08111 22.7814 6.08111 22.4216C6.08111 22.0618 5.78854 21.7692 5.4287 21.7692Z" fill="#75787B"/>
                                    <path d="M7.68002 27.6375V27.3632C6.04466 27.7567 4.29447 27.4084 3.0324 26.2222C1.40665 24.6945 1.05498 22.3385 1.94424 20.3764V20.0699H2.08885C2.26853 19.7327 2.48952 19.4113 2.74607 19.1091H1.94424V14.4052H7.41819L8.37903 13.4444H1.94424V7.6087H11.1794V10.6445L12.1403 9.68365V2.99135C12.1403 1.88398 11.2611 0.983185 10.1806 0.983185H2.94304C1.86257 0.983185 0.983398 1.88638 0.983398 2.99664V22.2385C0.983398 23.8239 1.62524 25.3551 2.74463 26.4408C3.78667 27.4507 5.13569 28.0003 6.56063 28.0003C6.6178 28.0003 6.67545 27.9993 6.7331 27.9979C7.33555 27.9791 7.91398 27.8513 8.45542 27.638H7.67954L7.68002 27.6375ZM1.94424 2.99664C1.94424 2.41629 2.39248 1.94451 2.94304 1.94451H10.1801C10.7307 1.94451 11.1789 2.41437 11.1789 2.99183V6.64833H1.94424V2.99664Z" fill="#75787B"/>
                                </svg>

                                <svg
                                v-else-if="item.value === 'shape'"
                                viewBox="0 0 33 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.2598 33.5108C31.1976 33.5108 31.1348 33.5014 31.0738 33.4826L11.9913 27.5616C11.7847 27.4976 11.6251 27.3317 11.5698 27.1226C11.5146 26.9134 11.5698 26.6904 11.7181 26.5327L23.6234 13.8065C23.771 13.6488 23.9896 13.5766 24.2025 13.6193C24.4148 13.6601 24.5907 13.8084 24.6686 14.01L31.8458 32.6572C31.9325 32.8814 31.8829 33.1365 31.7183 33.3123C31.5977 33.4411 31.4306 33.5108 31.2598 33.5108ZM13.3205 26.659L30.2076 31.899L23.8564 15.3969L13.3211 26.659H13.3205Z" fill="#75787B"/>
                                    <path d="M16.9224 22.517C16.8357 22.517 16.749 22.4994 16.6661 22.4624C12.5002 20.6005 9.80859 16.456 9.80859 11.9026C9.80859 5.52373 14.999 0.333344 21.3778 0.333344C27.7567 0.333344 32.947 5.52373 32.947 11.9026C32.947 16.0188 30.724 19.8581 27.1455 21.9221C26.9859 22.0139 26.795 22.0321 26.6216 21.9699C26.4488 21.9083 26.3113 21.7745 26.2447 21.6037L23.8552 15.3963L17.3809 22.3179C17.2597 22.4473 17.092 22.5164 16.9224 22.5164V22.517ZM21.3778 1.59027C15.6912 1.59027 11.0649 6.21657 11.0649 11.9032C11.0649 15.8128 13.2923 19.3851 16.7766 21.1257L23.6228 13.8071C23.7704 13.6494 23.989 13.5772 24.202 13.6199C24.4143 13.6608 24.5902 13.809 24.6681 14.0106L27.1436 20.4429C29.9652 18.5333 31.6901 15.326 31.6901 11.9032C31.6901 6.21657 27.0638 1.59027 21.3772 1.59027H21.3778Z" fill="#75787B"/>
                                    <path d="M16.6726 37.6667H0.629124C0.281759 37.6667 0.000976562 37.3859 0.000976562 37.0385V10.5546C0.000976562 10.2072 0.281759 9.92642 0.629124 9.92642H10.5287C10.7084 9.92642 10.8805 10.0037 10.9992 10.1387C11.1179 10.2732 11.1738 10.4528 11.1519 10.6312C11.0928 11.1111 11.0652 11.515 11.0652 11.9026C11.0652 15.8448 13.3724 19.495 16.9434 21.2017C17.1613 21.3059 17.3002 21.5271 17.3002 21.7683V22.1558C17.3002 22.3154 17.2392 22.4686 17.1306 22.5855L13.3196 26.6584L16.8579 27.7564C17.1211 27.8381 17.3002 28.0812 17.3002 28.3563V37.0373C17.3002 37.3846 17.0194 37.6654 16.672 37.6654L16.6726 37.6667ZM1.25727 36.4104H16.0445V28.8199L11.9911 27.5623C11.7844 27.4982 11.6248 27.3324 11.5696 27.1232C11.5143 26.914 11.5696 26.6911 11.7178 26.5334L15.8893 22.0748C12.1776 20.0666 9.80887 16.1357 9.80887 11.9038C9.80887 11.6708 9.81766 11.434 9.83525 11.184H1.25727V36.4104Z" fill="#75787B"/>
                                </svg>

                                <svg
                                v-else-if="item.value === 'dimensions'"
                                viewBox="0 0 37 37"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32.116 18.0958C31.7667 18.0958 31.4844 17.8135 31.4844 17.4642V8.14019C31.4844 7.79092 31.7667 7.50861 32.116 7.50861C32.4652 7.50861 32.7475 7.79092 32.7475 8.14019V17.4642C32.7475 17.8135 32.4652 18.0958 32.116 18.0958Z" fill="#75787B"/>
                                    <path d="M27.9538 18.0951C27.6046 18.0951 27.3223 17.8128 27.3223 17.4636V13.1404C27.3223 12.7911 27.6046 12.5088 27.9538 12.5088C28.3031 12.5088 28.5854 12.7911 28.5854 13.1404V17.4636C28.5854 17.8128 28.3031 18.0951 27.9538 18.0951Z" fill="#75787B"/>
                                    <path d="M23.7917 18.0958C23.4425 18.0958 23.1602 17.8135 23.1602 17.4642V8.14019C23.1602 7.79092 23.4425 7.50861 23.7917 7.50861C24.141 7.50861 24.4233 7.79092 24.4233 8.14019V17.4642C24.4233 17.8135 24.141 18.0958 23.7917 18.0958Z" fill="#75787B"/>
                                    <path d="M19.6296 18.0743C19.2804 18.0743 18.998 17.792 18.998 17.4427V13.1189C18.998 12.7697 19.2804 12.4873 19.6296 12.4873C19.9789 12.4873 20.2612 12.7697 20.2612 13.1189V17.4427C20.2612 17.792 19.9789 18.0743 19.6296 18.0743Z" fill="#75787B"/>
                                    <path d="M15.4685 18.0958C15.1192 18.0958 14.8369 17.8135 14.8369 17.4642V8.14019C14.8369 7.79092 15.1192 7.50861 15.4685 7.50861C15.8178 7.50861 16.1001 7.79092 16.1001 8.14019V17.4642C16.1001 17.8135 15.8178 18.0958 15.4685 18.0958Z" fill="#75787B"/>
                                    <path d="M11.3064 18.0951C10.9571 18.0951 10.6748 17.8128 10.6748 17.4636V13.1404C10.6748 12.7911 10.9571 12.5088 11.3064 12.5088C11.6556 12.5088 11.938 12.7911 11.938 13.1404V17.4636C11.938 17.8128 11.6556 18.0951 11.3064 18.0951Z" fill="#75787B"/>
                                    <path d="M7.14428 18.7495C6.79501 18.7495 6.5127 18.4672 6.5127 18.1179V8.20461C6.5127 7.85534 6.79501 7.57303 7.14428 7.57303C7.49354 7.57303 7.77586 7.85534 7.77586 8.20461V18.1179C7.77586 18.4672 7.49354 18.7495 7.14428 18.7495Z" fill="#75787B"/>
                                    <path d="M19.6189 27.5189C19.2696 27.5189 18.9873 27.2366 18.9873 26.8873V17.7332C18.9873 17.384 19.2696 17.1017 19.6189 17.1017C19.9681 17.1017 20.2505 17.384 20.2505 17.7332V26.8873C20.2505 27.2366 19.9681 27.5189 19.6189 27.5189Z" fill="#75787B"/>
                                    <path d="M1.30053 27.5189C0.951262 27.5189 0.668945 27.2366 0.668945 26.8874V10.4928C0.668945 5.07511 5.00474 0.666687 10.3346 0.666687H33.9936C35.4677 0.666687 36.6665 1.86605 36.6665 3.34079V15.6939C36.6665 17.1686 35.4677 18.368 33.9936 18.368H10.5709C10.2216 18.368 9.93928 18.0857 9.93928 17.7364C9.93928 17.3871 10.2216 17.1048 10.5709 17.1048H33.9936C34.7711 17.1048 35.4033 16.472 35.4033 15.6939V3.34079C35.4033 2.56269 34.7711 1.92985 33.9936 1.92985H10.3346C5.70138 1.92985 1.93211 5.77112 1.93211 10.4928V26.8874C1.93211 27.2366 1.64979 27.5189 1.30053 27.5189Z" fill="#75787B"/>
                                    <path d="M10.4567 31.8409C7.75919 31.8409 5.56445 29.6139 5.56445 26.8766C5.56445 24.1394 7.75919 21.9131 10.4567 21.9131C13.1542 21.9131 15.3533 24.1394 15.3533 26.8766C15.3533 29.6139 13.1567 31.8409 10.4567 31.8409ZM10.4567 23.1756C8.45583 23.1756 6.82761 24.836 6.82761 26.876C6.82761 28.916 8.45583 30.5771 10.4567 30.5771C12.4575 30.5771 14.0902 28.9166 14.0902 26.876C14.0902 24.8354 12.46 23.1756 10.4567 23.1756Z" fill="#75787B"/>
                                    <path d="M10.454 36.6668C5.05711 36.6668 0.666992 32.2747 0.666992 26.8754C0.666992 21.476 5.05711 17.084 10.454 17.084C15.8508 17.084 20.251 21.476 20.251 26.8754C20.251 32.2747 15.8559 36.6668 10.454 36.6668ZM10.454 18.3471C5.75437 18.3471 1.93015 22.1733 1.93015 26.8754C1.93015 31.5775 5.75437 35.4036 10.454 35.4036C15.1535 35.4036 18.9879 31.5775 18.9879 26.8754C18.9879 22.1733 15.1592 18.3471 10.454 18.3471Z" fill="#75787B"/>
                                </svg>

                                <svg
                                v-else-if="item.value === 'border.id'"
                                viewBox="0 0 37 37"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path d="M36.0356 36.6742H1.29968C0.950344 36.6742 0.667969 36.3919 0.667969 36.0425V1.34387C0.667969 0.994533 0.950344 0.712158 1.29968 0.712158H36.0356C36.385 0.712158 36.6673 0.994533 36.6673 1.34387V36.0425C36.6673 36.3919 36.385 36.6742 36.0356 36.6742ZM1.93139 35.4108H35.4039V1.97558H1.93139V35.4108Z" fill="#75787B"/>
                                    <path d="M36.0356 36.666H1.29968C0.950344 36.666 0.667969 36.3836 0.667969 36.0343C0.667969 35.685 0.950344 35.4026 1.29968 35.4026H35.4039V1.29837C35.4039 0.949032 35.6863 0.666656 36.0356 0.666656C36.385 0.666656 36.6673 0.949032 36.6673 1.29837V36.0343C36.6673 36.3836 36.385 36.666 36.0356 36.666Z" fill="#75787B"/>
                                    <path d="M1.2987 25.697C0.949367 25.697 0.666992 25.4146 0.666992 25.0653C0.666992 24.7159 0.949367 24.4335 1.2987 24.4335H24.4339V1.29837C24.4339 0.949032 24.7163 0.666656 25.0656 0.666656C25.4149 0.666656 25.6973 0.949032 25.6973 1.29837V25.0646C25.6973 25.414 25.4149 25.6963 25.0656 25.6963H1.2987V25.697Z" fill="#75787B"/>
                                    <path d="M35.3711 36.0103C35.2094 36.0103 35.0477 35.9484 34.9245 35.8258L24.3825 25.2965C24.1355 25.0495 24.1355 24.6496 24.3825 24.4026C24.6295 24.1556 25.0294 24.1562 25.2764 24.4026L35.8184 34.9319C36.0654 35.1789 36.0654 35.5788 35.8184 35.8258C35.6952 35.949 35.5329 36.0109 35.3711 36.0109V36.0103Z" fill="#75787B"/>
                                </svg>

                                <svg
                                v-else-if="item.value === 'border.color'"
                                viewBox="0 0 37 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.9038 1.77894V24.5545H2.21797V1.77894H25.9038ZM27.1491 0.557373H1.64637C1.27402 0.557373 0.972656 0.852993 0.972656 1.21824V25.7761H27.1491V0.557373Z" fill="#75787B"/>
                                    <path d="M1.26655 24.1953H24.3634C24.4356 24.1953 24.5053 24.2216 24.5595 24.2692L35.8744 34.2238C36.1141 34.4345 35.9622 34.8248 35.6397 34.8248H1.26655C1.10404 34.8242 0.972656 34.6953 0.972656 34.5359V24.4836C0.972656 24.3242 1.10404 24.1953 1.26655 24.1953Z" fill="#B3B6B8"/>
                                    <path d="M24.4248 0.557373V24.1953L35.9925 34.3289C36.2111 34.5207 36.5579 34.368 36.5579 34.0809V0.557373H24.4248Z" fill="#9C9D9E"/>
                                </svg>

                                <svg
                                v-else-if="item.value === 'border.thread.id'"
                                viewBox="0 0 37 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.9038 1.77894V24.5545H2.21797V1.77894H25.9038ZM27.1491 0.557373H1.64637C1.27402 0.557373 0.972656 0.852993 0.972656 1.21824V25.7761H27.1491V0.557373Z" fill="#75787B"/>
                                    <path d="M1.26655 24.1953H24.3634C24.4356 24.1953 24.5053 24.2216 24.5595 24.2692L35.8744 34.2238C36.1141 34.4345 35.9622 34.8248 35.6397 34.8248H1.26655C1.10404 34.8242 0.972656 34.6953 0.972656 34.5359V24.4836C0.972656 24.3242 1.10404 24.1953 1.26655 24.1953Z" fill="#B3B6B8"/>
                                    <path d="M24.4248 0.557373V24.1953L35.9925 34.3289C36.2111 34.5207 36.5579 34.368 36.5579 34.0809V0.557373H24.4248Z" fill="#9C9D9E"/>
                                </svg>

                                <svg
                                v-else-if="item.value === 'border.thread.color'"
                                viewBox="0 0 37 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.9038 1.77894V24.5545H2.21797V1.77894H25.9038ZM27.1491 0.557373H1.64637C1.27402 0.557373 0.972656 0.852993 0.972656 1.21824V25.7761H27.1491V0.557373Z" fill="#75787B"/>
                                    <path d="M1.26655 24.1953H24.3634C24.4356 24.1953 24.5053 24.2216 24.5595 24.2692L35.8744 34.2238C36.1141 34.4345 35.9622 34.8248 35.6397 34.8248H1.26655C1.10404 34.8242 0.972656 34.6953 0.972656 34.5359V24.4836C0.972656 24.3242 1.10404 24.1953 1.26655 24.1953Z" fill="#B3B6B8"/>
                                    <path d="M24.4248 0.557373V24.1953L35.9925 34.3289C36.2111 34.5207 36.5579 34.368 36.5579 34.0809V0.557373H24.4248Z" fill="#9C9D9E"/>
                                </svg>

                                <svg
                                v-else
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.9918 28.0003H6.96421C3.76026 28.0003 1.07854 25.5746 0.985821 22.5936C0.9397 21.1048 1.49315 19.6885 2.54335 18.6051C3.62863 17.4857 5.16022 16.8434 6.74561 16.8434H25.987C27.0973 16.8434 28 17.7226 28 18.803V26.0401C28 27.1206 27.0992 27.9998 25.9918 27.9998V28.0003ZM6.74561 17.8042C5.41869 17.8042 4.13836 18.3399 3.23276 19.2739C2.36464 20.1689 1.90775 21.3378 1.94619 22.5638C2.02257 25.0317 4.27384 27.0394 6.96373 27.0394H25.9918C26.5693 27.0394 27.0392 26.5912 27.0392 26.0406V18.8035C27.0392 18.253 26.5674 17.8047 25.987 17.8047H6.74561V17.8042Z" fill="#75787B"/>
                                    <path d="M6.96437 27.5198C4.07222 27.5198 1.55336 25.369 1.46641 22.5787C1.39723 20.3505 2.81255 18.437 4.82649 17.6774L4.92305 17.5808L4.96821 17.626C5.32036 17.5045 5.68885 17.417 6.07078 17.3699L5.60237 16.9015L8.92834 13.5756L12.6766 17.3238H14.0352L9.60766 12.8962L13.7345 8.7694L20.2649 15.2998L18.2404 17.3243H19.599L24.2092 12.7142C24.5873 12.3361 24.7996 11.8379 24.8078 11.3108C24.8164 10.779 24.6156 10.2808 24.2428 9.90849L19.1258 4.7915C18.3614 4.02715 17.1013 4.04396 16.3163 4.82897L13.3948 7.75043L2.71022 18.435C1.5894 19.5554 0.960041 21.0923 0.984062 22.6512C1.0076 24.1602 1.61774 25.553 2.7035 26.5729C3.71623 27.5246 5.04172 27.9935 6.39747 27.9935C7.18536 27.9935 7.98335 27.8336 8.73665 27.5198H6.96437ZM16.9951 5.50781C17.207 5.29594 17.4842 5.18881 17.7552 5.18881C18.0083 5.18881 18.2577 5.28201 18.446 5.47034L23.563 10.5873C23.9526 10.9769 23.9372 11.626 23.5294 12.0344L20.9437 14.62L14.4133 8.0896L16.9951 5.50781Z" fill="#75787B"/>
                                    <path d="M9.39424 27.8009C9.12857 27.8009 8.91382 27.5861 8.91382 27.3205V17.3238C8.91382 17.0581 9.12857 16.8434 9.39424 16.8434C9.65992 16.8434 9.87466 17.0581 9.87466 17.3238V27.3205C9.87466 27.5861 9.65992 27.8009 9.39424 27.8009Z" fill="#75787B"/>
                                    <path d="M15.0583 27.8009C14.7926 27.8009 14.5779 27.5861 14.5779 27.3205V17.3238C14.5779 17.0581 14.7926 16.8434 15.0583 16.8434C15.324 16.8434 15.5387 17.0581 15.5387 17.3238V27.3205C15.5387 27.5861 15.324 27.8009 15.0583 27.8009Z" fill="#75787B"/>
                                    <path d="M21.8554 27.8009C21.5897 27.8009 21.375 27.5861 21.375 27.3205V17.3238C21.375 17.0581 21.5897 16.8434 21.8554 16.8434C22.1211 16.8434 22.3358 17.0581 22.3358 17.3238V27.3205C22.3358 27.5861 22.1211 27.8009 21.8554 27.8009Z" fill="#75787B"/>
                                    <path d="M5.4287 24.0353C4.53895 24.0353 3.81592 23.3113 3.81592 22.4221C3.81592 21.5328 4.53944 20.8088 5.4287 20.8088C6.31796 20.8088 7.04196 21.5328 7.04196 22.4221C7.04196 23.3113 6.31796 24.0353 5.4287 24.0353ZM5.4287 21.7692C5.06934 21.7692 4.77676 22.0618 4.77676 22.4216C4.77676 22.7814 5.06934 23.074 5.4287 23.074C5.78805 23.074 6.08111 22.7814 6.08111 22.4216C6.08111 22.0618 5.78854 21.7692 5.4287 21.7692Z" fill="#75787B"/>
                                    <path d="M7.68002 27.6375V27.3632C6.04466 27.7567 4.29447 27.4084 3.0324 26.2222C1.40665 24.6945 1.05498 22.3385 1.94424 20.3764V20.0699H2.08885C2.26853 19.7327 2.48952 19.4113 2.74607 19.1091H1.94424V14.4052H7.41819L8.37903 13.4444H1.94424V7.6087H11.1794V10.6445L12.1403 9.68365V2.99135C12.1403 1.88398 11.2611 0.983185 10.1806 0.983185H2.94304C1.86257 0.983185 0.983398 1.88638 0.983398 2.99664V22.2385C0.983398 23.8239 1.62524 25.3551 2.74463 26.4408C3.78667 27.4507 5.13569 28.0003 6.56063 28.0003C6.6178 28.0003 6.67545 27.9993 6.7331 27.9979C7.33555 27.9791 7.91398 27.8513 8.45542 27.638H7.67954L7.68002 27.6375ZM1.94424 2.99664C1.94424 2.41629 2.39248 1.94451 2.94304 1.94451H10.1801C10.7307 1.94451 11.1789 2.41437 11.1789 2.99183V6.64833H1.94424V2.99664Z" fill="#75787B"/>
                                </svg>
                            </div>
                        </bxs-card>

                        <div class="text-center bxs-truncate">
                            <small
                            :class="{ 'text-disabled': item.disabled }"
                            style="font-size: 12px;">{{ item.label }}</small>
                        </div>
                    </template>
                </bxs-carousel>

                <div class="mb-ui">
                    <p>Progress: {{ progress }}%</p>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    tabs content
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <div v-if="status && status.value === 'ko'">
                    <div class="mb-ui">
                        <h6 class="mb-0">Questo configuratore non è al momento disponibile.</h6>
                        <small>Continua la ricerca e visualizza altri prodotti.</small>
                    </div>

                    <bxs-btn
                    block
                    outlined>Segnala</bxs-btn>
                </div>

                <!-- <div
                v-else-if="configuration_status && configuration_status.value === 'ko'"
                class="text-center py-ui">
                    <p>La tua configurazione non è al momento disponibile.</p>
                    <small>Contatta l'assistenza o visualizza altri prodotti.</small>
                </div> -->

                <div v-else>
                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        color
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <div v-show="currentTabKey === 'material.color'">
                        <!-- @select="nextStep" -->
                        <configurator-select
                        v-model="model.material.color"
                        :items="item.material.colors"
                        title="Scegli il colore" />
                    </div>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        backsides
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <div
                    v-if="item.backsides && item.backsides.length > 0"
                    v-show="currentTabKey === 'material.backside'">
                        <configurator-select
                        v-model="model.material.backside"
                        :items="item.backsides"
                        title="Scegli il sottofondo" />
                    </div>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        inlay
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <div
                    v-if="item.inlay"
                    v-show="currentTabKey === 'inlay'">
                        <ul>
                            <li
                            v-for="(n, i) in item.inlay.colors_selectable"
                            :key="i">
                                <!-- {{ n }}-{{ i }} - {{ item.inlay.colors_selectable }} -->
                                <configurator-select
                                v-model="model.inlay['material_color_' + n]"
                                :items="item.inlay.materials_colors"
                                :title="'Scegli il colore ' + n" />
                            </li>
                        </ul>
                    </div>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        graphics
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <div
                    v-if="item.graphics"
                    v-show="currentTabKey === 'graphic'">
                        <configurator-select
                        v-model="model.graphic"
                        :items="item.graphics"
                        title="Scegli la grafica"
                        :image-is-avatar="false" />
                    </div>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        shape
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <div
                    v-if="item.shapes"
                    v-show="currentTabKey === 'shape'">
                        <configurator-select
                        v-model="model.shape"
                        :items="item.shapes"
                        title="Scegli la forma"
                        :image-is-avatar="false" />
                    </div>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        dimensions
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <div v-show="currentTabKey === 'dimensions'">
                        <configurator-dimensions
                        v-model="model.dimensions"
                        v-model:inlay-orientation="model.inlay.orientation"
                        title="Scegli le misure"
                        :limits="limits"
                        :sizes="selectedMaterial ? selectedMaterial.sizes : []"
                        :has-variable-width="item.material.has_variable_width"
                        :size="model.material.size"
                        :type="item.material.type"
                        :shape="selectedShape"
                        :shape-side="selectedShape ? selectedShape.side : null"
                        :shape-ratio="selectedShape ? selectedShape.ratio : null"
                        :inlay="item.inlay"
                        @save="nextStep" />
                    </div>

                    <!-- borders -->
                    <div
                    v-if="borders.length > 0"
                    v-show="currentTabKey === 'border.id'">
                        <configurator-select
                        v-model="model.border.id"
                        :items="borders"
                        title="Scegli il tipo di bordo" />
                    </div>

                    <!-- borders_colors -->
                    <div
                    v-if="selectedBorder && selectedBorder.colors.length > 0"
                    v-show="currentTabKey === 'border.color'">
                        <configurator-select
                        v-model="model.border.color"
                        :items="selectedBorder.colors"
                        title="Scegli il colore del bordo" />
                    </div>

                    <!-- borders_threads -->
                    <div
                    v-if="selectedBorder && selectedBorder.threads && selectedBorder.threads.length > 0"
                    v-show="currentTabKey === 'border.thread.id'">
                        <configurator-select
                        v-model="model.border.thread.id"
                        :items="selectedBorder.threads"
                        title="Scegli il tipo di bordo filo" />
                    </div>

                    <!-- borders_threads_colors -->
                    <div
                    v-if="selectedBorderThread && selectedBorderThread.colors && selectedBorderThread.colors.length > 0"
                    v-show="currentTabKey === 'border.thread.color'">
                        <configurator-select
                        v-model="model.border.thread.color"
                        :items="selectedBorderThread.colors"
                        title="Scegli il colore del bordo filo"
                        @select="nextStep" />
                    </div>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------
                        recap
                        ------------------------------------------------------------------------------------------------------------------------------------------------
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <div v-show="currentTabKey === 'recap'">
                        <!-- <bxs-btn
                        outlined
                        block
                        @click="summary.on = true">Visualizza riepilogo</bxs-btn> -->

                        <div>
                            <div class="mb-ui">
                                <h3 class="text-700 mb-1">{{ product.name }}</h3>
                                <p class="text-500 mb-0">{{ $filters.toHumanPrice(summary.cashier.sell) }}</p>
                            </div>

                            <div>
                                <bxs-list>
                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        color
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <bxs-list-item
                                    v-if="selectedMaterial"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('material.color')">
                                        <small class="text-600">Colore:</small>
                                        <bxs-avatar
                                        class="mx-3"
                                        small
                                        :src="selectedMaterial.img ? selectedMaterial.img.url : null" />
                                        <small>{{ selectedMaterial.name }}</small>
                                    </bxs-list-item>

                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        inlay
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <bxs-list-item
                                    v-if="selectedInlayMaterialColors && selectedInlayMaterialColors.material_color_1"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('inlay')">
                                        <small class="text-600">Intarsio colore 1:</small>
                                        <bxs-avatar
                                        small
                                        :src="selectedInlayMaterialColors.material_color_1.img ? selectedInlayMaterialColors.material_color_1.img.url : null"
                                        class="mx-3" />
                                        <small>{{ selectedInlayMaterialColors.material_color_1.name }}</small>
                                    </bxs-list-item>

                                    <bxs-list-item
                                    v-if="selectedInlayMaterialColors && selectedInlayMaterialColors.material_color_2"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('inlay')">
                                        <small class="text-600">Intarsio colore 2:</small>
                                        <bxs-avatar
                                        small
                                        :src="selectedInlayMaterialColors.material_color_2.img ? selectedInlayMaterialColors.material_color_2.img.url : null"
                                        class="mx-3" />
                                        <small>{{ selectedInlayMaterialColors.material_color_2.name }}</small>
                                    </bxs-list-item>

                                    <bxs-list-item
                                    v-if="selectedInlayMaterialColors && selectedInlayMaterialColors.material_color_3"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('inlay')">
                                        <small class="text-600">Intarsio colore 3:</small>
                                        <bxs-avatar
                                        small
                                        :src="selectedInlayMaterialColors.material_color_3.img ? selectedInlayMaterialColors.material_color_3.img.url : null"
                                        class="mx-3" />
                                        <small>{{ selectedInlayMaterialColors.material_color_3.name }}</small>
                                    </bxs-list-item>

                                    <bxs-list-item
                                    v-if="selectedInlayMaterialColors && selectedInlayMaterialColors.material_color_4"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('inlay')">
                                        <small class="text-600">Intarsio colore 4:</small>
                                        <bxs-avatar
                                        small
                                        :src="selectedInlayMaterialColors.material_color_4.img ? selectedInlayMaterialColors.material_color_4.img.url : null"
                                        class="mx-3" />
                                        <small>{{ selectedInlayMaterialColors.material_color_4.name }}</small>
                                    </bxs-list-item>

                                    <bxs-list-item
                                    v-if="selectedInlayMaterialColors && selectedInlayMaterialColors.material_color_5"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('inlay')">
                                        <small class="text-600">Intarsio colore 5:</small>
                                        <bxs-avatar
                                        small
                                        :src="selectedInlayMaterialColors.material_color_5.img ? selectedInlayMaterialColors.material_color_5.img.url : null"
                                        class="mx-3" />
                                        <small>{{ selectedInlayMaterialColors.material_color_5.name }}</small>
                                    </bxs-list-item>

                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        material
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <bxs-list-item
                                    v-if="selectedBackside"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('material.backside')">
                                        <small class="text-600">Sottofondo:</small>
                                        <bxs-avatar
                                        class="mx-3"
                                        small
                                        :src="selectedBackside && selectedBackside.img && selectedBackside.img.url ? selectedBackside.img.url : null" />
                                        <small>{{ selectedBackside && selectedBackside.name ? selectedBackside.name : '--' }}</small>
                                    </bxs-list-item>

                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        shape
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <bxs-list-item
                                    v-if="selectedShape"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('shape')">
                                        <small class="text-600">Forma:</small>
                                        <bxs-figure
                                        class="mx-3"
                                        max-width="36"
                                        :src="selectedShape.img ? selectedShape.img.url : null" />
                                        <small>{{ selectedShape.name }}</small>
                                    </bxs-list-item>

                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        graphic
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <!-- <bxs-list-item
                                    v-if="selectedShape"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('border.id')">
                                        <small class="text-600">Grafica:</small>
                                        <p>Continua la configruazione, nella tua personale potrai caricare il file.</p>
                                    </bxs-list-item> -->

                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        dimension
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <bxs-list-item
                                    v-if="model.dimensions.a && model.dimensions.b"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('dimensions')">
                                        <small class="text-600">Misure:</small>
                                        <small class="ml-3">
                                            <span>{{ model.dimensions.a || 0 }}</span>
                                            <span class="mx-1">x</span>
                                            <span>{{ model.dimensions.b || 0 }}</span>
                                            <span class="ml-1">cm</span>
                                        </small>
                                    </bxs-list-item>

                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        border
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <bxs-list-item
                                    v-if="selectedBorder"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('border.id')">
                                        <small class="text-600">Bordo:</small>
                                        <bxs-avatar
                                        class="mx-2"
                                        small
                                        :src="selectedBorder.img ? selectedBorder.img.url : null" />
                                        <small>{{ selectedBorder.name }}</small>
                                    </bxs-list-item>

                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        border color
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <bxs-list-item
                                    v-if="selectedBorderColor"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('border.color')">
                                        <small class="text-600">Colore bordo:</small>
                                        <bxs-avatar
                                        class="mx-2"
                                        small
                                        :src="selectedBorderColor.img ? selectedBorderColor.img.url : null" />
                                        <small>{{ selectedBorderColor.name }}</small>
                                    </bxs-list-item>

                                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                                        border thread
                                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                                    <bxs-list-item
                                    v-if="selectedBorderThread"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('border.thread.id')">
                                        <small class="text-600">Filo bordo:</small>
                                        <bxs-avatar
                                        class="mx-2"
                                        small
                                        :src="selectedBorderThread.img ? selectedBorderThread.img.url : null" />
                                        <small>{{ selectedBorderThread.name }}</small>
                                    </bxs-list-item>

                                    <bxs-list-item
                                    v-if="selectedBorderThreadColor"
                                    spacer
                                    border
                                    append-icon="edit"
                                    @click="toStep('border.thread.color')">
                                        <small class="text-600">Colore filo bordo:</small>
                                        <bxs-avatar
                                        class="mx-2"
                                        small
                                        :src="selectedBorderThreadColor.img ? selectedBorderThreadColor.img.url : null" />
                                        <small>{{ selectedBorderThreadColor.name }}</small>
                                    </bxs-list-item>
                                </bxs-list>
                            </div>
                        </div>

                        <div class="mt-ui">
                            <bxs-alert
                            v-if="summary.error"
                            type="warn"
                            class="mb-ui">
                                <p class="mb-1">{{ summary.error }}</p>
                                <p>Ops, qualcosa è andato storto, riprova più tardi o prosegui con gli step della configurazione</p>
                            </bxs-alert>

                            <div class="mb-ui">
                                <bxs-btn
                                :disabled="!is_addable_to_cart"
                                block
                                color="primary"
                                @click="addToCart">Aggiungi al carrello</bxs-btn>
                            </div>

                            <div
                            class="toolbar flex nowrap align-center justify-between"
                            style="height: 100%;">
                                <button
                                class="flex-1 flex column nowrap align-center text-center"
                                @click="saveInProfile">
                                    <bxs-icon
                                    name="heart"
                                    width="1em"
                                    height="1em"
                                    class="mb-1" />

                                    <small style="font-size: 10px;">salva</small>
                                </button>

                                <button
                                class="flex-1 flex column nowrap align-center text-center"
                                @click="reset">
                                    <bxs-icon
                                    name="refresh"
                                    width="1em"
                                    height="1em"
                                    class="mb-1" />

                                    <small style="font-size: 10px;">ricomincia</small>
                                </button>

                                <button
                                class="flex-1 flex column nowrap align-center text-center"
                                @click="createShare">
                                    <bxs-icon
                                    name="share"
                                    width="1em"
                                    height="1em"
                                    class="mb-1" />

                                    <small style="font-size: 10px;">condividi</small>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            footer
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <div
        v-if="!is.preload"
        class="product-configurator-view--footer">
        </div>

        <div
        v-else-if="is.preload && !is.error && status.value === 'ok'"
        class="product-configurator-view--footer">
            <div>
                <button
                v-if="prev_tab"
                :disabled="prev_tab && prev_tab.disabled"
                :class="{ 'bxs-disabled': prev_tab && prev_tab.disabled }"
                @click.prevent="prevStep">
                    <bxs-icon
                    name="chevron-left"
                    width="10px"
                    height="10px"
                    class="mr-2" />
                    <small>{{ prev_tab.label }}</small>
                </button>
            </div>

            <div>
                <svg
                v-if="summary.loading"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 50 50"
                style="enable-background:new 0 0 50 50;"
                xml:space="preserve">
                    <path
                    fill="currentColor"
                    d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                        <animateTransform
                        attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0 25 25"
                        to="360 25 25"
                        dur="0.6s"
                        repeatCount="indefinite" />
                    </path>
                </svg>

                <span
                v-else
                class="text-700 bxs-pointer"
                @click="summary.on = true">{{ $filters.toHumanPrice(summary.cashier.sell) }}</span>
            </div>

            <div>
                <button
                v-if="next_tab"
                :disabled="next_tab && next_tab.disabled"
                :class="{ 'bxs-disabled': next_tab && next_tab.disabled }"
                @click.prevent="nextStep">
                    <small>{{ next_tab.label }}</small>
                    <bxs-icon
                    name="chevron-right"
                    width="10px"
                    height="10px"
                    class="ml-2" />
                </button>
            </div>
        </div>
        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            end footer
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            recap menu
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-menu
        v-if="is.preload"
        v-model="summary.on"
        title="Riepilogo configurazione"
        min-height="95%"
        solo>
            <div class="pa-layout">
                <div class="mb-ui">
                    <h3 class="text-700 mb-2">{{ product.name }}</h3>
                    <p class="text-500 mb-0">{{ $filters.toHumanPrice(summary.cashier.sell) }}</p>
                </div>

                <div>
                    <bxs-list>
                        <bxs-list-item
                        v-if="selectedMaterial"
                        spacer
                        border
                        append-icon="edit"
                        @click="toStep('material.color')">
                            <small class="text-600">Colore:</small>
                            <bxs-avatar
                            class="mx-3"
                            small
                            :src="selectedMaterial.img ? selectedMaterial.img.url : null" />
                            <small>{{ selectedMaterial.name }}</small>
                        </bxs-list-item>

                        <bxs-list-item
                        v-if="selectedShape"
                        spacer
                        border
                        append-icon="edit"
                        @click="toStep('shape')">
                            <small class="text-600">Forma:</small>
                            <bxs-figure
                            class="mx-3"
                            max-width="36"
                            :src="selectedShape.img ? selectedShape.img.url : null" />
                            <small>{{ selectedShape.name }}</small>
                        </bxs-list-item>

                        <bxs-list-item
                        v-if="model.dimensions.a && model.dimensions.b"
                        spacer
                        border
                        append-icon="edit"
                        @click="toStep('dimensions')">
                            <small class="text-600">Misure:</small>
                            <small class="ml-3">
                                <span>{{ model.dimensions.a || 0 }} (A) cm</span>
                                <span> x {{ model.dimensions.b || 0 }} (B) cm</span>
                                <span v-if="model.dimensions.a1">{{ model.dimensions.a1 || 0 }} (A1) cm</span>
                                <span v-if="model.dimensions.b1"> x {{ model.dimensions.b1 || 0 }} (B1) cm</span>
                            </small>
                        </bxs-list-item>

                        <bxs-list-item
                        v-if="selectedBorder"
                        spacer
                        border
                        append-icon="edit"
                        @click="toStep('border.id')">
                            <small class="text-600">Bordo:</small>
                            <bxs-avatar
                            class="mx-2"
                            small
                            :src="selectedBorder.img ? selectedBorder.img.url : null" />
                            <small>{{ selectedBorder.name }}</small>
                        </bxs-list-item>

                        <bxs-list-item
                        v-if="selectedBorderColor"
                        spacer
                        border
                        append-icon="edit"
                        @click="toStep('border.color')">
                            <small class="text-600">Colore bordo:</small>
                            <bxs-avatar
                            class="mx-2"
                            small
                            :src="selectedBorderColor.img ? selectedBorderColor.img.url : null" />
                            <small>{{ selectedBorderColor.name }}</small>
                        </bxs-list-item>

                        <bxs-list-item
                        v-if="selectedBorderThread"
                        spacer
                        border
                        append-icon="edit"
                        @click="toStep('border.thread.id')">
                            <small class="text-600">Filo bordo:</small>
                            <bxs-avatar
                            class="mx-2"
                            small
                            :src="selectedBorderThread.img ? selectedBorderThread.img.url : null" />
                            <small>{{ selectedBorderThread.name }}</small>
                        </bxs-list-item>

                        <bxs-list-item
                        v-if="selectedBorderThreadColor"
                        spacer
                        border
                        append-icon="edit"
                        @click="toStep('border.thread.color')">
                            <small class="text-600">Colore filo bordo:</small>
                            <bxs-avatar
                            class="mx-2"
                            small
                            :src="selectedBorderThreadColor.img ? selectedBorderThreadColor.img.url : null" />
                            <small>{{ selectedBorderThreadColor.name }}</small>
                        </bxs-list-item>
                    </bxs-list>
                </div>
            </div>

            <template #actions>
                <div class="pa-layout">
                    <bxs-alert
                    v-if="summary.error"
                    type="warn"
                    class="mb-ui">
                        <p class="mb-1">{{ summary.error }}</p>
                        <p>Ops, qualcosa è andato storto, riprova più tardi o prosegui con gli step della configurazione</p>
                    </bxs-alert>

                    <div class="mb-ui">
                        <bxs-btn
                        :disabled="!is_addable_to_cart"
                        block
                        color="primary">Aggiungi al carrello</bxs-btn>
                    </div>

                    <div
                    class="toolbar flex nowrap align-center justify-between"
                    style="height: 100%;">
                        <button
                        class="flex-1 flex column nowrap align-center text-center"
                        @click="saveInProfile">
                            <bxs-icon
                            name="heart"
                            class="mb-1" />

                            <small style="font-size: 10px;">salva</small>
                        </button>

                        <button
                        class="flex-1 flex column nowrap align-center text-center"
                        @click="reset">
                            <bxs-icon
                            name="refresh"
                            class="mb-1" />

                            <small style="font-size: 10px;">ricomincia</small>
                        </button>

                        <button
                        class="flex-1 flex column nowrap align-center text-center"
                        @click="createShareLink">
                            <bxs-icon
                            name="share"
                            class="mb-1" />

                            <small style="font-size: 10px;">condividi</small>
                        </button>
                    </div>
                </div>
            </template>
        </bxs-menu>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            share menu
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-menu
        v-if="is.preload"
        v-model="share.on"
        title="Condividi"
        solo
        min-height="90%">
            <div class="pa-layout">
                <div class="mb-ui">
                    <div class="mb-2">
                        <h5 style="line-height: normal;">Condividi questa configurazione con amici e familiari</h5>
                    </div>

                    <bxs-figure
                    ratio="4-3"
                    class="mb-2" />

                    <div>
                        <div class="flex nowrap align-center justify-between">
                            <h5>{{ product.name }}</h5>
                            <p>{{ $filters.toHumanPrice(summary.cashier.sell) }}</p>
                        </div>
                    </div>
                </div>

                <div v-if="share.loading">
                    <bxs-loader></bxs-loader>
                </div>

                <div v-else>
                    <div v-if="!share.error">
                        <small class="mb-2">Copia e condividi il link</small>

                        <div class="flex nowrap align-center">
                            <div
                            class="flex-1 flex nowrap align-center bxs-truncate pl-2"
                            style="height: 47px; border: 1px solid #BDBDBD;">
                                <span>{{ share.url }}</span>
                            </div>

                            <bxs-btn
                            style="border-radius: 0;"
                            @click="copyShareLink">Copia</bxs-btn>
                        </div>
                    </div>

                    <bxs-alert
                    v-else
                    type="warn"
                    class="mb-ui">
                        <p class="mb-1">{{ share.error }}</p>
                        <p>Ops, qualcosa è andato storto, riprova più tardi o prosegui con gli step della configurazione prima di condividerla</p>
                    </bxs-alert>
                </div>
            </div>

            <template #actions>
                <div
                v-if="!share.loading && !share.error"
                class="toolbar flex nowrap align-center justify-between"
                style="height: 100%;">
                    <button class="flex-1 flex column nowrap align-center text-center">
                        <bxs-icon name="tw" class="mb-1" />
                        <small style="font-size: 10px;">Twitter</small>
                    </button>

                    <button
                    class="flex-1 flex column nowrap align-center text-center"
                    @click="reset">
                        <bxs-icon name="fb" class="mb-1" />
                        <small style="font-size: 10px;">Facebook</small>
                    </button>

                    <button class="flex-1 flex column nowrap align-center text-center">
                        <bxs-icon name="ig" class="mb-1" />
                        <small style="font-size: 10px;">Instagram</small>
                    </button>
                </div>
             </template>
        </bxs-menu>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            confirm exit menu
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-menu
        v-if="is.preload"
        v-model="dialog.on"
        title="Attenzione"
        solo>
            <div class="pa-layout">
                <h3>Attenzione!</h3>
                <p>Non hai salvato la configurazione, tutte le scelte fatte andranno perse</p>
            </div>

            <template #actions>
                <div class="flex nowrap align-center pa-layout">
                    <bxs-btn
                    block
                    outlined
                    class="mr-1"
                    @click="dialog.on = false">Annulla</bxs-btn>

                    <bxs-btn
                    block
                    class="ml-1"
                    @click="confirmExit">Ho capito</bxs-btn>
                </div>
             </template>
        </bxs-menu>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            confirm save
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-menu
        v-if="is.preload"
        v-model="modals.save.on"
        title="Salva"
        solo>
            <div class="pa-layout">
                <div v-if="!modals.save.completed">
                    <h4 class="mb-2">Salva la tua configurazione!</h4>
                    <p class="mb-ui">{{ !model.name ? 'Dai un nome alla tua configurazione' : 'Mantieni o sostituisci il nome della tua configurazione' }}</p>

                    <bxs-text-field
                    v-model="modals.save.name"
                    placeholder="Inserisci un nome"
                    solo />
                </div>

                <div v-else>
                    <h3 class="mb-2">Configurazione salvata!</h3>
                    <p class="mb-ui">Troverai la configurazione salvata nella tua area personale e potrai modificarla in qualsiasi momento</p>

                    <bxs-btn
                    to="/me"
                    block>Vai al tuo profilo</bxs-btn>
                </div>
            </div>

            <template #actions>
                <div
                v-if="!modals.save.completed"
                class="flex nowrap align-center pa-layout">
                    <bxs-btn
                    block
                    outlined
                    class="mr-1"
                    @click="modals.save.on = false">Annulla</bxs-btn>

                    <bxs-btn
                    block
                    class="ml-1"
                    @click="confirmSave">Salva</bxs-btn>
                </div>
             </template>
        </bxs-menu>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            conferma addToCart
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-menu
        v-if="is.preload"
        v-model="modals.add_to_cart.on"
        :title="modals.add_to_cart.title"
        solo>
        </bxs-menu>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            loading
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-loader
        v-if="is.preload && is.loading"
        fullscreen />
    </div>
</template>

<script>
import Select from '@/components/configurator/Select.vue'
import Dimensions from '@/components/configurator/Dimensions.vue'
import dot from 'dot-object'
import { isEqual } from 'lodash'
import html2canvas from 'html2canvas'

export default {
    name: 'product_configurator',
    components: {
        'configurator-select': Select,
        'configurator-dimensions': Dimensions
    },
    data () {
        return {
            key: 0,
            product: null,
            item: null,
            type: 'tappeto',
            id: null,
            configuration: null,
            isEditing: false,
            //
            status: {
                ref: null,
                value: null,
                errors: []
            },
            configuration_status: {
                ref: null,
                configuration: null,
                value: null,
                progress: null,
                errors: []
            },
            tabs: {
                index: 0,
                values: [],
                t: {
                    color: 'Colore',
                    backside: 'Sottofondo',
                    shape: 'Forma',
                    dimensions: 'Misure',
                    border: 'Bordo',
                    border_color: 'Colore bordo',
                    border_thread: 'Bordo filo',
                    border_thread_color: 'Colore bordo filo',
                    border_inlay: 'Intarsio bordo',
                    graphic: 'Grafica',
                    inlay: 'Intarsio',
                    recap: 'Riepilogo'
                }
            },
            tabs_items: [],
            model: {
                configurator: null,
                material: {
                    id: null,
                    color: null,
                    size: null,
                    backside: null
                },
                shape: null,
                dimensions: {
                    a: 0,
                    b: 0,
                    a1: 0,
                    b1: 0,
                    variable_b: false
                },
                border: {
                    id: null,
                    color: null,
                    thread: {
                        id: null,
                        color: null
                    },
                    inlay: null
                },
                graphic: null,
                inlay: {
                    material_color_1: null,
                    material_color_2: null,
                    material_color_3: null,
                    material_color_4: null,
                    material_color_5: null,
                    orientation: null
                },
                img: null
            },
            summary: {
                on: false,
                loading: false,
                error: false,
                cashier: {
                    sell: 0
                }
            },
            dialog: {
                on: false
            },
            modals: {
                add_to_cart: {
                    on: false,
                    title: null
                },
                save: {
                    on: false,
                    name: null,
                    completed: false,
                    error: null,
                    loading: false
                }
            },
            share: {
                on: false,
                url: null,
                loading: false,
                error: null
            },
            is: {
                preload: false,
                loading: false,
                error: false
            }
        }
    },
    computed: {
        configuration_id () {
            return this.$route.params.configuration_id
        },
        currentTabKey () {
            const i = this.tabs_items[this.tabs.index]
            return i ? i.value : null
        },
        prev_tab () {
            return this.tabs_items[this.tabs.index - 1]
        },
        next_tab () {
            return this.tabs_items[this.tabs.index + 1]
        },
        is_end_tab () {
            return this.tabs.index >= this.tabs_items.length - 1
        },
        progress () {
            const filter_tabs = this.tabs_items.filter((i) => {
                return i.required === true && i.value !== 'dimensions'
            })
            let filter_tabs_count = filter_tabs.length

            let risolved = filter_tabs.filter((i) => {
                return !!this.dot_model[i.value]
            }).length

            if (
                this.has('dimensions') &&
                (
                    (this.selectedShape && this.selectedShape.side !== 'elle' && this.model.dimensions.a && this.model.dimensions.b) ||
                    (this.selectedShape && this.selectedShape.side === 'elle' && this.model.dimensions.a && this.model.dimensions.b && this.model.dimensions.a1 && this.model.dimensions.b2)
                )
            ) {
                filter_tabs_count += 1
                risolved += 1
            }

            // console.log('progress risolved', filter_tabs, risolved)

            const progress = (100 / filter_tabs_count) * risolved
            return Math.round(progress)
        },
        is_addable_to_cart () {
            return this.status.value === 'ok' && !this.summary.error && this.progress >= 100
        },
        // models ------------------------------------------------------------------------------
        dot_model () {
            return JSON.parse(JSON.stringify(dot.dot(this.model)))
        },
        dot_model_string () {
            return JSON.parse(JSON.stringify(
                JSON.stringify(dot.dot(this.model))
            ))
        },
        // -------------------------------------------------------------------------------------
        selectedMaterial () {
            if (!this.model.material.color) return null
            const v = this.item.material.colors.find((i) => i.id === this.model.material.color)
            return v
        },
        selectedInlayMaterialColors () {
            if (!this.model.inlay.material_color_1) return null

            return {
                material_color_1: this.item.inlay.materials_colors.find((i) => i.id === this.model.inlay.material_color_1),
                material_color_2: this.item.inlay.materials_colors.find((i) => i.id === this.model.inlay.material_color_2),
                material_color_3: this.item.inlay.materials_colors.find((i) => i.id === this.model.inlay.material_color_3),
                material_color_4: this.item.inlay.materials_colors.find((i) => i.id === this.model.inlay.material_color_4),
                material_color_5: this.item.inlay.materials_colors.find((i) => i.id === this.model.inlay.material_color_5)
            }
        },
        selectedMaterialSize () {
            return this.selectedMaterial && this.selectedMaterial.max_size ? this.selectedMaterial.max_size : this.item.material && this.item.material.max_size ? this.item.material.max_size : null
        },
        selectedBackside () {
            if (!this.selectedMaterial) return null
            const v = this.item.backsides ? this.item.backsides.find((i) => i.id === this.model.material.backside) : null
            return v
        },
        selectedShape () {
            if (!this.model.shape) return null
            const v = this.item.shapes.find((i) => i.id === this.model.shape)
            return v
        },
        selectedBorder () {
            if (!this.model.border.id) return null
            return this.item.borders.find((i) => i.id === this.model.border.id)
        },
        selectedBorderColor () {
            if (!this.model.border.color) return null
            const border = this.item.borders.find((i) => i.id === this.model.border.id)
            if (!border) return null
            return border.colors.find((i) => i.id === this.model.border.color)
        },
        selectedBorderThread () {
            if (!this.model.border.thread.id) return null
            return this.selectedBorder.threads.find((i) => i.id === this.model.border.thread.id)
        },
        selectedBorderThreadColor () {
            if (!this.model.border.thread.color) return null
            return this.selectedBorderThread.colors.find((i) => i.id === this.model.border.thread.color)
        },
        selectedGraphic () {
            if (!this.model.graphic) return null
            return this.item.graphics.find((i) => i.id === this.model.graphic)
        },
        borders () {
            // recupero i bordi da forma
            if (
                this.item.material.type !== 'passatoia' &&
                this.item.shapes &&
                this.selectedShape &&
                this.selectedShape.borders &&
                this.item.borders
            ) {
                return this.item.borders.filter((i) => this.selectedShape.borders.includes(i.id))
            } else if (this.item.borders) {
                return this.item.borders
            }

            return []
        },
        limits () {
            if (!this.selectedMaterialSize) {
                return {
                    min: 0,
                    max: 0,
                    maxSizing: 0
                }
            }

            return {
                min: this.selectedMaterialSize.dimensions.min, // this.item.material.type !== 'tappeto' ? this.selectedMaterial.sizes[0].dimensions.width : this.selectedMaterialSize.dimensions.min,
                max: this.selectedMaterialSize.dimensions.max,
                maxSizing: this.selectedMaterialSize.dimensions.width
            }
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        configuration () {
            console.log('configurator change configuration -')
            // this.start()
        },
        dot_model_string: {
            deep: true,
            immediate: false,
            handler (newVal, oldVal) {
                // console.log(JSON.parse(newVal), JSON.parse(oldVal), isEqual(newVal, oldVal))

                if (isEqual(newVal, oldVal)) return

                console.log('configurator change model')
                this.update()
                this.getCalc()
            }
        }
    },
    methods: {
        // initials --------------------------------------------------------------------------------------------------------
        async start () {
            this.is.error = false
            this.is.preload = false
            this.is.loading = true

            try {
                console.log('ProductConfigurator start()')

                const doc = await this.$api.catalog.getProduct(this.$route.params.slug)
                this.item = doc.configurator.steps
                this.type = doc.configurator.type
                this.id = doc.id
                this.product = doc

                await this.getStatus()

                if (this.configuration_id) {
                    this.model = await this.$api.me.products_configurations.getDoc(this.configuration_id)
                    this.modals.save.name = this.model.name
                    await this.getConfigurationStatus()
                } else {
                    this.model.configurator = this.product.id
                    this.model.material.id = this.item.material.id
                }

                if (this.item.backsides && this.item.backsides.length > 0) {
                    this.model.material.backside = this.item.backsides[0].id
                }

                this.is.preload = true
                window.addEventListener('beforeunload', this.preventNav)
                // this.$once('hook:beforeDestroy', () => window.removeEventListener('beforeunload', this.preventNav))

                this.$nextTick(() => {
                    if (this.configuration_id) {
                        this.toStep('recap')
                    }
                })
            } catch (err) {
                console.log('configurator start() error', err)

                this.is.error = true

                if ('status_code' in err && err.status_code === 404) {
                    if (!this.configuration_id) {
                        return this.$router.replace('/errors/404')
                    }
                }
            } finally {
                this.is.preload = true
                this.is.loading = false
            }
        },
        stop () {
            this.status = {
                ref: null,
                value: null,
                errors: []
            }

            this.configuration_status = {
                ref: null,
                configuration: null,
                value: null,
                progress: null,
                errors: []
            }
            this.is.preload = true
            this.is.loading = true
            this.reset()
            window.removeEventListener('beforeunload', this.preventNav)
        },
        // actions --------------------------------------------------------------------------------------------------------
        async createShare () {
            console.log('configurator createShareLink()')

            this.share.loading = true
            this.share.error = null
            this.share.on = true

            try {
                const { url } = await this.confirmSave()
                // this.$router.push(`/prodotti/${this.$route.id}/configurazioni/${id}`)
                this.share.url = url
            } catch (err) {
                this.share.error = true
            } finally {
                this.share.loading = false
            }
        },
        save () {
            this.modals.save.completed = false
            this.modals.save.on = true
        },
        async confirmSave () {
            console.log('Configurator confirmSave()')
            this.modals.save.loading = true

            // const img = await this.genImg()
            // console.log(img)
            // const img = await html2canvas(this.$refs.figure_box, {
            //     allowTaint: true,
            //     width: 400,
            //     height: 300
            // })
            // console.log('image', img)

            const d = await this.$api.me.products_configurations.create({
                ...this.model,
                name: this.modals.save.name || this.model.name
            })

            this.model.id = d.id
            // this.modals.save.name = null
            this.modals.save.loading = false
            this.modals.save.completed = true

            return d
        },
        saveInProfile () {
            console.log('Configurator saveInProfile()')

            if (!this.$api.auth.me) {
                return this.$store.commit('set_drawer', {
                    key: 'sign',
                    value: true
                })
            }

            // if (!this.model.id) this.save()
            // else this.confirmSave()

            this.save()
        },
        exit () {
            this.dialog.on = true
        },
        confirmExit () {
            this.$router.push(`/prodotti/${this.$route.params.slug}`)
        },
        async addToCart () {
            try {
                const { id } = await this.confirmSave()

                await this.$api.me.checkout.addToCart(id, 1)

                alert('Articolo aggiunto al carrello!')
            } catch (err) {
                alert(err.message || err.name)
            }
        },
        reset () {
            // this.$router.push('/prodotti/' + this.$route.params.slug + '/configurazioni')

            this.model = {
                id: null,
                name: this.model.name,
                proposal: this.model.proposal,
                configurator: this.model.configurator,
                //
                material: {
                    id: this.model.material.id,
                    color: null,
                    size: null,
                    backside: null
                },
                shape: null,
                dimensions: {
                    a: 0,
                    b: 0,
                    a1: 0,
                    b1: 0,
                    variable_b: false
                },
                border: {
                    id: null,
                    color: null,
                    thread: {
                        id: null,
                        color: null
                    },
                    inlay: null
                },
                graphic: null,
                inlay: {
                    material_color_1: null,
                    material_color_2: null,
                    material_color_3: null,
                    material_color_4: null,
                    material_color_5: null,
                    orientation: null
                },
                img: null
            }

            this.tabs.index = 0
            this.summary.on = false
        },
        copyShareLink () {
            navigator.clipboard.writeText(this.share.url)
            this.$toast.success('Link della configurazione copiato!')
        },
        genImg () {
            return new Promise((resolve, reject) => {
                return html2canvas(this.$refs.figure_box, {
                    onrendered: (canvas) => {
                        const data = canvas.toDataURL()
                        const img = document.createElement('img')
                        img.setAttribute('download', 'my-image.png')
                        img.src = 'data:image/png;base64,' + data
                        this.$el.appendChild(img)
                        console.log(img.src)
                    },
                    width: 400,
                    height: 300
                }).then(resolve).catch(reject)
            })
        },
        // ----------------------------------------------------------------------------------------------------
        async getStatus () {
            console.log('configurator getStatus()')
            this.status = await this.$api.post(`/v1/configurators/${this.id}/status`)
        },
        async getConfigurationStatus () {
            console.log('configurator getConfigurationStatus()')
            this.configuration_status = await this.$api.post('/v1/configurators/configurations/status', this.model)
        },
        async getCalc () {
            console.log('configurator getCalc()')

            this.summary.loading = true
            this.summary.error = null

            try {
                const doc = await this.$api.post('/v1/configurators/' + this.id + '/configurations/prices', this.model)
                this.summary.cashier = doc
                this.summary.error = false
            } catch (err) {
                this.summary.error = err.message || err.code || err
            } finally {
                this.summary.loading = false
            }
        },
        preventNav (evt) {
            if (!this.isEditing) return
            evt.preventDefault()
            evt.returnValue = ''
        },
        // --------------------------------------------------------------------------------------------------------
        update () {
            console.log('configurator update()')

            const v = [
                {
                    label: 'Colore',
                    value: 'material.color',
                    required: true,
                    disabled: false,
                    completed: this.model.material.color
                }
            ]

            if (this.item.backsides && this.item.backsides.length > 0) {
                v.push({
                    label: 'Sottofondo',
                    value: 'material.backside',
                    required: false,
                    disabled: !this.model.material.color,
                    completed: this.model.material.backside
                })
            }

            if (this.item.shapes) {
                v.push({
                    label: 'Forma',
                    value: 'shape',
                    required: true,
                    disabled: !this.model.material.color,
                    completed: this.model.shape
                })
            }

            if (this.item.inlay) {
                v.push({
                    label: 'Intarsio',
                    value: 'inlay',
                    required: true,
                    disabled: !this.model.material.color,
                    completed: this.model.inlay.material_color_1
                })
            }

            if (this.item.graphics) {
                v.push({
                    label: 'Grafica',
                    value: 'graphic',
                    required: true,
                    disabled: !this.model.material.color,
                    completed: this.model.graphic
                })
            }

            v.push({
                label: 'Misure',
                value: 'dimensions',
                required: true,
                disabled: !this.model.shape,
                completed: this.model.dimensions.a && this.model.dimensions.b
            })

            if (this.item.borders) {
                v.push({
                    label: 'Bordo',
                    value: 'border.id',
                    required: true,
                    disabled: !this.model.shape,
                    completed: this.model.border.id
                })

                if (this.selectedBorder) {
                    if (this.selectedBorder.colors && this.selectedBorder.colors.length > 0) {
                        v.push({
                            label: 'Colore bordo',
                            value: 'border.color',
                            required: false,
                            disabled: !this.model.border.id,
                            completed: this.model.border.color
                        })
                    }

                    if (this.selectedBorder.threads && this.selectedBorder.threads.length > 0) {
                        v.push({
                            label: 'Bordo filo',
                            value: 'border.thread.id',
                            required: false,
                            disabled: !this.model.border.id,
                            completed: this.model.border.thread.id
                        })

                        if (this.selectedBorderThread && this.selectedBorderThread.colors && this.selectedBorderThread.colors.length > 0) {
                            v.push({
                                label: 'Colore bordo filo',
                                value: 'border.thread.color',
                                required: false,
                                disabled: !this.model.border.thread.id,
                                completed: this.model.border.thread.color
                            })
                        }
                    }
                }

                // if (this.item.borders.inlay && this.item.inlay.border.on) v.push({ label: 'Intarsio bordo', value: 'border_inlay' })
            }

            this.tabs_items = [
                ...v,
                {
                    label: 'Recap',
                    value: 'recap',
                    required: false,
                    disabled: false, // this.progress < 100
                    completed: false
                }
            ]

            return [
                ...v,
                {
                    label: 'Recap',
                    value: 'recap',
                    required: false,
                    disabled: false, // this.progress < 100
                    completed: true
                }
            ]
        },
        has (val) {
            return this.tabs_items.some(i => i.value === val)
        },
        nextStep () {
            this.$nextTick(() => {
                if (
                    !this.next_tab ||
                    (this.next_tab && this.next_tab.disabled)
                ) return

                this.summary.on = this.next_tab.value === 'recap' && this.$store.state.is_mobile
                this.tabs.index += 1
            })
        },
        prevStep () {
            this.$nextTick(() => {
                if (!this.prev_tab || (this.prev_tab && this.prev_tab.disabled)) return
                this.tabs.index -= 1
            })
        },
        toStep (index) {
            this.$nextTick(() => {
                console.log('configurator to()', index)

                let i = index

                if (typeof index === 'string') {
                    i = this.tabs_items.findIndex(_i => _i.value === index)
                }

                const tab = this.tabs_items[i]
                if (!tab) return

                this.summary.on = false
                this.tabs.index = i
            })
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    beforeRouteUpdate (to, from) {
        console.log('Configurator beforeRouteUpdate()')
        this.stop()
        this.start()
    },
    beforeRouteLeave(to, from, next) {
        console.log('Configurator beforeRouteLeave()')

        if (this.isEditing) {
            this.dialog.on = true
            return
        }

        next()
    },
    beforeUnmount () {
        console.log('Configurator beforeUnmount()')
        this.stop()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

$page-max-width: 100%;
$page-boxed: var(--boxed-ui);
$navbar_height: var(--navbar-min-height);
$navbar_background_color: var(--color-mute);
$toolbar_height: var(--navbar-min-height);
$background-color: rgba(245, 245, 245);
$col-l-width: 60%;
$col-r-width: 40%;

.product-configurator-view {
    position: relative;
    width: 100%;
    height: 100vh;
    max-width: $page-max-width;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    user-select: none;

    * {
        user-select: none;
    }

    h1, h2, h3, h4, h5, h6
    p,
    a,
    small,
    button {
        margin: 0;
        padding: 0;
        line-height: 100%;
    }

    button {
        display: inline-flex;
        flex-flow: row nowrap;

        &.bxs-disabled {
            color: var(--color-disabled);
        }
    }

    .toolbar {
        height: $toolbar_height;
    }

    // -----------------------------------------

    &--navbar {
        height: $navbar_height;
        justify-content: space-between;
        background-color: $navbar_background_color;
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0 var(--size-layout);
    }

    &--main {
        position: relative;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-flow: column nowrap;

        @include mq($mq-static-breakpoint) {
            flex-flow: row nowrap;
        }

        &--hero {
            position: relative;
            overflow: hidden;
            background-color: rgba(245, 245, 245);

            @include mq($mq-static-breakpoint) {
                width: 60%;
            }

            @include mq(dm) {
                width: $col-l-width;
            }

            &--toolbar {
                height: $toolbar_height;
            }
        }

        &--content {
            flex: 1;
            // max-width: 520px;
            position: relative;
            padding: 0 var(--size-layout);
            background-color: white;

            &::before {
                content: "";
                position: absolute;
                top: calc(
                    (var(--size-layout) * 1.5 * -1) + 1px
                );
                left: 0;
                width: 100%;
                height: calc(var(--size-layout) * 1.5);
                border-radius: 20px 20px 0px 0px;
                background-color: white;
                z-index: 2;
                pointer-events: none;
                // box-shadow: 0px -24px 24px rgba(#000, 0.2);
            }

            @include mq($mq-static-breakpoint) {
                width: 40%;
                overflow: hidden;
                padding-top: var(--size-ui);
                padding-bottom: var(--size-ui);
            }

            @include mq(dm) {
                width: $col-r-width;
            }
        }
    }

    &--footer {
        height: $toolbar_height;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        padding: 0 var(--size-layout);
        background-color: rgba(245, 245, 245);
        // border-top: 1px solid var(--divider-color);

        @include mq($mq-static-breakpoint) {
            width: 60%;
        }

        @include mq(dm) {
            width: $col-l-width;
        }

        > div {
            flex: 1;

            &:last-child {
                text-align: right;
            }

            &:nth-child(2) {
                text-align: center;
            }
        }
    }
}
</style>