<template>
    <div
    ref="wrapper"
    id="wrapper"
    :class="['app--wrapper', 'app-scroll-direction-' + scroll_direction, {
        'app-loading': is_in_transition || is_loading,
        'app-noevt': is_in_transition || is_loading
    }]">
        <div
        v-if="!is_funnell"
        class="mute py-2 text-center"
        style="font-size: 12px;">Scegli, crea e compra online il tuo tappeto su misura made in Italy</div>

        <!-- -->
        <bxs-navbar
        v-if="is_preloaded && !is_funnell"
        ref="navframe" />

        <!-- -->
        <top-banner
        v-if="is_preloaded && !is_funnell && $api.shop.banners_announcements.length > 0"
        :items="$api.shop.banners_announcements"
        ref="top_banner" />

        <!-- view -->
        <main
        ref="main"
        class="app--main">
            <router-view
            v-if="is_preloaded"
            :key="$route.name"
            ref="page"
            class="app--page" />

            <!-- <bxs-layout>
                <div
                v-for="(icon, name) in $store.state.icons"
                :key="name">
                    <bxs-icon
                    :name="name" />
                    <p>{{ name }}</p>
                </div>
            </bxs-layout> -->

            <transition
            name="fade"
            mode="out-in">
                <bxs-loader
                v-if="is_loading"
                fullscreen
                logo />
            </transition>
        </main>

        <!--  -->
        <bxs-footer
        v-if="is_preloaded && !is_funnell"
        ref="footer" />

        <!-- ------------------------------------------------------------------------------------------------------------
            cookie banner
        ------------------------------------------------------------------------------------------------------------ -->
        <teleport to="#app">
            <bxs-cookie
            ref="cookie"
            v-show="!cookie_settings.on">
                <template #default="{ accept }">
                    <div class="flex-1 text-ui">
                        <div class="mb-8">
                            <small class="text-medium">Notice!</small>
                        </div>

                        <div class="mb-ui small">
                            <p class="mb-3">This website does not use profiling cookies. Instead, technical cookies, including third-party cookies, are used in order to improve the browsing experience.</p>
                            <p class="mb-3">By clicking on the "Accept" button, you will close the banner without giving consent to any cookies except those that are necessary.</p>

                            <div class="text-disabled">
                                <router-link to="/policy" class="link-mute">Policy</router-link>
                            </div>
                        </div>

                        <div class="text-right">
                            <!-- <bxs-btn
                            text
                            class="mr-ui"
                            @click="cookie_settings.on = true">
                            <small>Solo essenziali</small>
                            </bxs-btn> -->

                            <bxs-btn
                            text
                            class="mr-ui"
                            @click="cookie_settings.on = true">
                                <small>Imposta preferenze</small>
                            </bxs-btn>

                            <bxs-btn
                            color="white"
                            dark
                            @click="accept">
                                <small>Accetto</small>
                            </bxs-btn>
                        </div>
                    </div>
                </template>
            </bxs-cookie>
        </teleport>

        <!-- ------------------------------------------------------------------------------------------------------------
        cookie settings
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-cookie-settings
        v-model="cookie_settings.on"
        ga="G-WM79260NVP"
        @accept-all="$refs.cookie.accept()" />

        <!-- ------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------ -->
        <sign-drawer />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { fixVh } from '@/assets/libs/utils'

import TopBanner from '@/components/project/TopBanner.vue'
import Navbar from '@/components/layout/Navbar.vue'
import Footer from '@/components/layout/Footer.vue'
import SignDrawer from '@/components/project/SignDrawer.vue'
import CookieBanner from '@/components/CookieBanner.vue'
import CookieSettings from '@/components/CookieSettings.vue'

export default {
    name: 'App',
    components: {
        'top-banner': TopBanner,
        'bxs-navbar': Navbar,
        'bxs-footer': Footer,
        'bxs-cookie': CookieBanner,
        'bxs-cookie-settings': CookieSettings,
        'sign-drawer': SignDrawer
    },
    data () {
        return {
            cookie_settings: {
                on: false
            },
            scroll_direction: null
        }
    },
    computed: {
        ...mapState({
            is_loading: (state) => state.is_loading,
            is_preloaded: (state) => state.is_preloaded,
            is_in_transition: state => state.is_in_transition
        }),
        is_funnell () {
            const v = [
                'product_configurator'
            ]
            return v.includes(this.$route.name)
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            console.log('app start()', this.$api)

            const condition = navigator.onLine ? 'bxs-app-online' : 'bxs-app-offline'
            document.body.classList.toggle(condition)

            this.$api.on('change-connection', (evt, val) => {
                const condition = navigator.onLine ? 'tsm-app-online' : 'tsm-app-offline'
                document.body.classList.toggle(condition)

                if (condition === 'tsm-app-online') {
                    this.$notify.success('Sei online')
                } else {
                    this.$notify.error('Sei offline')
                }
            })

            this.$api.on('access-token-expired', (evt, val) => {
                console.log('App on access-token-expired')
                this.$router.push('/signin')
            })

            this.resize()
            window.addEventListener('resize', this.resize)

            try {
                await this.$api.shop.doc()
                await this.$api.auth.getAccess()
                this.$api.me.checkout.getDoc()

                if (this.$api.auth.me) {
                    this.$api.me.wish.list()
                }
            } catch (err) {
                console.log('err', err)
            }

            this.$router.push(sessionStorage.getItem('tsm_last_page') || '/').catch(() => {})

            this.$store.commit('set_is_preloaded', true)
            this.$store.commit('set_is_loading', false)

            this.play()
        },
        play () {
            this.$nextTick(() => {
                console.log('app play()', this.$refs)
                this.resize()
            })
        },
        pause () {
            console.log('app pause()')
        },
        // handlers
        resize () {
            console.log('app resize()')
            fixVh()
            this.$store.commit('resize')
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
</style>
