<template>
    <div class="me">
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="4">
                        <bxs-card>
                            <div class="pa-ui">
                                <div class="flex nowrap">
                                    <div class="flex-1">
                                        <h4 class="mb-0">{{ $api.auth.me.name }}</h4>
                                    </div>
                                    <bxs-icon to="/" name="close" />
                                </div>

                                <div>
                                    <small>{{ $api.auth.me.email }}</small>
                                </div>
                            </div>

                            <bxs-divider />

                            <bxs-list>
                                <bxs-list-item
                                to="/me/profilo"
                                prepend-icon="person">Profilo</bxs-list-item>

                                <bxs-list-item
                                to="/me/indirizzi"
                                prepend-icon="geo-al-fill">Indirizzi</bxs-list-item>

                                <bxs-list-item
                                to="/me/ordini"
                                prepend-icon="cart">Ordini</bxs-list-item>

                                <bxs-list-item
                                to="/me/configurazioni"
                                prepend-icon="heart">Configurazioni</bxs-list-item>

                                <bxs-list-item
                                to="/me/desideri"
                                prepend-icon="heart">Desideri</bxs-list-item>

                                <bxs-list-item
                                to="/me/assistenza"
                                prepend-icon="chat-left">Assistenza</bxs-list-item>

                                <bxs-list-item
                                link
                                @click="signout">Esci</bxs-list-item>
                            </bxs-list>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col cols="12" ds="8">
                        <router-view />
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'me',
    methods: {
        async signout () {
            try {
                await this.$api.auth.signout()
                this.$api.me.checkout.getDoc()
                this.$router.push('/')
            } catch (err) {
                alert(err.message || err.name)
            }
        }
    }
}
</script>